import Footer from "../../Common/Footer";
import Header from "../../Common/Header";
import Loading from "../../Common/Loading";
import { Link, useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import "./style.scss";
import React, { useEffect, useState } from "react";
import { URL_LANDING_PAGE } from "../../../Helpers/urls";
import Api from "../../../Helpers/Api";
import Moment from "react-moment";

const News = () => {
  return (
    <div>
      <Header />
      <NewsContent />
      <Footer />
    </div>
  );
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function trimMyString(string, maxLength, start = 0) {
  if (string.length > maxLength) {
    let trimmedString = string.substr(start, maxLength);
    return (
      trimmedString.substr(
        start,
        Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))
      ) + " ..."
    );
  }
  return string;
}

const NewsContent = () => {
  let query = useQuery();
  const history = useHistory();
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(false);
  let offsetPage = +query.get("offset") ? +query.get("offset") : 0;
  const [hideNextNewsButton, setHideNextNewsButton] = useState(false);
  let limitPage = 6;

  const [isCancelled, setIsCancelled] = useState(false);

  const getNews = async (offset, limit) => {
    setLoading(true);
    try {
      const result = await Api.getNewsFully(offset, limit);
      let offPageSize = offset + limitPage;
      if (result.meta.total_count > offPageSize) {
        setHideNextNewsButton(false);
      } else {
        setHideNextNewsButton(true);
      }
      if (result.data.length > 0) {
        const imageIds = result.data.map((news) => news.image);
        const imagesData = await Api.getAllImageUrl({
          "filter[id][in]": imageIds.toString(),
        });
        result.data.map((data) => {
          let imageUrls = imagesData.filter(
            (details) => details.id === data.image
          );
          if (imageUrls.length > 0) data.imageUrl = imageUrls[0].data.full_url;
        });
        const sortedNews = result.data.sort(
          (a, b) => new Date(b.date) - new Date(a.date)
        );
        setNews(sortedNews);
        setLoading(false);
      }
    } catch (err) {
      console.error("getTeams -> err", err);
      setLoading(false);
    }
  };

  const getNewsData = async (offset, limit) => {
    await Api.getAccessToken();
    Promise.all([getNews(offset, limit)]);
  };

  function backPage(e) {
    e.preventDefault();
    let paramsQuery =
      +query.get("offset") - limitPage >= 0
        ? +query.get("offset") - limitPage
        : 0;
    history.push({
      pathname: "/news",
      search: new URLSearchParams({ offset: +paramsQuery }).toString(),
    });
    getNewsData(paramsQuery, limitPage);
  }

  function nextPage(e) {
    e.preventDefault();
    let paramsQuery = +query.get("offset") + limitPage;
    history.push({
      pathname: "/news",
      search: new URLSearchParams({ offset: paramsQuery }).toString(),
    });
    getNewsData(paramsQuery, limitPage);
  }

  useEffect(() => {
    !isCancelled && getNewsData(offsetPage, limitPage);

    return () => {
        setIsCancelled(true);
    }
  }, []);

  return (
    <>
      <div>
        <div className="page-title-area bg-15 news-banner-image"></div>

        <section id="newsSection" className="blog-section ptb-100">
          <div className="container">
            <div className="section-title">
              <h2>Latest News</h2>
            </div>

            {loading ? (
              <Loading />
            ) : (
              <div>
                <div className="row">
                  {news.map((item, index) => (
                    <div className="col-lg-4 col-md-6" key={index}>
                      <div className="blog-item">
                        <div className="image">
                          <Link to={"/news/" + item.slug}>
                            <img src={item.imageUrl} alt="" />
                          </Link>
                        </div>
                        <ul className="post-meta">
                          <li>
                            <i className="fa fa-calendar"></i>
                            <Moment date={item.date} format="MMM DD, YYYY" />
                          </li>
                        </ul>
                        <div className="content">
                          <h3>
                            <Link to={"/news/" + item.slug}>{item.name}</Link>
                          </h3>

                          {item.description ? (
                            <div>{trimMyString(item.description, 100)}</div>
                          ) : (
                            ""
                          )}

                          <Link to={"/news/" + item.slug} className="read-more">
                            Read More
                            <i className="flaticon-curve-arrow-1"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="col-lg-12">
                  <div className="page-navigation-area">
                    <nav aria-label="Page navigation example text-center">
                      <ul className="pagination">
                        <li className="slider-btn m-3">
                          <button
                            className="default-btn"
                            onClick={backPage}
                            disabled={+offsetPage <= 0}
                          >
                            <i className="fa fa-chevron-left"></i> Back Page
                          </button>
                        </li>
                        <li className="slider-btn m-3">
                          <button
                            className="default-btn"
                            onClick={nextPage}
                            disabled={hideNextNewsButton}
                          >
                            Next Page <i className="fa fa-chevron-right"></i>
                          </button>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>
      </div>
    </>
  );
};

export default News;
