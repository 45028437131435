import { PATH_ARTWORK, PATH_GET_BY_TAGS, PATH_GET_FEATURED_TAG, PATH_GET_TAG, PATH_ARTWORK_COMMENTS, PATH_ARTWORK_IMAGES, PATH_ADD_ARTWORK_COMMENTS, PATH_REPORT_ART } from "./path";
import * as handler from "./handler";

const getAllByUser = (UserId) => {
  return handler
    .get(PATH_ARTWORK + `?UserId=` + UserId)
    .then((res) => {
      return res.model;
    })
    .catch((error) => {
      console.log("error " + error);
    });
};
const GetArtwork = (id) => {
  return handler
    .get(PATH_ARTWORK + `?Id=` + id)
    .then((res) => {
      return res.model;
    })
    .catch((error) => {
      console.log("error " + error);
    });
};

const GetArtworkBySlug = (id, slug) => {
  return handler
    .get(PATH_ARTWORK + `/` + id + `/` + slug)
    .then((res) => {
      return res.model;
    })
    .catch((error) => {
      return false;
    });
};

const CreateArtwork = (payload) => {
  return handler
    .post(PATH_ARTWORK, payload)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.error("error " + error);
    });
};

const deleteArtwork = (id) => {
  return handler
    .delet(PATH_ARTWORK + "?id=" + id)
    .then((res) => {
      return res.model;
    })
    .catch((error) => {
      console.error("error" + errorss);
    });
};

const UpdateArtwork = (payload) => {
  return handler
    .put(PATH_ARTWORK, payload)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.error("error " + error);
    });
};

const GetCompletedForFollower = (userId, LoggedInUserId) => {
  return handler
    .get(PATH_ARTWORK + `?userId=${userId}&LoggedInUserId=${LoggedInUserId}`)
    .then((res) => {
      return res.model;
    })
    .catch((error) => {
      console.log("error " + error);
    });
};

const addInspiration = (artId) => {
  return handler
    .post(PATH_ARTWORK + `/${artId}/addinspiration`, {})
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.error("error " + error);
    });
};

const deleteInspiration = (artId) => {
  return handler
    .delet(PATH_ARTWORK + `/${artId}/removeinspiration`)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.error("error" + error);
    });
};

const getAllTags = () => {
  return handler
    .get(PATH_GET_TAG)
    .then((res) => {
      return res.model;
    })
    .catch((error) => {
      console.log("error " + error);
    });
};

const GetArtworkComments = (artId) => {
  return handler.get(PATH_ARTWORK_COMMENTS + `/${artId}`)
  .then((res) => {
    return res.model;
  })
  .catch((error) => {
    console.log("error " + error);
  });
};

const getByTags = (value) => {
  return handler
    .post(PATH_GET_BY_TAGS,value)
    .then((res) => {
      return res.model;
    })
    .catch((error) => {
      console.log("error " + error);
    });
};

const getFeaturedTags = () => {
  return handler
    .get(PATH_GET_FEATURED_TAG)
    .then((res) => {
      return res.model;
    })
    .catch((error) => {
      console.log("error " + error);
    });
};

const getArtworkImages = (params) => {
  return handler
    .get(PATH_ARTWORK_IMAGES, params)
    .then((res) => {
      return res.model;
    })
    .catch((error) => {
      console.log("error " + error);
    });
};

const addArtworkComment = (payload) => {
  return handler
    .post(PATH_ADD_ARTWORK_COMMENTS, payload)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.error("error " + error);
    });
};

const reportArtwork = (payload) => {
  return handler.get(PATH_REPORT_ART, payload)
};

export default {

  getAllByUser,
  GetArtwork,
  GetArtworkComments,
  addArtworkComment,
  GetArtworkBySlug,
  CreateArtwork,
  deleteArtwork,
  UpdateArtwork,
  addInspiration,
  deleteInspiration,
  GetCompletedForFollower,
  getAllTags,
  getByTags,
  getFeaturedTags,
  getArtworkImages,
  reportArtwork
};
