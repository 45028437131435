import React, {useEffect, useState} from "react";
import script from "../../../Assets/img/icons/feather.svg";

var chat_height = '100%';
var chat_width = '100%';
var chat_appid = '56867';

const Chat = () => {

  useEffect(() => {
    var chat_js = document.createElement('script');
    chat_js.type = 'text/javascript';
    chat_js.src = 'https://fast.cometondemand.net/'+chat_appid+'x_xchatx_xcorex_xembedcode.js';

    chat_js.onload = () => {
      var chat_iframe = {};
      chat_iframe.module="synergy";
      chat_iframe.style="min-height:"+chat_height+";min-width:"+chat_width+";";
      chat_iframe.width=chat_width.replace('px','');
      chat_iframe.height=chat_height.replace('px','');
      chat_iframe.src='https://'+chat_appid+'.cometondemand.net/cometchat_embedded.php';
      if(typeof(addEmbedIframe)=="function"){
        addEmbedIframe(chat_iframe)
      }
    }

    var chat_script = document.getElementsByTagName('script')[0]; chat_script.parentNode.insertBefore(chat_js, chat_script);
  }, [])

  return (
    <>
      <div id="cometchat_embed_synergy_container">
        <div className="loading-div">
          Loading...
        </div>
      </div>
    </>
  )
}

export default Chat
