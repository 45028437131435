import { URL_ABOUT_PAGE, URL_CONTACT_PAGE, URL_DONATE_PAGE, URL_GET_INVOLVED_PAGE, URL_LANDING_PAGE, URL_NEWS_PAGE } from "../../../Helpers/urls";

export const menu = [
    {
        name: 'Home',
        pathname: URL_LANDING_PAGE,
        state: 'home'
    },
    {
        name: 'About',
        pathname: URL_ABOUT_PAGE,
        state: 'about'
    },
    {
        name: 'Get Involved',
        pathname: URL_GET_INVOLVED_PAGE,
        state: 'involedpage'
    },
    {
        name: 'News',
        pathname: URL_NEWS_PAGE,
        state: 'news'
    },
    {
        name: 'Contact',
        pathname: URL_CONTACT_PAGE,
        state: 'contact'
    },
    {
        name: 'Donate',
        pathname: URL_DONATE_PAGE,
        state: 'donate'
    },
]