import { combineReducers } from "redux";
// import { routerReducer as routing } from 'react-router-redux';
import authReducer from "./auth.reducer";
import searchReducer from "./search.reducer";

const rootReducer = combineReducers({
  auth: authReducer,
  search: searchReducer
});

export default rootReducer;
