import React, { useState, useEffect } from "react";
import "./style.scss";
import { errorMessage } from "../../../Helpers/utils";
import Loading from "../../Common/Loading";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { URL_REGISTRATION_PAGE, URL_FORGOT_PASSWORD_PAGE, URL_USER_PROFILE } from "./../../../Helpers/urls";
import { Link, Redirect, useHistory, withRouter } from "react-router-dom";
import Api from "../../../Helpers/Api";
import whiteIcon from '../../../Assets/img/white-logo.png'
import { connect, useDispatch, useSelector } from 'react-redux';
import { login } from "../../../Redux/actions/auth.action";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Login = (props) => {
  const userReduxData = useSelector(state => state)
  const dispatch = useDispatch();
  let history = useHistory();
  const [loading, setLoading] = useState(false);
  const LoginSchema = Yup.object().shape({
    username: Yup.string().required("Required"),
    password: Yup.string().required("Required"),
  });


  useEffect(() => {
    if(history.location.pathname == "/user-comfirm-email"){
      doEmailConfirm(location.search)
    }
  }, []);


  const toastMessage = (type, message) => {
    toast[type](message)
  };

  const doEmailConfirm = async (string) => {
    try {
      const result = await Api.doUserEmailConfirm(string)
      if(result.message == "Success") {
        history.push('/login')
        setTimeout(() => { toastMessage('success', "Email confirmed. You can now log in.")}, 100);
      } else {
        toastMessage('error', result)
      }
    } catch (err) {
      toastMessage('error', err)
    }
  };

  const handleSubmitClick = async(values, resetForm) => {
    setLoading(true);
    try {
      const result = await Api.doUserLogin(values)
      setLoading(false);
      if(result.message == "Success") {
        resetForm();
        dispatch(login(result.model.roles[0], result.model.accessToken, result.model.username));
        localStorage.setItem('verifiedUserIds', result.model.verifiedUserIds)

        if (props.location.state && props.location.state.includes('/events')) {
            history.push(props.location.state);    
        } else {
            window.location.href = '/p/' + result.model.username;
        }
      } else {
        toastMessage('error', result)
      }
    } catch (err) {
      setLoading(false);
      toastMessage('error', err)
    }
  };

  return (
    <div className="login-wrapper">
      <div className="login">
        <div className="login-left">
          <div className="login-left-content d-flex flex-column justify-content-between">
            <div className="login-content-heading pt-5">
              <div className="login-heading-content">
                <h2>Welcome to Collective!</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="login-right d-flex flex-column justify-content-center align-center login-form">
          <div className="form-inner">
            <div className="logo-wrap">
              <div className="wrap-inner">
                <img src={whiteIcon} alt="Logo" />
              </div>
            </div>
            <div className="login-right-content-heading form-act">
              <div className="form-wrapper">
                <div className="team-item">
                  <h2 className="text-uppercase theme-color">Collective</h2>
                  <span className="theme-color">By the Artivist Foundation</span>
                </div>
                <Formik
                  initialValues={{ username: "", password: "" }}
                  validationSchema={LoginSchema}
                  onSubmit={(values, { resetForm }) => {
                    handleSubmitClick(values, resetForm);
                  }}
                >
                  {({ errors, touched, handleSubmit }) => (
                    <Form>
                      <div className="login-form">
                        <div className="field">
                          <div className="control">
                            <Field
                              type="text"
                              placeholder="User Name"
                              name="username"
                            ></Field>
                            {errors.username &&
                              touched.username &&
                              errorMessage(errors.username)}
                            <div className="input-icon">
                              <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                >
                                  <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                  <circle cx="12" cy="7" r="4"></circle>
                              </svg>
                            </div>
                          </div>
                        </div>
                        <div className="field">
                          <div className="control">
                            <Field
                              type="password"
                              placeholder="●●●●●●●"
                              name="password"
                            ></Field>
                            {errors.password &&
                              touched.password &&
                              errorMessage(errors.password)}
                            <div className="input-icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <rect
                                  x="3"
                                  y="11"
                                  width="18"
                                  height="11"
                                  rx="2"
                                  ry="2"
                                ></rect>
                                <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                              </svg>
                            </div>
                          </div>
                        </div>

                        <div className="field">

                          <div className="control">
                            { loading ? <Loading /> : <button
                              onClick={handleSubmit}
                              className="button primary-button default-btn"
                            >
                              Login
                            </button>}
                          </div>
                          <div className="control">
                        <Link
                            className="d-flex justify-content-center register-link"
                            to={URL_FORGOT_PASSWORD_PAGE}
                          >
                         Forgot password
                        </Link>
                        <Link
                            className="d-flex justify-content-center forgot-link"
                            to={URL_REGISTRATION_PAGE}
                          >
                           Sign Up
                        </Link>
                      </div>
                        </div>

                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default connect(null, {
  login,
})(withRouter(Login));
