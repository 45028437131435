import React, { useState, useEffect, Fragment } from "react";
import "./style.scss"
import HeaderMenu from "../../Common/HeaderMenu";
import SideMenu from "./SideMenu";
import MainContent from "./PageContent";
import script from "../../../Assets/img/icons/feather.svg";
import { useLocation } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "./../../../Redux/actions/auth.action";
import NotFoundUser from "../NotFoundUser";

const UserProfile = (props) => {

  const [toggleMenu, setToggleMenu] = useState(false);
  const [selectedID, setUserId] = useState(null);
  const [userName, setuserName] = useState(null);
  const [isCancelled, setIsCancelled] = useState(false);
  const location = useLocation();


  const menuClick = () => {
    setToggleMenu(true)
  };

  const onchange = (data) => {
    setToggleMenu(data)
  };

  useEffect(() => {
    !isCancelled && setuserName(window.location.pathname.substr(window.location.pathname.lastIndexOf('/') + 1));

    return () => {
        setIsCancelled(true);
    }
  }, [location.pathname]);

  return (
      <div>
        {/*Header menu*/}
        <HeaderMenu />

        <div className="view-wrapper is-full">
          <div className="stories-wrapper is-home">

            {
              selectedID !== 0
                ? <Fragment>
                  {/*Side menu*/}
                  <SideMenu toggle={toggleMenu} userid={userName} userID={id => {
                    setUserId(id)
                  }} onchange={(e) => { onchange(e) }} />

                  {/*Page content*/}
                  <div className="inner-wrapper">
                    <a className="mobile-sidebar-trigger is-home-v2" onClick={menuClick}>
                      <svg className="feather feather-menu">
                        <use href={script + '#feather-menu'}></use>
                      </svg>
                    </a>

                    <MainContent selectedid= {selectedID} />

                  </div>
                  </Fragment>
                  : <NotFoundUser />
            }

          </div>
        </div>

      </div>
  )
};
export default connect(null, {
  logout,
})(withRouter(UserProfile));

