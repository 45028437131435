import React, { useEffect, useState } from "react";
import "./style.scss";
import { Fragment } from "react";
import Header from "../../Common/Header";
import Footer from "../../Common/Footer";
import Api from "../../../Helpers/Api";

const GetInvolvedPage = () => {
  return (
    <div>
      <Header />
      <Content />
      <Footer />
    </div>
  );
};

const Content = () => {
  const [getInvolved, setGetInvolved] = useState([]);
  const [isCancelled, setIsCancelled] = useState(false);
  //Fetch Get Involved data
  const fetchGetInvolved = async () => {
    try {
      const result = await Api.getInvolved();
      setGetInvolved(result[0]);
    } catch (error) {
      console.error("getInvolved -> error", error);
    }
  };

  const getInvolvedData = async () => {
    Promise.all([fetchGetInvolved()]).then(() => {
      if (window.location.hash) {
        let donateSection = document.querySelector('#donate');
        donateSection?.scrollIntoView({behavior: "smooth"})
      }
    });
  };
  useEffect(() => {
    !isCancelled && getInvolvedData();

    return () => {
        setIsCancelled(true);
    }
  }, []);
  const { community_panel, home_description, opp_description, opp_title } = getInvolved;
  return (
    <Fragment>
      <div className="container-fluid p-0">
        {/* Community Panel  */}
        <section className="ramp-section ptb-100">
          <div className="container">
            <div className="ramp-item">
              <h3>Community Panel</h3>
              <p style={{ whiteSpace: 'pre-wrap' }}>{community_panel}</p>
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLScPTSVXElTJNKuWEfaCVp1UF2Dry7r0voU3bY1XO_nUvTU4tQ/viewform"
                target="_blank"
              >
                Sign Up Here
              </a>
            </div>
          </div>
        </section>

        {/* Internship Opportunities   */}
        <div className="faq-section ptb-100">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="faq-accordion">
                  <ul className="accordion">
                    <li className="accordion-item">
                      <span className="accordion-title active" href="#">
                        Social Media
                      </span>
                    </li>

                    <li className="accordion-item">
                      <span className="accordion-title" href="#">
                        Fundraising
                      </span>
                    </li>

                    <li className="accordion-item">
                      <span className="accordion-title" href="#">
                        Community Outreach
                      </span>
                    </li>

                    <li className="accordion-item">
                      <span className="accordion-title" href="#">
                        Technology
                      </span>
                    </li>

                    <li className="accordion-item">
                      <span className="accordion-title" href="#">
                        Marketing
                      </span>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="about-area-content">
                  <h3>{opp_title}</h3>
                  <p>
                    {opp_description}

                  </p>
                </div>
              </div>
              <div className="col-lg-12 col-md-12 pt-5 text-center">
                <button
                  type="submit"
                  className="default-btn page-btn"
                  onClick={() =>
                    window.open(
                      "https://docs.google.com/forms/d/e/1FAIpQLSfbFgFOc-yHR3VboOB1rqKCu6BdJmSNsdSnPoPdqxJF5C5wig/viewform",
                      "_blank"
                    )
                  }
                >
                  Apply Here
                </button>
                <div id="msgSubmit" className="h3 text-center hidden"></div>
                <div className="clearfix"></div>
              </div>
            </div>
          </div>
        </div>

        {/* Donate */}
        <section className="about-section ptb-100" id="donate">
          <div className="container">
            <div className="row" id="donate">
              <div className="col-lg-6">
                <div className="about-area-content">
                  <span>Donors</span>
                  <h3>Donations Make a Difference in Our Community</h3>
                  <p>
                    The Artivist Foundation is committed to creating both
                    virtual and in-person platforms to elevate the voices of
                    Artivists, particularly those who might not otherwise be
                    heard or seen. We operate with a volunteer staff, which
                    means every dollar you give goes directly into advancing the
                    Collective community platform, and to support the artists
                    and activists who our work serves. As a 501(c)3
                    not-for-profit organization, your donation is tax
                    deductible.
                  </p>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="about-area-content donor-form text-center mt-5">
                  <script
                    src="https://donorbox.org/widget.js"
                    paypalexpress="false"
                  ></script>
                  <iframe
                    allowpaymentrequest=""
                    frameBorder="0"
                    name="donorbox"
                    scrolling="no"
                    seamless="seamless"
                    display="block"
                    src="https://donorbox.org/embed/the-artivist-foundation"
                    width="100%"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Fragment>
  );
};

export default GetInvolvedPage;
