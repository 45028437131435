import {
  PATH_COUNTRY_STATE_CITY,
  PATH_PROFILE,
  PATH_PROFILE_AVATAR,
  PATH_ARTWORK,
  PATH_CHANGE_PASSWORD,
  PATH_UNFOLLOW_USER,
  PATH_FOLLOW_USER,
  PATH_GET_NOTIFICATION,
  PATH_SEARCH,
  PATH_FOLLOWERS, PATH_FOLLOWINGS,
  PATH_READ_NOTIFICATION
} from "./path";
import * as handler from "./handler";

const getProfile = (UserId) => {
  return handler
    .get(PATH_PROFILE + `?UserId=${UserId}`)
    .then((response) => {
      return response.model;
    })
    .catch((error) => {
      console.log("error " + error);
    });
};

const getProfileByName = (UserId) => {
  return handler
    .get(PATH_PROFILE + `/${UserId}`)
    .then((response) => {
      return response.model;
    })
    .catch((error) => {
      return "false";
    });
};

const editProfile = (obj) => {
  return handler.put(PATH_PROFILE, obj).then((res) => {
    return res;
  });
};
const uploadAvatar = (obj) => {
  return handler.post(PATH_PROFILE_AVATAR, obj).then((res) => {
    return res;
  });
};

const getAvatar = (userId) => {
  return handler.get(PATH_PROFILE + `/${userId}/avatar`).then((res) => {
    return res;
  });
};
const getAvatarThumb = (userId) => {
  return handler.get(PATH_PROFILE + `/${userId}/avatarthumb`).then((res) => {
    return res;
  });
};

const getAllArtwork = (path) => {
  return handler
    .getAuth(PATH_ARTWORK +`/${path}`, {}, true)
    .then((response) => {
     return response.model
    })
    .catch(() => {
      return []
    });
};

const changePassword = (user) => {
  return handler.post(PATH_CHANGE_PASSWORD, user).then((res) => {
    return res;
  }).catch((error) => error);
};

const userFollow = (obj) => {
  return handler.post(PATH_FOLLOW_USER, obj).then((res) => {
    return res;
  });
};

const userUnFollow = (obj) => {
  return handler.delet(PATH_UNFOLLOW_USER, obj).then((res) => {
    return res;
  });
};

const getUserNotification = (obj) => {
  return handler.post(PATH_GET_NOTIFICATION, obj).then((res) => {
    return res;
  });
};

const readUserNotification = (id) => {
  return handler.put(PATH_READ_NOTIFICATION + '?id=' + id).then((res) => {
    return res;
  });
};

const getSearchResult = (payload) => {
  return handler.get(PATH_SEARCH + payload.query , payload).then((res) => {
    return res.model;
  });
};

const getFollowers = (payload) => {
  return handler.get(PATH_FOLLOWERS + '?userId=' + payload).then((res) => {
    return res.model;
  });
};

const getFollowings = (payload) => {
  return handler.get(PATH_FOLLOWINGS + '?userId=' + payload).then((res) => {
    return res.model;
  });
};


export default {
  getProfile,
  editProfile,
  getProfileByName,
  getAllArtwork,
  uploadAvatar,
  getAvatar,
  getAvatarThumb,
  changePassword,
  userFollow,
  userUnFollow,
  getUserNotification,
  readUserNotification,
  getSearchResult,
  getFollowers,
  getFollowings
};
