import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./style.scss";
import {
  FACEBOOK_URL,
  INSTAGRAM_URL,
  TWITTER_URL,
  YOUTUBE_URL,
} from "../../../Configs/config";
import {
  URL_ABOUT_PAGE,
  URL_CONTACT_PAGE,
  URL_GET_INVOLVED_PAGE,
  URL_LANDING_PAGE,
  URL_NEWS_PAGE,
  URL_EVENTS_PAGE
} from "../../../Helpers/urls";

import Api from "./../../../Helpers/Api";
import logo from "../../../Assets/img/logo-main-white.png";
import "./style.scss";

const Footer = () => {
  const [contact, setContact] = useState({
    email: "",
    phone: "",
    location: "",
  });
  const [isCancelled, setIsCancelled] = useState(false);

  //Get contact
  const getContact = async () => {
    try {
      const result = await Api.getContact();
      if (result.length > 0) setContact(result[0]);
    } catch (error) {
      console.error("getContact -> error", error);
    }
  };

  const appendScript = () => {
    const script = document.createElement("script");
    script.src = '//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js';
    script.async = true;
    document.body.appendChild(script);
  };

  const getFooterData = async () => {
    await Api.getAccessToken();
    Promise.all([getContact()]);
  };
  useEffect(() => {
    !isCancelled && appendScript();
    !isCancelled && getFooterData();

    return () => {
      setIsCancelled(true);
    }
  }, []);
  const { location, phone, email } = contact;
  return (
    <div className="footer-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-footer-widget">
              <div className="logo">
                <Link to={URL_LANDING_PAGE}>
                  <img src={logo} alt="logo" />
                </Link>
              </div>
              <ul className="footer-contact-info">
                <li>
                  <i className="flaticon-call"></i>
                  <a href="tel:4498886660000">{phone}</a>
                </li>
                <li>
                  <i className="fa fa-envelope"></i>
                  <a href={`mailto:` + email}>{email}</a>
                </li>
                <li>
                  <i className="fa fa-crosshairs"></i>
                  <div className="content">{location}</div>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-footer-widget">
              <h3>Quick Link</h3>
              <ul className="quick-links">
                <li>
                  <i className="flaticon-right-arrow"></i>
                  <Link to={URL_LANDING_PAGE}>Home</Link>
                </li>
                <li>
                  <i className="flaticon-right-arrow"></i>
                  <Link to={URL_ABOUT_PAGE}>About</Link>
                </li>
                <li>
                  <i className="flaticon-right-arrow"></i>
                  <Link to={URL_GET_INVOLVED_PAGE}>Get Involved</Link>
                </li>
                <li>
                  <i className="flaticon-right-arrow"></i>
                  <Link to={URL_NEWS_PAGE}>News</Link>
                </li>
                <li>
                  <i className="flaticon-right-arrow"></i>
                  <Link to={URL_CONTACT_PAGE}>Contact</Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-footer-widget">
              <h3>Newsletter</h3>
              <div className="text">
                <p>Sign up for our newsletter to stay updated</p>
              </div>

              <div id="mc_embed_signup">
                <form
                    action="https://artivistcollective.us17.list-manage.com/subscribe/post?u=173de17de4bf144a1a219524c&amp;id=111ff57390"
                    method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate newsletter-form"
                    target="_blank" noValidate>
                  <div id="mc_embed_signup_scroll">
                    <div className="mc-field-group">
                      <input type="email" name="EMAIL" placeholder="Your Email" className="required email input-newsletter" id="mce-EMAIL" />
                    </div>
                    <div id="mce-responses" className="clear">
                      <div className="response" id="mce-error-response" style={{display: 'none'}}></div>
                      <div className="response" id="mce-success-response" style={{display: 'none'}}></div>
                    </div>
                    <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true">
                      <input type="text" name="b_173de17de4bf144a1a219524c_111ff57390" tabIndex="-1" defaultValue="" />
                    </div>
                    <div className="clear">
                      <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="button" />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="footer-bottom-text">
                <p>
                  Copyright @{new Date().getFullYear()} The Artivist Foundation. All Rights Reserved.
                </p>
                <p>
                  Powered by&nbsp;
                  <a href="https://www.flexiblesites.com/" target="_blank">
                    FlexibleSites
                  </a>
                </p>
              </div>
            </div>

            <div className="col-lg-6">
              <ul className="footer-bottom-social">
                <li>
                  <a href={TWITTER_URL} target="_blank">
                    <i className="flaticon-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href={FACEBOOK_URL} target="_blank">
                    <i className="flaticon-facebook"></i>
                  </a>
                </li>
                <li>
                  <a href={YOUTUBE_URL} target="_blank">
                    <i className="fa fa-youtube-play"></i>
                  </a>
                </li>
                <li>
                  <a href={INSTAGRAM_URL} target="_blank">
                    <i className="flaticon-instagram"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
