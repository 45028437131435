import LandingPage from "../Components/LandingPage";
import About from "../Components/Pages/AboutPage";
import Contact from "../Components/Pages/Contact";
import News from "../Components/Pages/NewsPage";
import NewsDetails from "../Components/Pages/NewsDetailsPage";
import GetInvolvedPage from "../Components/Pages/GetInvolvedPage";
import Registration from "../Components/Pages/Registration";
import Login from "../Components/Pages/Login";
import ForgotPassword from "../Components/Pages/ForgotPassword";

import {
  URL_ABOUT_PAGE,
  URL_LANDING_PAGE,
  URL_NEWS_PAGE,
  URL_NEWS_DETAILS_PAGE,
  URL_GET_INVOLVED_PAGE,
  URL_CONTACT_PAGE,
  URL_REGISTRATION_PAGE,
  URL_LOGIN_PAGE,
  URL_USER_PROFILE,
  URL_USER_EMAIL_CONFIRMATION,
  URL_USER_PROFILE_EDIT,
  URL_EVENT_DETAIL_PAGE,
  URL_FORGOT_PASSWORD_PAGE,
  URL_RESET_PASSWORD_PAGE,
  URL_ADD_LIBRARY_PAGE,
  URL_Feed_PAGE,
  URL_PRIVACY_PAGE,
  URL_EXPLORE_PAGE,
  URL_EDIT_ARTWORK_PAGE,
  URL_RESOURCES_PAGE,
  URL_DASHBOARD,
  URL_EVENTS_PAGE,
  URL_EVENT_DETAILS_PAGE,
  URL_ADD_EVENT_PAGE,
  URL_EDIT_EVENT_PAGE
} from "../Helpers/urls";
import { ADMIN_URL } from "../Configs/config";

import UserProfile from "../Components/Pages/UserProfile";
import UserProfileEdit from "../Components/Pages/UserProfileEdit";
import EventDetail from "../Components/Pages/UserProfile/EventDetail";
import AddLibrary from "../Components/Pages/UserProfile/AddLibrary";
import FeedPage from "../Components/Pages/UserProfile/FeedPage";
import Privacy from "../Components/Pages/Privacy";
import Explore from "../Components/Pages/UserProfile/Explore";
import Resources from "../Components/Pages/UserProfile/Resources";
import EventDetailsPage from '../Components/Pages/EventDetailsPage';
import AddEvent from "../Components/Pages/UserProfile/AddEvent";
import EditEvent from "../Components/Pages/UserProfile/EditEvent";
import MainEvents from "../Components/Pages/UserProfile/MainEvents";

export default [
  {
    path: URL_USER_PROFILE_EDIT,
    exact: true,
    component: UserProfileEdit,
  },
  {
    path: URL_USER_PROFILE,
    exact: true,
    component: UserProfile,
  },
  {
    path: URL_ADD_LIBRARY_PAGE,
    exact: true,
    component: AddLibrary,
  },
  {
    path: URL_Feed_PAGE,
    exact: true,
    component: FeedPage,
  },
  {
    path: URL_EDIT_ARTWORK_PAGE,
    exact: true,
    component: AddLibrary,
  },
  {
    path: URL_EVENT_DETAIL_PAGE,
    exact: true,
    component: EventDetail,
  },
  {
    path: URL_EXPLORE_PAGE,
    exact: true,
    component: Explore,
  },
   {
      path: URL_RESOURCES_PAGE,
      exact: true,
      component: Resources
  },
  {
      path: URL_ADD_EVENT_PAGE,
      exact: true,
      component: AddEvent
  },
  {
      path: URL_EDIT_EVENT_PAGE,
      exact: true,
      component: EditEvent
  },
  {
    path: URL_EVENTS_PAGE,
    exact: true,
    component: MainEvents,
  }
];
export const PublicRoutes = [
  {
    path: URL_LANDING_PAGE,
    exact: true,
    component: LandingPage,
  },
  {
    path: URL_ABOUT_PAGE,
    exact: true,
    component: About,
  },
  {
    path: URL_CONTACT_PAGE,
    exact: true,
    component: Contact,
  },
  {
    path: URL_NEWS_PAGE,
    exact: true,
    component: News,
  },
  {
    path: URL_NEWS_DETAILS_PAGE,
    exact: true,
    component: NewsDetails,
  },
  {
    path: URL_GET_INVOLVED_PAGE,
    exact: true,
    component: GetInvolvedPage,
  },
  {
    path: URL_REGISTRATION_PAGE,
    exact: true,
    component: Registration,
  },
  {
    path: URL_LOGIN_PAGE,
    exact: true,
    component: Login,
  },
  {
    path: URL_USER_EMAIL_CONFIRMATION,
    exact: true,
    component: Login,
  },
  {
    path: URL_FORGOT_PASSWORD_PAGE,
    exact: true,
    component: ForgotPassword,
  },
  {
    path: URL_RESET_PASSWORD_PAGE,
    exact: true,
    component: ForgotPassword,
  },
  {
    path: URL_PRIVACY_PAGE,
    exact: true,
    component: Privacy,
  },
  {
    path: URL_DASHBOARD,
    exact: true,
    component: () => window.location.href = ADMIN_URL
  },
  {
    path: URL_EVENT_DETAILS_PAGE,
    exact: true,
    component: EventDetailsPage
  },
];
