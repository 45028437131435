import React, {useEffect, useState} from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import Api from '../../Helpers/Api';
import { getImageData, modifyData } from '.';
import Loading from '../Common/Loading';

const News = () => {
    const [news, setNews] = useState([]);
    const [visibility, setVisibility] = useState(false)
    const [show, setShow] = useState(false)

    function trimMyString(string, maxLength, start = 0) {
        if(string) {
            if (string.length > maxLength) {
                let trimmedString = string.substr(start, maxLength);
                return (
                    trimmedString.substr(
                        start,
                        Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))
                    ) + " ..."
                );
            }
        }
        return string;
    }

    const getNewsData = async () => {
        const news = await Api.getNews();
        const newsImageIds = news.map((data) => data.image);
        const newsImageData = await getImageData(newsImageIds)
        const newsData = modifyData(news, newsImageData, 'imageUrl', 'image')
        setNews(newsData)
    }

    useEffect(() => {
        if (!show && visibility) {
            setShow(true)
        }
    }, [visibility])

    useEffect(() => {
        if (show) {
            getNewsData()
        }
    }, [show])

    return (
        <>
            <VisibilitySensor
              partialVisibility
              onChange={(isVisible) => {
                  setVisibility(isVisible)
              }}
            >
                {news?.length <= 0
                ? <Loading />
                :
                  <section id="news" className="blog-section pt-80 pb-80">
                      <div className="container">
                          <div className="section-title">
                              <h2>Latest News</h2>
                          </div>
                          <Swiper
                            className="team-slider owl-carousel owl-theme"
                            spaceBetween={30}
                            slidesPerView={3}
                            breakpoints={{
                                "0": {
                                    "slidesPerView": 1
                                },
                                "768": {
                                    "slidesPerView": 2
                                },
                                "1024": {
                                    "slidesPerView": 3
                                }
                            }}
                            pagination={{ clickable: true }}
                          >
                              {news?.map((item, index) => (
                                <SwiperSlide key={index}>
                                    <div className="blog-item">
                                        <div className="image">
                                            <Link to={"/news/" + item.slug}>
                                                <img src={item.imageUrl} alt="" />
                                            </Link>
                                        </div>
                                        <ul className="post-meta">
                                            <li>
                                                <i className="fa fa-calendar"></i>
                                                <Moment date={item.date} format="MMM DD, YYYY" />
                                            </li>
                                        </ul>
                                        <div className="content">
                                            <h3>
                                                <Link to={"/news/" + item.slug}>{item.name}</Link>
                                            </h3>
                                            <p>{trimMyString(item.description, 120)}</p>
                                            <Link to={"/news/" + item.slug} className="read-more">
                                                Read More
                                                <i className="flaticon-curve-arrow-1"></i>
                                            </Link>
                                        </div>
                                    </div>
                                </SwiperSlide>
                              ))}
                          </Swiper>
                      </div>

                  </section>
             }
            </VisibilitySensor>
        </>
    )
}

export default News
