import * as Yup from "yup";

export const initialValues = {
  firstname: "",
  lastname: "",
  username: "",
  email: "",
  country: "USA",
  password: "",
  passwordConfirmation: "",
};

export const RegisterSchema = Yup.object().shape({
  firstname: Yup.string().required("Required"),
  lastname: Yup.string().required("Required"),
  username: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  country: Yup.string().required("Required"),
  password: Yup.string().required("Required").min(6, 'Password is too short - should be 6 chars minimum.'),
  passwordConfirmation: Yup.string().required("Required")
    .required("Required")
    .oneOf([Yup.ref("password"), null], "Password must match"),
});
