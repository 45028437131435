import React, { useEffect, useState } from "react";
import Api from "../../../../Helpers/Api";
import Loading from "../../../Common/Loading";
import { Link, withRouter, useLocation, useParams } from "react-router-dom";
import userDefaultImg from "../../../../Assets/img/user-defoult-img.jpg";

const MainContent = (props) => {
  const loggedUsername = localStorage.getItem("username");
  const { username } = useParams();
  const location = useLocation();
  const [gridData, setgridData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeTab, setactiveTab] = useState("portfolio");
  const [isCancelled, setIsCancelled] = useState(false);

  const getGridData = async (tabType) => {
    setLoading(true);
    setgridData([]);
    try {
      var path;
      if (tabType === "inspirations") {
        path = props.selectedid + "/inspirations";
      }
      else if (tabType === "work-in-progress") {
        path = "/inprogress";
      }
      else {
        path = props.selectedid;
      }

      const result = await Api.getAllArtwork(path);

      if (result) {
        setgridData(result);
      }
    } catch (error) {
      console.error(error)
    }
    finally{
      setLoading(false);
    }
  };

  const getGrid = async () => {
    setLoading(true);
    if(props.selectedid !== null){
      if (location.search === '?tab=inspirations') {
        setactiveTab('inspirations');
        await getGridData('inspirations');
      }
      else {
        await getGridData(activeTab);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    !isCancelled && getGrid();

    return () => {
        setIsCancelled(true);
    }
  }, [props.selectedid]);

  useEffect(() => {
    setLoading(true);
  }, [])

  return (
    <div className="stories-content">
      <div className="collections-header is-home-v2">
        <a
          className={activeTab === "portfolio" ? "is-active" : ""}
          onClick={() => {
            getGridData("");
            setactiveTab("portfolio");
          }}
        >
          Portfolio
        </a>
        <a
          className={activeTab === "inspirations" ? "is-active" : ""}
          onClick={() => {
            getGridData("inspirations");
            setactiveTab("inspirations");
          }}
        >
          Inspirations
        </a>
        {loggedUsername === username && (
          <a
            className={activeTab === "work-in-progress" ? "is-active" : ""}
            onClick={() => {
              getGridData("work-in-progress");
              setactiveTab("work-in-progress");
            }}
          >
            Work In Progress
          </a>
        )}
      </div>
      <div className="collections-wrap is-active">
        <div className="collection">
          {/* <div className="header">
            <h4>Portfolio</h4>
          </div> */}
          <div className="w-100">
            {loading ? (
              <Loading />
            ) : (
              <div className="video-collection">
                {
                  gridData.length ?
                    gridData && gridData.map((item, index) => (
                      activeTab !== "inspirations"
                        ? (
                          activeTab === "work-in-progress"
                            ? <CardItem item={item} index={index} />
                            : (
                              activeTab === "portfolio" && (
                                <CardItem item={item} index={index} />
                              )
                            )
                          )
                        :
                          <CardItem item={item} index={index} />
                    ))
                  :
                  <div className="collection">No Artworks yet</div>
                }
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const CardItem = ({ item, index }) => {
  return (
    <div className="episode" key={item.id}>
      <Link to={"/p/" + item.username + "/" + item.slug}>
        <div className="episode-thumbnail">
          <div className="episode-overlay"></div>
          <div className="play-button">
            <i data-feather="play-circle"></i>
          </div>
          <img
              src={`${item.image}`}
              data-demo-src="assets/img/demo/video/home/collections/c20.jpg"
              alt=""
          />
        </div>
      </Link>
      <div
          className="episode-meta"
      >
        {/* <Link to={"/p/" + item.username}> */}
        <img
            src={item.userImage ? `${item.userImage}` : userDefaultImg }
            data-demo-src="assets/img/icons/logos/metamovies.svg"
            data-page-popover="2"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = userDefaultImg;
            }}
        />
        {/* </Link> */}
        <div className="info">
          <span className="d-inline-block text-truncate">{item.title}</span>
        </div>
      </div>
    </div>
  );
};

export default withRouter(MainContent);
