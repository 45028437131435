import React from "react";
import "./style.scss";
import sad from "../../../Assets/img/sad.png"
import {Link} from "react-router-dom";
import {URL_EXPLORE_PAGE} from "../../../Helpers/urls";

const NotFoundUser = () => {
 return (
     <div>
       <div className="not-found-user-page">
          <div className="row justify-content-center">
            <div className="col-md-7 text-center">
              <img src={sad} alt=""/>
              <h4>This page is not available</h4>
              <p>The link may not work or the page has been deleted. Check if the link you are trying to follow is correct.</p>
              <Link to={URL_EXPLORE_PAGE} className="button is-solid accent-button">Go to Explore page</Link>
            </div>
          </div>
       </div>
     </div>
 )
};

export default NotFoundUser;
