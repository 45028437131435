export const PATH_CTA = "items/call_to_action";
export const PATH_HEADERCAROUSEL = "items/header_carousel";
export const PATH_TEAMS = "items/team";
export const PATH_ABOUT = "items/about";
export const PATH_NEWS = "items/news";
export const PATH_ACCESS_TOKEN = "auth/authenticate";
export const PATH_IMAGES = "files";
export const PATH_CONTACT = "items/contact";
export const PATH_GET_INVOLVED = "items/get_involved";
export const PATH_FLYER = "items/flyers";
export const PATH_USER_EMAIL_CONFIRMATION = "auth/confirm-email";
export const PATH_REGISTER = "auth/register";
export const PATH_LOGIN = "auth/login";
export const PATH_FORGOT_PASSWORD = "auth/forgot-password";
export const PATH_RESET_PASSWORD = "auth/reset-password";
export const PATH_COUNTRY_STATE_CITY = "https://api.countrystatecity.in/v1/";
export const PATH_PROFILE = "profile";
export const PATH_ARTWORK = "artwork";
export const PATH_ARTWORK_COMMENTS = "comments";
export const PATH_ADD_ARTWORK_COMMENTS = "comments/new";
export const PATH_PROFILE_AVATAR = "profile/avatar";
export const PATH_CHANGE_PASSWORD = "/auth/change-password";
export const PATH_FOLLOW_USER = "follow";
export const PATH_UNFOLLOW_USER = "unfollow";
export const PATH_FEED = "artwork/feed";
export const PATH_GET_FEATURED_TAG="tag/featured";
export const PATH_GET_BY_TAGS="artwork/getbytags"
export const PATH_GET_EXPLORE = "explore";
export const PATH_GET_TAG = "tag";
export const PATH_GET_NOTIFICATION = "notification/user";
export const PATH_READ_NOTIFICATION = "notification/read";
export const PATH_ARTWORK_IMAGES = "/artworkimages";
export const PATH_SEARCH = "search/";
export const PATH_FOLLOWERS = "followers";
export const PATH_FOLLOWINGS = "followings";
export const PATH_REPORT_ART = "artowrk/report/";
export const PATH_RESOURCES_DATA = "items/resources?status=published"
export const PATH_RESOURCE_CATEGORY = "items/resource_category";
export const PATH_EVENTS = 'event/all';
export const PATH_PAST_EVENTS = 'event/pastevents';
export const PATH_EVENT_DETAILS = 'event';
export const PATH_EVENT_ADD_PARTICIPATION = 'event/addparticipation';
export const PATH_EVENT_REMOVE_PARTICIPATION = 'event/removeparticipation';
export const PATH_MY_EVENTS = 'event/myevents';
export const PATH_EVENT = 'event';
