import React, { useState } from "react";
import HeaderMenu from "../../../Common/HeaderMenu";
import SideMenu from "../SideMenu";
import featherIcon from "../../.../../../../Assets/img/icons/feather.svg";
import "./style.scss";
import { withRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { logout } from "../../../../Redux/actions/auth.action";
import { connect } from "react-redux";
import { Fragment } from "react";
import {
    searchChangeValue,
    handleSearch,
} from "../../../../Redux/actions/search.action";
import { Formik, Field, Form } from "formik";
import { useEffect } from "react";
import Api from "../../../../Helpers/Api";
import Loading from "../../../Common/Loading";

const Resources = (props) => {
    const [toggleMenu, setToggleMenu] = useState(false);
    const [categories, setCategories] = useState([])
    const [resources, setResources] = useState([]);
    const [filteredResources, setFilteredResources] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isCancelled, setIsCancelled] = useState(false);
    const [filteredCategoryIds, setFilteredCategoryIds] = useState([])

    const menuClick = () => {
        setToggleMenu(true);
    };

    const toggleChange = (data) => {
        setToggleMenu(data);
    };

    const handleSubmit = (value) => {
        const filtered = resources.filter(item => {
            const title = item.title;
            const searchString = value.search;

            const lowerCaseTitle = title.toLowerCase();
            const lowerCaseSearchString = searchString.toLowerCase();

            return lowerCaseTitle.includes(lowerCaseSearchString);
        })

        setFilteredResources(filtered);
    }

    const getResourcesData = async () => {
        setLoading(true);
        try {
            const result = await Api.getResources();
            if (result) {
                setResources(result);
                setFilteredResources(result)
            }
        } catch (error) {
            console.error(error)
        }
        finally {
            setLoading(false);
        }
    };

    const getResourceCategoriesData = async () => {
        setLoading(true);
        try {
            const result = await Api.getResourceCategories();
            if (result) {
                setCategories(result)
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const getCategoryIds = (arr) => {
       const ids = arr.map(item => {
            return item.resource_category
        })
        setFilteredCategoryIds(ids)
        
        return null
    }

    useEffect(() => {
        !isCancelled && getResourcesData();
        !isCancelled && getResourceCategoriesData();

        return () => {
          setIsCancelled(true)
        }
    }, [])

    useEffect(() => {
        getCategoryIds(filteredResources)
    }, [filteredResources])

    return (
        <div>
            <HeaderMenu />

            <div className="view-wrapper is-full">
                <div className="stories-wrapper is-home">
                    {/*Side menu*/}
                    <SideMenu
                        toggle={toggleMenu}
                        onchange={(e) => {
                            toggleChange(e);
                        }}
                    />
                    {/*Page content*/}
                    <div className="inner-wrapper">
                        <a
                            className="mobile-sidebar-trigger is-home-v2"
                            onClick={menuClick}
                        >
                            <svg className="feather feather-menu">
                                <use href={featherIcon + "#feather-menu"}></use>
                            </svg>
                        </a>
                        <Content
                            {...props}
                            resources={filteredResources}
                            categories={categories}
                            filteredCategoryIds={filteredCategoryIds}
                            loading={loading}
                            handleSubmit={handleSubmit}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

const Content = ({
    resources, 
    categories, 
    filteredCategoryIds, 
    loading, 
    handleSubmit 
}, ...props) => {
    const showHeading = (category, list) => {
        if (list.includes(category.id)) {
            return (
                <div className="resources-category">
                    <h5>{loading ? <Loading /> : category.name} </h5>
                </div>
            )
        }

        return        
    }

    return (
        <Fragment>
            <div className="resources-wrapper">
                <Search handleSubmit={handleSubmit} />

                <h3>Resources</h3>
                <p>Here's the list of some useful resources</p>

                {categories.length > 0 ? categories.map(category => (
                        <Fragment key={category.id}>
                            {showHeading(category, filteredCategoryIds)}
                            
                            {loading ? <Loading /> : (
                                resources.length > 0 && resources.map(item => {
                                    if (item.resource_category !== category.id) return

                                    return (
                                        <div className="resources-container" key={item.id}>
                                            <div className="resources-item">
                                                <div className="icon-wrapper">
                                                    <svg className="feather feather-menu">
                                                        <use href={featherIcon + "#book-open"}></use>
                                                    </svg>
                                                </div>
                                                <a href={item.url ?? "#"} target={item.url ? "_blank" : ""} >
                                                    {item.title}
                                                </a>
                                            </div>
                                        </div>
                                    )
                                }) 
                            )}
                        </Fragment>
                    )
                ) : <div>No Resources found</div>}
            </div>
        </Fragment>
    )
}

const Search = ({ handleSubmit }) => {
    return (
        <Formik
            initialValues={{search: ""}}
            onSubmit={(values) => {
                handleSubmit(values);
            }}>
            <Form>
                <div className="field-group">
                    <div className="search-icon">
                        <i className="fa fa-search"></i>
                    </div>
                    <Field name="search" id="resources-search" />
                </div>
                <button type="submit" className="button is-solid accent-button form-button">Search</button>
            </Form>
        </Formik>
    )
}

const mapReduxStateToProps = (state) => ({
    searchValue: state.search.searchValue,
    shouldSearch: state.search.shouldSearch,
});

export default connect(mapReduxStateToProps, {
    logout,
    searchChangeValue,
    handleSearch,
})(withRouter(Resources));
