import React, {useEffect, useState} from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import Api from '../../Helpers/Api';
import { URL_GET_INVOLVED_PAGE } from '../../Helpers/urls';
import Loading from '../Common/Loading';
import { getImageData, modifyData } from '.';

const Flyers = () => {
    const [getInvolved, setGetInvolved] = useState([]);
    const [imageShowSrc, setImageShowSrc] = useState(null);
    const [imageShow, setImageShow] = useState(null);
    const [flyer, setFlyers] = useState(null)
    const [visibility, setVisibility] = useState(false)
    const [show, setShow] = useState(false)

    const handleCloseImage = () => {
        setImageShow(false);
    };

    const handleImageShow = (url) => {
        setImageShowSrc(url);
        setImageShow(true);
    }

    const getEventData = async () => {
        const flyers = await Api.getFlyers();
        const flyersImageIds = flyers.map((data) => data.file_upload);
        const flyersImageData = await getImageData(flyersImageIds)
        const flyersData = modifyData(flyers, flyersImageData, 'file_upload', 'file_upload')
        setFlyers(flyersData);

        const invole = await Api.getInvolved();
        setGetInvolved(invole[0]);
    }

    useEffect(() => {
        if (!show && visibility) {
            setShow(true)
        }
    }, [visibility])

    useEffect(() => {
        if (show) {
            getEventData()
        }
    }, [show])

    return (
      <VisibilitySensor
        partialVisibility
        onChange={(isVisible) => {
            setVisibility(isVisible)
        }}
      >
          <section id="event" className="events-section pt-80 pb-80">
              <div className="container">
                  <div className="section-title">
                      <h2>{getInvolved.home_title}</h2>
                  </div>
                  <div className="row">
                      <div className="col-lg-6">
                          <div className="events-image-slider owl-theme">
                              {flyer?.length <= 0 ? (
                                <div className="custom-spinner">
                                    {" "}
                                    <Loading />{" "}
                                </div>
                              ) : (
                                <div>
                                    <Swiper
                                      className="events-image-slider owl-carousel owl-theme"
                                      slidesPerView={1}
                                      navigation
                                    >
                                        {flyer?.map((item, index) => (
                                          <SwiperSlide key={index}>
                                              <div className="item" onClick={() => handleImageShow(item.file_upload)}>
                                                  <img src={item.file_upload} alt="" />
                                              </div>
                                          </SwiperSlide>
                                        ))}
                                    </Swiper>
                                    <Modal
                                      className="team-modal"
                                      show={imageShow == true}
                                      onHide={handleCloseImage}>
                                        <button type="button" className="close" onClick={handleCloseImage}>
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                        <img
                                          src={imageShowSrc}
                                          alt="img"
                                          className="img-fluid"
                                        />
                                    </Modal>
                                </div>
                              )}
                          </div>
                      </div>
                      <div className="col-lg-6">
                          <div className="events-item">
                              <div className="events-text">
                                  <p dangerouslySetInnerHTML={{ __html: getInvolved.home_description }}></p>
                              </div>

                              <div className="events-btn d-flex justify-content-center justify-content-lg-start">
                                  <Link className="default-btn" to={URL_GET_INVOLVED_PAGE}>
                                      Learn More
                                  </Link>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div className="events-shape">
                  <div className="shape-one">
                      <img src="assets/img/events/shape.png" alt="" />
                  </div>

                  <div className="shape-two">
                      <img src="assets/img/events/shape2.png" alt="" />
                  </div>
              </div>
          </section>
      </VisibilitySensor>

    )
}

export default Flyers
