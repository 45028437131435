import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Link, useLocation } from 'react-router-dom'
import logo from '../../../Assets/img/logo-main.png'
import logowhite from '../../../Assets/img/logo-main-white.png';
import {
  URL_LANDING_PAGE,
  URL_EXPLORE_PAGE,
} from "../../../Helpers/urls";
import { menu } from "../Menu";

let loggedUserId = localStorage.getItem("userId");
const Header = (props) => {
  const [mobileNav, setMobileNav] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  const [selected, setSelected] = useState('')
  const { pathname } = useLocation();

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true);

    return () => {
      window.removeEventListener('scroll', handleScroll, false)
    }
  }, []); 

  useEffect(() => {
    setSelected(pathname)
  }, [pathname])

  const handleScroll = () => {
    window.onscroll = (e) => {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      if (top > 120) {
        setShowMenu(true)
      } else {
        setShowMenu(false)
      }
    }
  }

  return (
    <div className={"nav-area fixed-top" + (showMenu ? ' is-sticky' : '')}>
      <div className="navbar-area">
        <div className="mobile-nav">
          <Link className="logo" to={loggedUserId ? URL_EXPLORE_PAGE : URL_LANDING_PAGE}>
            <img src={logo} alt="Logo" />
          </Link>

        </div>

        <div className="mobile-nav mean-container">
          <Link className="logo" to={loggedUserId ? URL_EXPLORE_PAGE : URL_LANDING_PAGE}>
            <img src={logowhite} alt="Logo" />
          </Link>
          <div className="mean-bar">
            {!mobileNav ? (
              <a
                href="#"
                onClick={() => setMobileNav(!mobileNav)}
                className="meanmenu-reveal"
              >
                <span>
                  <span>
                    <span></span>
                  </span>
                </span>
              </a>
            ) : (
              <a
                href="#nav"
                onClick={() => setMobileNav(!mobileNav)}
                className="meanmenu-reveal meanclose"
              >
                X
              </a>
            )}
            <nav className="mean-nav">
              {mobileNav && (
                <ul className="navbar-nav m-auto">
                  {
                    menu.map((menus, index) => (
                      <li className="nav-item" key={index} >
                        <Link to={menus.pathname} className={selected === menus.pathname ? 'nav-link active' : 'nav-link'}>
                          {menus.name}
                        </Link>
                      </li>
                    ))
                  }
                </ul>
              )}
            </nav>
          </div>
        </div>

        <div className="main-nav">
          <nav className="navbar navbar-expand-md">
            <div className="container">
              <Link to={loggedUserId ? URL_EXPLORE_PAGE : URL_LANDING_PAGE} className="navbar-brand logo-1">
                <img src={logo} alt="Logo" />
              </Link>
              <Link to={loggedUserId ? URL_EXPLORE_PAGE : URL_LANDING_PAGE} className="navbar-brand logo-2">
                <img src={logo} alt="Logo" />
              </Link>

              <div className="collapse navbar-collapse mean-menu">
                <ul className="navbar-nav m-auto">
                  {
                    menu.map((menus, index) => (
                      menus.name !== 'Donate' ? <li className="nav-item" key={index}>
                        <Link to={menus.pathname} className={selected === menus.pathname ? 'nav-link active' : 'nav-link'}>
                          {menus.name}
                        </Link>
                      </li> : ''

                    ))
                  }
                </ul>
                <div className="others-option">
                  <div>
                    <div className="option-item">
                      <Link to="/get-involved#donate" className="default-btn">
                        Donate
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};
export default Header;
