import jwtDecode from "jwt-decode";

export const ACTION_TYPES = {
  LOGIN: "LOGIN",
  LOGOUT: "LOGOUT",
};

export const login = (role, accessToken, username) => {
  localStorage.setItem("accessToken", accessToken);
  localStorage.setItem("roles", role);
  localStorage.setItem("isLoggedIn", true);
  let userId = jwtDecode(accessToken);
  localStorage.setItem("userId", userId.sub);
  localStorage.setItem("username", username);
  return {
    type: ACTION_TYPES.LOGIN,
    accessToken,
    roles: role,
    username: username
  };
};

export const logout = () => {
  localStorage.clear();
  return {
    type: ACTION_TYPES.LOGOUT,
  };
};
