import * as handler from "./handler";
import { PATH_FEED } from "./path";


const getFeed = (UserId) => {
    return handler
      .get(PATH_FEED + `?UserId=` + UserId)
      .then((res) => {
        return res.model;
      })
      .catch((error) => {
        console.log("error " + error);
      });
  };
  export  default {
    getFeed
  }