import React from "react";
import loadingImg from "../../../Assets/img/loading-img.gif";
import "./style.scss";

const Loading = () => {
  return (
      <>
        <div className="loading">
          <img src={loadingImg} width="100" alt=""/>
        </div>
      </>
  )
};

export default Loading
