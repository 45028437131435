import React from "react";

const ImageRequirements = (props) => {
  return (
      <div className="image-requirements">
        <p>Images must be at least {props.width} x {props.length} in size.</p>
      </div>
  )
};

export default ImageRequirements
