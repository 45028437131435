import { ACTION_TYPES } from "./../actions/search.action";

const initialState = {
  searchValue: localStorage.getItem("searchValue") || "",  
  shouldSearch : false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.CHANGE_VALUE:{      
      return {
        searchValue: action.searchValue
      };
    }
    case ACTION_TYPES.SHOULD_SEARCH:{      
      return {
        ...state,
        shouldSearch: action.isSearched
      };
    }
    default:
      return state;
  }
};
