import React, {useEffect, useState} from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import Api from '../../Helpers/Api';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Spinner } from 'reactstrap';
import shapeImg from "../../Assets/img/slider/shape.png";
import { getImageData, modifyData } from '.';

const HeaderCarousel = () => {
    const [headerCarousel, setHeaderCarousel] = useState([]);
    const [loading, setLoading] = useState(true);
    const [visibility, setVisibility] = useState(false)
    const [show, setShow] = useState(false)

    const getHeaderData = async () => {
        setLoading(true)
        await Api.getAccessToken();
        const header = await Api.getHeaderCarousel();
        const headerCarousalImageIds = header.map((data) => data.background_image);
        const headerCarosalImageData = await getImageData(headerCarousalImageIds)
        const headerCarousel = modifyData(header, headerCarosalImageData, 'imageUrl', 'background_image')
        setHeaderCarousel(headerCarousel)
        setLoading(false)
    }

    useEffect(() => {
        if (!show && visibility) {
            setShow(true)
        }
    }, [visibility])

    useEffect(() => {
        if (show) {
            getHeaderData()
        }
    }, [show])

    return (
      <VisibilitySensor
        partialVisibility
        onChange={(isVisible) => {
            setVisibility(isVisible)
        }}
      >
          <div className="container-fluid p-0">
              {loading ? (
                <div className="custom-spinner" style={{height: '100vh'}}>
                    {" "}
                    <Spinner />{" "}
                </div>
              ) : (
                <Swiper
                  className="home-slider owl-carousel owl-theme"
                  navigation
                  autoplay={{ clickable: true }}
                  slidesPerView={1}
                >
                    {headerCarousel?.map((item, index) => (
                      <SwiperSlide key={index}>
                          <div
                            className="home-item item-bg1 active"
                            style={{
                                background: `url(${item.imageUrl}) no-repeat center center`,
                            }}
                          >
                              <div className="d-table">
                                  <div className="d-table-cell">
                                      <div className="container">
                                          <div className="slider-content">
                                              <span>{item.title}</span>
                                              <h2>{item.description}</h2>
                                              <div className="slider-btn">
                                                  <a href={item.button1_url} className="default-btn">
                                                      {item.button1_text}
                                                  </a>
                                                  {item.button2_url && (
                                                    <a href={item.button2_url} className="optional-btn">
                                                        {item.button2_text}
                                                    </a>
                                                  )}
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>

                              <div className="shape">
                                  <img src={shapeImg} alt="" />
                              </div>
                          </div>
                      </SwiperSlide>
                    ))}
                </Swiper>
              )}
          </div>
      </VisibilitySensor>

    )
}

export default HeaderCarousel
