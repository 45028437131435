import * as handler from "./handler";
import { PATH_RESOURCES_DATA, PATH_RESOURCE_CATEGORY } from "./path";


const getResources = () => {
    return handler
      .get(PATH_RESOURCES_DATA, {}, true)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.log("error " + error);
      });
    };

const getResourceCategories = () => {
    return handler
        .get(
            PATH_RESOURCE_CATEGORY, 
            {}, 
            true
        )
        .then((res) => {
            return res.data
        })
        .catch((error) => {
            console.log("error ", + error)
        })
}

export  default {
    getResources,
    getResourceCategories
}