import auth from "./auth";
import home from "./home";
import about from "./about";
import news from "./news";
import profile from "./profile";
import artwork from "./artwork";
import feed from "./feed";

import explore from "./explore";
import resources from "./resources";
import events from './events';

export default {
  ...auth,
  ...home,
  ...about,
  ...news,
  ...profile,
  ...artwork,
  ...feed,
  ...explore,
  ...resources,
  ...events
};
