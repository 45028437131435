export const ACTION_TYPES = {
  CHANGE_VALUE: "CHANGE_VALUE",
  SHOULD_SEARCH: "SHOULD_SEARCH"
};

export const searchChangeValue = (searchValue) => {
  return {
    type: ACTION_TYPES.CHANGE_VALUE,
    searchValue,
  };
};

export const handleSearch = (isSearched) => {
  return {
    type: ACTION_TYPES.SHOULD_SEARCH,
    isSearched
  };
};