import React, { useEffect, useState } from "react";
import "../style.scss";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import script from "../../../../Assets/img/icons/feather.svg";
import userDefaultImg from "../../../../Assets/img/user-defoult-img.jpg";
import Api from "../../../../Helpers/Api";
import { Link, useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { URL_USER_PROFILE } from "../../../../Helpers/urls";
import JwtDecode from "../../../Common/JwtDecode";
import Loading from "../../../Common/Loading";
import csc from "country-state-city";
import ImageRequirements from "../../../Common/ImageRequirements";
const EditPageContent = (props) => {
  let UserId = localStorage.getItem("userId"); //get userid
  let history = useHistory();
  const [loading, setLoading] = useState(false);
  const [formUpdated, setFormUpdated] = useState(false);
  const [file, setFile] = useState("");
  const [country, setCountry] = useState([]);
  const [region, setRegion] = useState([]);
  const [userdata, setuserdata] = useState({
    id: "",
    firstName: "",
    lastName: "",
    userName: "",
    email: "",
    mission: "",
    pronoun: "",
    city: "",
    stateRegion: "",
    country: "",
  });

  const toastMessage = (type, message) => {
    toast[type](message);
  };
  const getAvatar = async () => {
    const result = await Api.getAvatar(UserId);
    if(result)
    setFile(result);
  };

  const getSideMenu = async () => {
    try {
      const result = await Api.getProfile(UserId);
      if (result) {
        setuserdata({
          id: result.id,
          firstName: result.firstName ? result.firstName : "",
          lastName: result.lastName ? result.lastName : "",
          userName: result.username ? result.username : "",
          email: result.email ? result.email : "",
          mission: result.mission ? result.mission : "",
          pronoun: result.pronoun ? result.pronoun : "",
          city: result.city ? result.city : "",
          stateRegion: result.stateRegion ? result.stateRegion : "",
          country: result.country != "" ? result.country : "US",
        });
        setFormUpdated(false);
        const Statesresult = csc.getStatesOfCountry(
          result.country != "" ? result.country : "US"
        );
        setRegion(Statesresult);
      }
    } catch (error) {
      console.error("getSideMenu -> error", error);
    }
  };

  function selectCountry() {
    const result = csc.getAllCountries();
    setCountry(result);
  }

  function selectRegion(e) {
    setRegion([]);
    setuserdata({
      ...userdata,
      country: e.target.value,
      stateRegion: "",
    });
    const result = csc.getStatesOfCountry(e.target.value);
    setRegion(result);
  }

  function selectCity(e) {
    setuserdata({
      ...userdata,
      stateRegion: e.target.value,
    });
  }

  function checkChange() {
    setFormUpdated(true);
  }

  const handleSubmitClick = async (formValue, resetForm, setSubmitting) => {
    setSubmitting(true)
    try {
      const result = await Api.editProfile(formValue);
      if (result.message === "Success" && result.statusCode === 200) {
        toastMessage("success", "Changes Saved");
        setTimeout(() => {
          history.push(`/p/${userdata.userName}`);
        }, 3000);
      }
    } catch (error) {
      console.error(error)
    }
    finally{
      setSubmitting(false)
    }
  };

  const handlePasswordSubmitClick = async (formValue, resetForm) => {
    const result = await Api.changePassword(formValue);
    if (result.statusCode === 200) {
      toastMessage("success", "Password Successfully changed.");
      resetForm();
    } else {
      toastMessage("error", result ? result : "Ooops! Please try again");
    }
  };

  function openImgUpload() {
    document.getElementById("hidden-img-input").click();
  }

  // Handles file upload event and updates state
  function handleChange(event) {
    getBase64(event.target.files[0], (result) => {
      setFile(result);
    });
  }
  const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  function handleUpload() {
    setLoading(true);

    const obj = {
      userId: UserId,
      image: file.substring(file.indexOf(",") + 1),
    };
    Api.uploadAvatar(obj)
      .then((res) => {
        if (res === "Success") {
          toastMessage("success", "Avatar Updated Successfully.");
          if (formUpdated) {
            document.getElementById("change-user-info").click();
            setTimeout(() => {
              history.push(`/p/${userdata.userName}`);
            }, 2000);
          } else {
            setTimeout(() => {
              history.push(`/p/${userdata.userName}`);
            }, 2000);
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }
  //
  useEffect(() => {
    getSideMenu();
    getAvatar();
    selectCountry();
  }, []);

  const LoginSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    userName: Yup.string().required("Required"),
    pronoun: Yup.string()
      .min(2, "Too Short!")
      .max(10, "Too Long!"),
    lastName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    mission: Yup.string().max(1000, "Max characters is 1000!"),
  });

  const PasswordSchema = Yup.object().shape({
    oldPassword: Yup.string()
      .required("Required")
      .min(6, "Password is too short - should be 6 chars minimum."),
    password: Yup.string()
      .required("Required")
      .min(6, "Password is too short - should be 6 chars minimum."),
    confirmPassword: Yup.string()
      .required("Required")
      .oneOf([Yup.ref("password"), null], "Password must match"),
  });

  return (
    <>
      <ToastContainer />
      <div className="settings-wrapper">
        <div id="general-settings" className="settings-section is-active">
          <div className="settings-panel">
            <div className="title-wrap">
              <a className="mobile-sidebar-trigger">
                <i data-feather="menu"></i>
              </a>
              <h2>Account Settings</h2>
            </div>

            <div className="settings-form-wrapper">
              <div className="settings-form">
                <div className="">
                  <Formik
                    enableReinitialize={true}
                    initialValues={userdata}
                    validationSchema={LoginSchema}
                    onSubmit={(values, { resetForm, setSubmitting }) => {
                      handleSubmitClick(values, resetForm, setSubmitting);
                    }}
                  >
                    {({ errors, touched, handleSubmit, isSubmitting }) => (
                      <Form className="w-100">
                        <div className="columns is-multiline">
                          <div className="column is-6 pb-0">
                            <div
                              className={
                                "field field-group" +
                                (errors.firstName && touched.firstName
                                  ? " is-invalid"
                                  : "")
                              }
                            >
                              <label>First Name</label>
                              <div className="control has-icon">
                                <Field
                                  type="Text"
                                  name="firstName"
                                  className="input is-fade"
                                  onBlur={checkChange}
                                ></Field>
                                <div className="form-icon">
                                  <svg className="feather feather-user">
                                    <use href={script + "#feather-user"}></use>
                                  </svg>
                                </div>
                              </div>
                              <div
                                className={
                                  "errors-msg" +
                                  (errors.firstName && touched.firstName
                                    ? " show-error"
                                    : "")
                                }
                              >
                                {errors.firstName}
                              </div>
                            </div>
                          </div>

                          <div className="column is-6 pb-0">
                            <div
                              className={
                                "field field-group" +
                                (errors.lastName && touched.lastName
                                  ? " is-invalid"
                                  : "")
                              }
                            >
                              <label>LAST NAME</label>
                              <div className="control has-icon">
                                <Field
                                  type="Text"
                                  name="lastName"
                                  className="input is-fade"
                                  onBlur={checkChange}
                                ></Field>
                                <div className="form-icon">
                                  <svg className="feather feather-user">
                                    <use href={script + "#feather-user"}></use>
                                  </svg>
                                </div>
                              </div>
                              <div
                                className={
                                  "errors-msg" +
                                  (errors.lastName && touched.lastName
                                    ? " show-error"
                                    : "")
                                }
                              >
                                {errors.lastName}
                              </div>
                            </div>
                          </div>

                          <div className="column is-6 pb-0">
                            <div
                              className={
                                "field field-group" +
                                (errors.userName && touched.userName
                                  ? " is-invalid"
                                  : "")
                              }
                            >
                              <label>User Name</label>
                              <div className="control has-icon">
                                <Field
                                  type="text"
                                  name="userName"
                                  className="input is-fade"
                                  onBlur={checkChange}
                                ></Field>
                                <div className="form-icon">
                                  <svg className="feather feather-user">
                                    <use href={script + "#feather-user"}></use>
                                  </svg>
                                </div>
                              </div>
                              <div
                                className={
                                  "errors-msg" +
                                  (errors.userName && touched.userName
                                    ? " show-error"
                                    : "")
                                }
                              >
                                {errors.userName}
                              </div>
                            </div>
                          </div>

                          <div className="column is-6 pb-0">
                            <div
                              className={
                                "field field-group" +
                                (errors.email && touched.email
                                  ? " is-invalid"
                                  : "")
                              }
                            >
                              <label>Email</label>
                              <div className="control has-icon">
                                <Field
                                  type="Email"
                                  name="email"
                                  className="input is-fade"
                                  onBlur={checkChange}
                                ></Field>
                                <div className="form-icon">
                                  <svg className="feather feather-mail">
                                    <use href={script + "#feather-mail"}></use>
                                  </svg>
                                </div>
                              </div>

                              <div
                                className={
                                  "errors-msg" +
                                  (errors.email && touched.email
                                    ? " show-error"
                                    : "")
                                }
                              >
                                {errors.email}
                              </div>
                            </div>
                          </div>

                          <div className="column is-12 pb-0">
                            <div
                              className={
                                "field field-group" +
                                (errors.mission && touched.mission
                                  ? " is-invalid"
                                  : "")
                              }
                            >
                              <label>My Mission</label>
                              <div className="control has-icon">
                                <Field
                                  component="textarea"
                                  name="mission"
                                  className="input is-fade"
                                  rows={3}
                                  onBlur={checkChange}
                                ></Field>
                                <div className="form-icon">
                                  <svg className="feather feather-message">
                                    <use
                                      href={script + "#feather-message"}
                                    ></use>
                                  </svg>
                                </div>
                              </div>
                              <div
                                className={
                                  "errors-msg" +
                                  (errors.mission && touched.mission
                                    ? " show-error"
                                    : "")
                                }
                              >
                                {errors.mission}
                              </div>
                            </div>
                          </div>

                          <div className="column is-6 pb-0">
                            <div className="field field-group">
                              <label>Country</label>
                              <div className="control has-icon">
                                <select
                                  id="country"
                                  value={userdata.country}
                                  disabled={!country.length}
                                  name="country"
                                  onChange={selectRegion}
                                  className="input is-fade"
                                  onBlur={checkChange}
                                >
                                  {country.length ? (
                                    <SelectOption
                                      object={country}
                                      selectedValue={userdata.country}
                                    />
                                  ) : (
                                    <option>...</option>
                                  )}
                                </select>
                                <div className="form-icon">
                                  <svg className="feather feather-globe">
                                    <use href={script + "#feather-globe"}></use>
                                  </svg>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="column is-6 pb-0">
                            <div
                              className={
                                "field field-group" +
                                (errors.stateRegion && touched.stateRegion
                                  ? " is-invalid"
                                  : "")
                              }
                            >
                              <label>
                                {userdata.country && userdata.country === "US"
                                  ? "State"
                                  : "Region"}
                              </label>
                              <div className="control has-icon">
                                <select
                                  value={userdata.stateRegion}
                                  disabled={!region.length}
                                  name="stateRegion"
                                  onChange={selectCity}
                                  className="input is-fade"
                                  onBlur={checkChange}
                                >
                                  <option hidden>
                                    Please Select State Region
                                  </option>
                                  {region.length ? (
                                    <SelectOption
                                      object={region}
                                      selectedValue={userdata.stateRegion}
                                    />
                                  ) : (
                                    <option>...</option>
                                  )}
                                </select>
                                <div className="form-icon">
                                  <svg className="feather feather-globe">
                                    <use href={script + "#feather-globe"}></use>
                                  </svg>
                                </div>
                              </div>
                              <div
                                className={
                                  "errors-msg" +
                                  (errors.stateRegion && touched.stateRegion
                                    ? " show-error"
                                    : "")
                                }
                              >
                                {errors.stateRegion}
                              </div>
                            </div>
                          </div>

                          <div className="column is-6 pb-0">
                            <div
                              className={
                                "field field-group" +
                                (errors.city && touched.city
                                  ? " is-invalid"
                                  : "")
                              }
                            >
                              <label>City</label>
                              <div className="control has-icon">
                                <Field
                                  type="text"
                                  name="city"
                                  className="input is-fade"
                                  onBlur={checkChange}
                                ></Field>
                                <div className="form-icon">
                                  <svg className="feather feather-globe">
                                    <use href={script + "#feather-globe"}></use>
                                  </svg>
                                </div>
                              </div>
                              <div
                                className={
                                  "errors-msg" +
                                  (errors.city && touched.city
                                    ? " show-error"
                                    : "")
                                }
                              >
                                {errors.city}
                              </div>
                            </div>
                          </div>

                          <div className="column is-6 pb-0">
                            <div
                              className={
                                "field field-group" +
                                (errors.pronoun && touched.pronoun
                                  ? " is-invalid"
                                  : "")
                              }
                            >
                              <label>Pronoun</label>
                              <div className="control has-icon">
                                <Field
                                  defaultValue={userdata.pronoun}
                                  name="pronoun"
                                  className="input is-fade"
                                ></Field>
                                <div className="form-icon">
                                  <svg className="feather feather-pronoun">
                                    <use
                                      href={script + "#feather-pronoun"}
                                    ></use>
                                  </svg>
                                </div>
                              </div>
                              <div
                                className={
                                  "errors-msg" +
                                  (errors.pronoun && touched.pronoun
                                    ? " show-error"
                                    : "")
                                }
                              >
                                {errors.pronoun}
                              </div>
                            </div>
                          </div>

                          <div className="column is-12">
                            <div className="buttons">
                              <button
                                id="change-user-info"
                                className="button is-solid accent-button form-button"
                                onClick={handleSubmit}
                                disabled={isSubmitting}
                              >
                                Save Changes
                              </button>
                            </div>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>

              <div className="illustration">
                <h2 className="text-center mb-5">Change Profile image</h2>
                <div className="user-img-wrapper">
                  {file ? (
                    <ImageThumb image={file} />
                  ) : (
                    <img
                      src={userDefaultImg}
                      className="user-img"
                      alt="user-img"
                    />
                  )}
                  <div
                    className="upload-img-icon"
                    title="Change Profile Image"
                    onClick={openImgUpload}
                  >
                    <svg className="feather feather-camera">
                      <use href={script + "#feather-camera"}></use>
                    </svg>
                  </div>
                </div>

                <ImageRequirements width="200" length="200" />

                <div
                  id="upload-box"
                  className="mt-5 d-flex justify-content-center"
                >
                  <input
                    type="file"
                    onChange={handleChange}
                    id="hidden-img-input"
                    accept="image/png, image/jpeg"
                  />
                  {loading ? (
                    <Loading />
                  ) : (
                    <button
                      id="submit-profile-picture"
                      className="button is-solid accent-button raised"
                      disabled={!file}
                      onClick={handleUpload}
                    >
                      Use Picture
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Password */}
          <div className="settings-panel mt-5">
            <div className="title-wrap">
              <a className="mobile-sidebar-trigger">
                <i data-feather="menu"></i>
              </a>
              <h2>Update your password</h2>
            </div>

            <div className="settings-form-wrapper">
              <div className="settings-form" style={{ maxWidth: "100%" }}>
                <div className="">
                  <Formik
                    initialValues={{
                      oldPassword: "",
                      password: "",
                      confirmPassword: "",
                    }}
                    validationSchema={PasswordSchema}
                    onSubmit={(values, { resetForm }) => {
                      handlePasswordSubmitClick(values, resetForm);
                    }}
                  >
                    {({ errors, touched, handleSubmit }) => (
                      <Form className="w-100">
                        <div className="columns is-multiline">
                          <div className="column is-4 pb-0">
                            <div
                              className={
                                "field field-group" +
                                (errors.oldPassword && touched.oldPassword
                                  ? " is-invalid"
                                  : "")
                              }
                            >
                              <label>Old Password</label>
                              <div className="control has-icon">
                                <Field
                                  type="password"
                                  name="oldPassword"
                                  className="input is-fade"
                                ></Field>
                                <div className="form-icon">
                                  <svg className="feather feather-password">
                                    <use
                                      href={script + "#feather-password"}
                                    ></use>
                                  </svg>
                                </div>
                              </div>
                              <div
                                className={
                                  "errors-msg" +
                                  (errors.oldPassword && touched.oldPassword
                                    ? " show-error"
                                    : "")
                                }
                              >
                                {errors.oldPassword}
                              </div>
                            </div>
                          </div>

                          <div className="column is-4 pb-0">
                            <div
                              className={
                                "field field-group" +
                                (errors.password && touched.password
                                  ? " is-invalid"
                                  : "")
                              }
                            >
                              <label>New Password</label>
                              <div className="control has-icon">
                                <Field
                                  type="password"
                                  name="password"
                                  className="input is-fade"
                                ></Field>
                                <div className="form-icon">
                                  <svg className="feather feather-password">
                                    <use
                                      href={script + "#feather-password"}
                                    ></use>
                                  </svg>
                                </div>
                              </div>
                              <div
                                className={
                                  "errors-msg" +
                                  (errors.password && touched.password
                                    ? " show-error"
                                    : "")
                                }
                              >
                                {errors.password}
                              </div>
                            </div>
                          </div>

                          <div className="column is-4 pb-0">
                            <div
                              className={
                                "field field-group" +
                                (errors.confirmPassword &&
                                touched.confirmPassword
                                  ? " is-invalid"
                                  : "")
                              }
                            >
                              <label>Repeat Password</label>
                              <div className="control has-icon">
                                <Field
                                  type="password"
                                  name="confirmPassword"
                                  className="input is-fade"
                                ></Field>
                                <div className="form-icon">
                                  <svg className="feather feather-password">
                                    <use
                                      href={script + "#feather-password"}
                                    ></use>
                                  </svg>
                                </div>
                              </div>
                              <div
                                className={
                                  "errors-msg" +
                                  (errors.confirmPassword &&
                                  touched.confirmPassword
                                    ? " show-error"
                                    : "")
                                }
                              >
                                {errors.confirmPassword}
                              </div>
                            </div>
                          </div>

                          <div className="column is-12">
                            <div className="buttons">
                              <button
                                className="button is-solid accent-button form-button"
                                onClick={handleSubmit}
                              >
                                Update Password
                              </button>
                            </div>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const ImageThumb = ({ image }) => {
  return <img src={image} alt={image.name} className="user-img" />;
};

const SelectOption = ({ object, selectedValue = "US" }) => {
  if (object && object.length) {
    return object.map((item, index) => (
      <option value={item.isoCode} key={index}>
        {item.name}
      </option>
    ));
  }
};

export default EditPageContent;
