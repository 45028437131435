import React, { useEffect } from "react";
import { Switch, Route, useLocation, useHistory } from "react-router-dom";
import ScrollToTop from "../Components/Common/ScrollToTop";
import RoutesList, { PublicRoutes } from "./routes-list";
import ProtectedRoute from "./private";

function usePageViews() {
  let location = useLocation();
  let history = useHistory();

  useEffect(
    () => {
      let username = localStorage.getItem('username');
      if (location.pathname === '/p/' || location.pathname === '/p') {
        if (username) {
          window.location = '/p/' + localStorage.getItem('username')
        } else {
          history.push('/login')
        }
      }
    },
    [location]
  )
}


function Routes() {
  usePageViews();
  return (
    <ScrollToTop>
      <Switch>
        {PublicRoutes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            component={route.component}
            exact={route.exact}
          />
        ))}
        <ProtectedRoute>
          {RoutesList.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              component={route.component}
            />
          ))}
        </ProtectedRoute>
      </Switch>
    </ScrollToTop>
  );
}

export default Routes;
