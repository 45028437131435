import * as handler from "./handler";
import { 
    PATH_EVENTS, 
    PATH_PAST_EVENTS, 
    PATH_EVENT_DETAILS, 
    PATH_EVENT_ADD_PARTICIPATION, 
    PATH_EVENT_REMOVE_PARTICIPATION,
    PATH_MY_EVENTS,
    PATH_EVENT
} from './path';

const getEvents = () => {
  return handler
    .get(PATH_EVENTS, {}, false)
    .then((res) => {
      return res.model
    });
}

const getPastEvents = () => {
  return handler
    .get(PATH_PAST_EVENTS, {}, false)
    .then((res) => {
      return res.model
  })
}

const getEventDetails = (slug) => {
  return handler
    .get(
      `${PATH_EVENT_DETAILS}/${slug}`,
      {},
      false)
    .then((res) => {
      return res.model
    })
}

const addParticipation = (id) => {
    return handler
        .post(`${PATH_EVENT_ADD_PARTICIPATION}?artEventId=${id}`)
        .then((res) => {
            return res;
        })
}

const removeParticipation = (id) => {
    return handler
        .delet(`${PATH_EVENT_REMOVE_PARTICIPATION}?artEventId=${id}`)
        .then((res) => {
            return res;
        })
}

const getMyEvents = (userId) => {
    return handler
        .get(`${PATH_MY_EVENTS}?userId=${userId}`)
        .then((res) => {
            return res
        })
}

const createEvent = (payload) => {
    return handler
        .post(PATH_EVENT, payload)
        .then((res) => {
            return res;
        })
        .catch(error => {
            console.log('createEvent Error: ', error)
        })
}

const updateEvent = (payload) => {
    return handler
        .put(PATH_EVENT, payload)
        .then((res) => {
            return res;
        })
        .catch(error => {
            console.log('updateEvent Error: ', error)
            return error
        })
}

const deleteEvent = (id) => {
    return handler
        .delet(`${PATH_EVENT}?Id=${id}`)
        .then(res => {
            return res
        })
        .catch(error => {
            console.error('deleteEventError: ', error)
        })
}

export default {
  getEvents,
  getPastEvents,
  getEventDetails,
  addParticipation,
  removeParticipation,
  getMyEvents,
  createEvent,
  updateEvent,
  deleteEvent
}