import React, { useEffect, useState, useRef } from "react";
import "./style.scss";
import script from "../../../Assets/img/icons/feather.svg";

const Dropdown = ({ img, options, isAccountDropdown = false, onChange, icon, viaButton = false, indicator = false, isNotification = false, checkOpen }) => {
  const node = useRef();

  const [open, setOpen] = useState(false);

  const handleClick = (e) => {
    if (node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setOpen(false);
  };

  const handleChange = (selectedValue) => {
    onChange(selectedValue);
    setOpen(false);
  };

  const openMenu = (e) => {
    e.preventDefault();

    if (isNotification) {
      checkOpen();
    }
    setOpen(!open);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  return (
      <div ref={node} className={(isAccountDropdown ? 'is-account ' : 'is-icon ') + 'custom-dropdown navbar-item drop-trigger' }  onClick={(e) => { openMenu(e) }}>
        {isAccountDropdown
          ?
            <div className="user-image">
              <img src={img} alt="" />
              <span className="indicator"></span>
            </div>
          : (
            viaButton
              ? <button className="button is-rounded">{viaButton}</button>
              : <a className={'icon-link' + (indicator ? ' is-active' : '')}>
                  <svg className="feather feather-mail">
                    <use href={script + icon}></use>
                  </svg>
                  <span className="indicator"></span>
                </a>
            )
        }
        <ul className={(open ? "nav-drop is-active" : "nav-drop ") + (isAccountDropdown ? ' is-account-dropdown ' : '')}>
          <div className="inner">
            {options}
          </div>
        </ul>
      </div>
  );
};

export default Dropdown;
