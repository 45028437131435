import React, { useEffect, useState } from "react";
import HeaderMenu from "../../../Common/HeaderMenu";
import SideMenu from "../SideMenu";
import featherIcon from "../../.../../../../Assets/img/icons/feather.svg";
import Api from "../../../../Helpers/Api";
import Loading from "../../../Common/Loading";
import userDefaultImg from "../../../../Assets/img/user-defoult-img.jpg";
import "./style.scss";
import { Formik, Field, Form } from "formik";
import Select from "react-select";
import { withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as $ from "jquery";
import { logout } from "../../../../Redux/actions/auth.action";
import defaultImg from "../../../../Assets/img/Image_Not_Available.png";
import { connect } from "react-redux";
import { Fragment } from "react";
import {
  searchChangeValue,
  handleSearch,
} from "../../../../Redux/actions/search.action";

const Explore = (props) => {
  const [toggleMenu, setToggleMenu] = useState(false);

  const menuClick = () => {
    setToggleMenu(true);
  };

  const toggleChange = (data) => {
    setToggleMenu(data);
  };
  return (
    <div>
      <HeaderMenu />

      <div className="view-wrapper is-full">
        <div className="stories-wrapper is-home">
          {/*Side menu*/}
          <SideMenu
            toggle={toggleMenu}
            onchange={(e) => {
              toggleChange(e);
            }}
          />
          {/*Page content*/}
          <div className="inner-wrapper">
            <a
              className="mobile-sidebar-trigger is-home-v2"
              onClick={menuClick}
            >
              <svg className="feather feather-menu">
                <use href={featherIcon + "#feather-menu"}></use>
              </svg>
            </a>
            <Content {...props} />
          </div>
        </div>
      </div>
    </div>
  );
};

const Content = (props) => {
  const [explore, setExplore] = useState([]);
  const [fetauredTagsData, setfetauredTagsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tags, setTags] = useState([]);
  const [isTagsAvail, setisTagsAvail] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [scroll, setScroll] = useState(0);
  const [section2, setSection2] = useState(0);
  const [section, setSection] = useState(false);
  const [fetauredTagName, setfetauredTagName] = useState();
  const [users, setUsers] = useState([]);
  const [gridData, setGridData] = useState([]);
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);
  const [hideMoreBtn, setHideMoreBtn] = useState(false);
  const [load, setLoad] = useState(true);
  const [isMoreLoading, setIsMoreLoading] = useState(false);

  useEffect(() => {
    getExploreData();
  }, []);

  /* useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [scroll]);

  function handleScroll() {
    const docSelect = document.getElementById("Section2");
    if (docSelect)
      setSection2(document.getElementById("Section2").offsetHeight);
    var initial = document.documentElement.scrollTop;
    var totalScrollSize = section2 - 200;
    if (!section) {
      if (initial > totalScrollSize) {
        getExplore();
        setScroll(initial);
        setSection(true);
      }
    }
  } */

  const getExploreData = async () => {
    setLoading(true);
    await Promise.all([getExplore(), getAllTags(), getFeaturedTags()]);
    setLoading(false);
  };

  const getExplore = async () => {

    let skip = explore.length;
    if(skip !== 0) setIsMoreLoading(true)
    await Api.GetLatest(skip, 12).then((result) => {
      if (result) {
        if (result.length < 12) {
          // setSection(true);
          setHideMoreBtn(true)
        }
        // setSection(false);
        let data = [...explore];
        result.map((item) => {
          data.push(item);
        });
        setExplore(data);
        if(skip !== 0) setIsMoreLoading(false)
        // const docSelect = document.getElementById("Section2");
        // if (docSelect)
        //   setSection2(document.getElementById("Section2").offsetHeight);
        // setSection2(document.getElementById("Section2"));
      }
    });
  };

  const getAllTags = async () => {
    try {
      const result = await Api.getAllTags();
      if (result) {
        setisTagsAvail(true);
        setTags(result);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getFeaturedTags = async () => {
    try {
      let result = await Api.getFeaturedTags();
      setfetauredTagName(result.featuredTag.tagText);
      setLoad(false);
      if (result.artworks) {
        setfetauredTagsData(result.artworks);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const resetFilter = () => {
    setExplore([]);
    setIsFilter(false);
    setisTagsAvail(false);
    getExploreData();
  };

  const handleSubmitClick = async (formValue) => {
    if (formValue.tags.length > 0) {
      setExplore([]);
      setIsFilter(true);
      let ids = [];
      if (formValue.tags) {
        formValue.tags.forEach((element) => {
          ids.push(element.id);
        });
      } else {
        formValue.featuredTags.forEach((element) => {
          ids.push(element.id);
        });
      }
      const object = {
        tagIds: ids,
        skip: 0,
        take: 12,
      };

      setLoading(true);
      await Api.getByTags(object).then((response) => {
        setExplore(response);
        setLoading(false);
      });
      setLoading(false);
    } else {
      setIsFilter(false);
      getExploreData();
    }
  };

  const TagSelect = ({ values, options, field, setFieldValue, ...props }) => {
    $(".css-g1d714-ValueContainer").animate(
      { scrollTop: $(document).height() },
      "slow"
    );

    const style = {
      control: (base, state) => ({
        ...base,
        border: state.isFocused ? 0 : 0,
        boxShadow: state.isFocused ? 0 : 0,
        "&:hover": {
          border: state.isFocused ? 0 : 0,
        },
      }),
      valueContainer: (base) => ({
        ...base,
        paddingLeft: 36,
      }),
    };

    return (
      <Select
        getOptionLabel={(option) => option.tagText + "(" + option.tagType + ")"}
        getOptionValue={(option) => option.id}
        options={options}
        styles={style}
        className="ml-5"
        onChange={(val) => {
          setFieldValue("tags", val);
        }}
        value={values.tags}
        isMulti
      />
    );
  };

  const getSearchResult = async () => {
    if (
      props.searchValue &&
      props.searchValue.length > 2 &&
      props.shouldSearch
    ) {
      setIsLoadingSearch(true);
      try {
        const result = await Api.getSearchResult({ query: props.searchValue });
        setUsers(result.users);
        setGridData(result.artworks);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoadingSearch(false);
      }
    } else {
      setUsers([]);
      setGridData([]);
      props.handleSearch(false);
    }
  };

  useEffect(() => {
    getSearchResult();
  }, [props.shouldSearch]);

  const initialValues = {
    tags: [],
  };

  useEffect(() => {
    return () => {
      props.searchChangeValue("");
      localStorage.removeItem("searchValue");
    };
  }, []);

  return (
    <>
      <ToastContainer />
      <a className="mobile-sidebar-trigger is-home-v2">
        <i data-feather="menu"></i>
      </a>
      <div className="stories-content">
        {/* <div className="section-title main-section-title">
          <h2>Stories</h2>
          <a className="view-all">View All</a>
        </div> */}
        {isLoadingSearch && <Loading />}
        {users.length > 0 && (
          <div className="stories-list m-0">
            <div className="container-inner">
              {users.map(({ avatar, username }, index) => (
                <div className="story modal-trigger" data-modal="new-story-modal" key={index}>
                  <div className="text-center">
                    <div className="story-avatar">
                      <a target="_blank" href={"/p/" + username}>
                        <img
                          className="avatar"
                          src={
                            avatar
                              ? `${avatar}`
                              : userDefaultImg
                          }
                          data-demo-src={avatar ? avatar : userDefaultImg}
                          data-user-popover="0"
                          alt=""
                        />
                      </a>
                      {/* <div className="add-overlay">
                      <i data-feather="plus"></i>
                    </div> */}
                    </div>
                    <a target="_blank" href={"/p/" + username}>
                      <span>{username}</span>
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {props.shouldSearch &&
          (gridData.length > 0 ? (
            <div className="collections-wrap is-active">
              <div className="collection">
                <div className="header">
                  <h4>Results for {props.searchValue}</h4>
                </div>
                <div className="w-100">
                  <div className="video-collection">
                    {gridData.map((item, index) => (
                      <div className="episode" key={index}>
                        <a
                          href={"/p/" + item.username + "/" + item.slug}
                          target="_blank"
                        >
                          <div className="episode-thumbnail">
                            <div className="episode-overlay"></div>
                            <div className="play-button">
                              <i data-feather="play-circle"></i>
                            </div>
                            <img
                              src={`${item.image}`}
                              data-demo-src="assets/img/demo/video/home/collections/c20.jpg"
                              alt=""
                            />
                          </div>
                        </a>
                        <div className="episode-meta">
                          <a href={"/p/" + item.username} target="_blank">
                            <img
                              src={
                                item.userImage
                                  ? `${item.userImage}`
                                  : userDefaultImg
                              }
                              data-demo-src="assets/img/icons/logos/metamovies.svg"
                              data-page-popover="2"
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = userDefaultImg;
                              }}
                            />
                          </a>
                          <div className="info">
                            <a
                              href={"/p/" + item.username + "/" + item.slug}
                              target="_blank"
                              className="art-title"
                            >
                              <span>{item.title}</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            !isLoadingSearch && (
              <div className="section-title">
                <div className="collections-wrap is-active">
                  <div className="collection">
                    <div className="header">
                      <h4>Results for {props.searchValue}</h4>
                    </div>
                    <div className="w-100">
                      <div className="video-collection">No records found</div>
                    </div>
                  </div>
                </div>
              </div>
            )
          ))}

        {!props.shouldSearch && (
          <>
            <div className="section-title filter-tags justify-content-end">
              <div className="filter-tags-wrapper">
                <div className="section-title mb-4">
                  <div>
                    {isFilter && (
                      <button
                        className="button dark-grey-button mr-3"
                        onClick={resetFilter}
                      >
                        Reset Filter
                      </button>
                    )}
                  </div>
                  {!props.shouldSearch && isTagsAvail ? (
                    <Formik
                      enableReinitialize={true}
                      initialValues={initialValues}
                      onSubmit={(values, { resetForm }) => {
                        handleSubmitClick(values, resetForm);
                      }}
                    >
                      {({
                        errors,
                        touched,
                        handleSubmit,
                        setFieldValue,
                        values,
                      }) => (
                        <Form>
                          <label>Filter by tags:</label>
                          <div
                            className={
                              "field field-group" +
                              (errors.tags && touched.tags ? " is-invalid" : "")
                            }
                          >
                            <div className="control has-icon">
                              <div className="search-icon">
                                <i className="fa fa-search"></i>
                              </div>
                              <Field
                                name="tags"
                                className="input is-fade"
                                options={tags}
                                setFieldValue={setFieldValue}
                                values={values}
                                component={TagSelect}
                              />
                            </div>
                            <div
                              className={
                                "errors-msg" +
                                (errors.tags && touched.tags
                                  ? " show-error"
                                  : "")
                              }
                            >
                              {/* {errors.tags} */}
                            </div>
                          </div>
                          <a href="#Section2">
                            <button
                              className="button is-solid accent-button form-button"
                              onClick={handleSubmit}
                            >
                              Go
                            </button>
                          </a>
                        </Form>
                      )}
                    </Formik>
                  ) : (
                    !props.shouldSearch && (
                      <div
                        className="placeload weather-widget-placeload"
                        style={{ width: "630px", height: "38px" }}
                      >
                        <div className="body">
                          <div className="inner-wrap">
                            <div className="rect loads"></div>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
            {/* featured tags Data section */}
            {((fetauredTagsData.length > 0 && fetauredTagsData != null) ||
              load) && (
              <div
                className={
                  isFilter ? "stories-feed display-none" : "stories-feed"
                }
              >
                <h3>{fetauredTagName}</h3>
                <div className="columns is-multiline">
                  {load ? (
                    <Loading />
                  ) : (
                    (fetauredTagsData || []).map((item, index) => (
                      <div key={index} className="column is-3 is-half-tablet">
                        <div className="story-feed-item">
                          <a
                            className="related-post"
                            href={"/p/" + item.username + "/" + item.slug}
                            target="_blank"
                          >
                            <img
                              className="featured-image"
                              src={
                                item.image
                                  ? `${item.image}`
                                  : userDefaultImg
                              }
                              alt=""
                            />
                          </a>
                          <div className="item-meta">
                            <div className="user-info">
                              <div className="small-avatar">
                                <a href={"/p/" + item.username} target="_blank">
                                  <img
                                    src={
                                      item.userImage
                                        ? `${item.userImage}`
                                        : userDefaultImg
                                    }
                                    data-demo-src="assets/img/demo/video/home/collections/c20.jpg"
                                    alt=""
                                  />
                                </a>
                              </div>
                              <a
                                href={"/p/" + item.username + "/" + item.slug}
                                target="_blank"
                                className="art-title"
                              >
                                <span>{item.title}</span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            )}

            {/* explore data section */}
            <div className="stories-feed">
              <h3>Explore</h3>
              <div id="Section2" className="columns is-multiline">
                {loading ? (
                  <Loading />
                ) : (
                  <Fragment>
                    {explore.length ? (
                      <Fragment>
                        {(explore || []).map((item, index) => (
                          <div
                            key={index}
                            className="column is-3 is-half-tablet"
                          >
                            <div className="story-feed-item">
                              <a
                                className="related-post"
                                href={"/p/" + item.username + "/" + item.slug}
                                target="_blank"
                              >
                                <img
                                  className="featured-image"
                                  src={
                                    item.image
                                      ? `${item.image}`
                                      : defaultImg
                                  }
                                  alt=""
                                />
                              </a>
                              <div className="item-meta">
                                <div className="user-info">
                                  <div className="small-avatar">
                                    <a
                                      href={"/p/" + item.username}
                                      target="_blank"
                                    >
                                      <img
                                        src={
                                          item.userImage
                                            ? `${item.userImage}`
                                            : userDefaultImg
                                        }
                                        data-demo-src="assets/img/demo/video/home/collections/c20.jpg"
                                        alt=""
                                      />
                                    </a>
                                  </div>
                                  <a
                                    href={
                                      "/p/" + item.username + "/" + item.slug
                                    }
                                    target="_blank"
                                    className="art-title"
                                  >
                                    <span>{item.title}</span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                        <div className="text-center w-100 mt-3">
                        {isMoreLoading ?  <Loading /> : (
                          <button
                            className="button dark-grey-button load-more mr-3"
                            onClick={getExplore}
                            disabled={hideMoreBtn}
                          >
                            Load More
                          </button>
                        )}
                        </div>
                      </Fragment>
                    ) : (
                      "No results found"
                    )}
                  </Fragment>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

const mapReduxStateToProps = (state) => ({
  searchValue: state.search.searchValue,
  shouldSearch: state.search.shouldSearch,
});

export default connect(mapReduxStateToProps, {
  logout,
  searchChangeValue,
  handleSearch,
})(withRouter(Explore));
