export const URL_LANDING_PAGE = "/";
export const URL_GET_INVOLVED_PAGE = "/get-involved";
export const URL_ABOUT_PAGE = "/about";
export const URL_NEWS_PAGE = "/news";
export const URL_NEWS_DETAILS_PAGE = "/news/:slug";
export const URL_DONATE_PAGE = "/donate";
export const URL_CONTACT_PAGE = "/contact";
export const URL_REGISTRATION_PAGE = "/collective";
export const URL_LOGIN_PAGE = "/login";
export const URL_FORGOT_PASSWORD_PAGE = "/forgot-password";
export const URL_RESET_PASSWORD_PAGE = "/reset-password";
export const URL_USER_PROFILE = "/p/:username";
export const URL_USER_EMAIL_CONFIRMATION = "/user-comfirm-email";
export const URL_USER_PROFILE_EDIT = "/user-profile";
export const URL_EVENT_DETAIL_PAGE = "/p/:username/:slug";
export const URL_ADD_LIBRARY_PAGE = "/AddLibrary";
export const URL_EDIT_ARTWORK_PAGE = "/artwork/edit/:artworkId";
export const URL_Feed_PAGE = "/feed";
export const URL_PRIVACY_PAGE = "/privacy";
export const URL_EXPLORE_PAGE = "/explore";
export const URL_RESOURCES_PAGE = "/resources";
export const URL_DASHBOARD = '/dashboard';
export const CONTACT_ART_FOUNDATION_URL =
  "https://artivistfoundation.org/contact";
export const ART_FOUNDATION_URL = "https://artivistfoundation.org/";
export const URL_EVENTS_PAGE = "/events";
export const URL_EVENT_DETAILS_PAGE = '/events/:slug';
export const URL_ADD_EVENT_PAGE = '/event/add';
export const URL_EDIT_EVENT_PAGE = '/event/edit/:slug';
