import React from "react";
import SwiperCore, { Navigation, Autoplay, Pagination } from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
SwiperCore.use([Navigation, Autoplay, Pagination]);
import Api from "../../Helpers/Api";
import { Fragment } from "react";
import "./style.scss";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import HeaderCarousel from "./HeaderCarousel";
import About from "./About";
import Flyers from "./Flyers";
import Teams from "./Teams";
import News from "./News";

const LandingPage = () => {
  return (
    <div>
      <Header />
      <Content />
      <Footer />
    </div>
  );
};

export const getImageData = async (imageIds) => {
  if (imageIds?.length > 0) {
    const imageData = await Api.getAllImageUrl({
      "filter[id][in]": imageIds.toString(),
    });
    return imageData
  }
}

export const modifyData = (data, imagedata, key, imageUrl) => {
  const ans = []
  data.forEach(element => {
    let imageUrls = imagedata && imagedata.filter(
      (details) => details.id === element[imageUrl]
    );
    if (imageUrls?.length > 0) ans.push({ ...element, [key]: imageUrls[0].data.full_url })
  });
  return ans
}

const Content = () => {

  return (
    <Fragment>
      <HeaderCarousel />
      <About />
      <Flyers />
      <Teams />
      <News />
    </Fragment>
  );
};
export default LandingPage;
