import { PATH_GET_EXPLORE } from "./path";
import * as handler from "./handler";
const GetLatest = (skip=0, take=10) => {
  return handler
    .get(PATH_GET_EXPLORE + `?skip=${skip}&take=${take}`)
    .then((res) => {
      return res.model;
    })
    .catch((error) => {
      console.log("error " + error);
    });
};
export default {
  GetLatest,
};
