import * as handler from "./handler";
import {PATH_NEWS} from "./path";

const getNewsFully = (offset = 0, limit = 6) => {
  return handler
      .get(
          PATH_NEWS,
          {
            status: "published",
            offset,
            limit,
            meta:"total_count"
          },
          true
      )
      .then((res) => {
        return res;
      });
};

const getNewsByUrl = (slug) => {
  return handler
      .get(
          PATH_NEWS,
          {
            status: "published",
            "filter[slug]": slug
          },
          true
      )
      .then((res) => {
        return res.data;
      });
};

const instagramPhotos = () => {
  var myHeaders = new Headers();

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };

  return fetch("https://www.instagram.com/explore/tags/collectivebyartivist/?__a=1", requestOptions)
      .then(response => response.json())
      .then(result => result)
      .catch(error => console.log('error', error));
};

export default {
  getNewsFully,
  getNewsByUrl,
  instagramPhotos
}
