import './style.scss';

import { useEffect, useState } from "react";
import Moment from "react-moment";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from "react-share";
import { TWITTER_URL } from "../../../Configs/config";
import Api from "../../../Helpers/Api";
import events from "../../../Helpers/Api/events";
import Footer from "../../Common/Footer";
import Header from "../../Common/Header";
import Loading from "../../Common/Loading";
import userDefaultImg from "../../../Assets/img/user-defoult-img.jpg";
import { ToastContainer, toast } from 'react-toastify';
import moment from "moment";

const EventDetailsPage = () => {
  return (
    <div>
      <Header />
      <EventDetails />
      <Footer />
    </div>
  )
}

const loadTwitterWidget = () => {
  const script = document.createElement("script");
  script.src = "https://platform.twitter.com/widgets.js";
  script.async = true;
  document.body.appendChild(script);
}

const EventDetails = () => {
  let { slug } = useParams();
  let UserId = localStorage.getItem("userId");

  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [event, setEvent] = useState([]);
  const [commentVal, setcommentVal] = useState("");
  const [commentBtnLoad, setCommentBtnLoad] = useState(false);
  const [artWorkComment, setartWorkComment] = useState([]);
  const [isJoined, setIsJoined]= useState(false);
  const [isCancelled, setIsCancelled] = useState(false);

  const getEventDetails = async (slug) => {

    setcommentVal('');
    setLoading(true);

    try {
      const result = await Api.getEventDetails(slug);

      if(result) {
        setEvent(result);
        if (UserId) {
            getArtWorksComments(result.id);
        }
        setLoading(false);
      }

    } catch (error) {
      console.error("getEventDetails Error:", error);
      setLoading(false);
    }
  }

  const getArtWorksComments = async (_artID) => {
    // method to get artworks of the client
    try {
      const result = await Api.GetArtworkComments(_artID);
      if (result) {
        setartWorkComment(result.reverse());
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  const addNewComment = async () => {
    let commentData = {
      // "id": null,
      commenterId: UserId,
      entityId: event.id,
      discriminator: "ArtEventComment",
      commentedDate: new Date().toISOString(),
      body: commentVal,
    };
    setCommentBtnLoad(true);
    try {
      const result = await Api.addArtworkComment(commentData);
      if (result) {
        setCommentBtnLoad(false);
        setcommentVal("");
        getArtWorksComments(event.id);
      } else {
        setCommentBtnLoad(false);
      }
    } catch (error) {
      console.error(error)
      setCommentBtnLoad(false);
    }
  };

  const getEventData = async (slug) => {
    Promise.all([
      getEventDetails(slug),
      loadTwitterWidget(),
    ])
  }

  const handleJoinButtonClick = async () => {
      if (UserId) {
          const result = await Api.addParticipation(event.id)   

        if (result?.message === "Success" && result.statusCode === 200) {  
            setTimeout(() => { toastMessage('success', "Added to Event") }, 100)
            setIsJoined(true)
        } 
        else {
            toastMessage(
            "Error",
            result.error
            );
        }
      } else {
          history.push({
              pathname: '/login',
              state: location.pathname
          })
      }
  }


  const handleLeaveButtonClick = async () => {
      const result = await Api.removeParticipation(event.id)   

      if (result?.message === "Success" && result.statusCode === 200) {  
        setTimeout(() => { toastMessage('success', "Left Event") }, 100)
        setIsJoined(false)
      } 
      else {
        toastMessage(
          "Error",
          result.error
        );
      }
  }

  const handleDeleteButtonClick = async () => {
      if (event.createdByUserId === UserId) {
        const result = await Api.deleteEvent(event.id);
        
        if (result?.message === "Success" && result.statusCode === 200) {  
          history.push('/events');
        } else {
          toastMessage(
            "Error",
            result.error
          );
        }
      }
  }

  const checkIfEventJoinedOrNot = () => {
    if (event && event.artEventDetails?.attendees?.length) {
        event.artEventDetails?.attendees.map(user => {
            if (user.userId === UserId) {
                setIsJoined(true)
            }
        })
    }
  }

  
  const toastMessage = (type, message) => {
      toast[type](message)
  };

  const convertUTCToLocal = (date, format) => {
    return moment.utc(date).local().format(format)       
  }

  useEffect(() => {
    !isCancelled && getEventData(slug);

    return () => {
        setIsCancelled(true);
    }
  }, [])

  useEffect(() => {
      !isCancelled && checkIfEventJoinedOrNot();

      return () => {
          setIsCancelled(true);
      }
  }, [event])


  return (
    <>
    <ToastContainer />
      <div>
        <div className="page-title-area bg-15">
          <div className="container">
            <div className="page-title-content">
              <h2>{event.name}</h2>
            </div>
          </div>
        </div>

        <section className="blog-details-area ptb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-12">
                {
                loading
                  ? <Loading />
                  : <div className="blog-details-desc">
                      <div className="article-content">
                        <div className="entry-meta mb-2">
                        <ul>
                          <li>
                            <span>Posted On: </span>
                            &nbsp;<Moment 
                              date={event.createdDate}
                              format="MMM DD, YYYY" 
                              />
                          </li>
                        </ul>
                        </div>

                                                      
                        {event.coverImageUrl ? (
                          <div className="article-image">
                            <img 
                              src={event.coverImageUrl}
                              alt="image" 
                              width="100%"
                              height="100%"
                              />
                          </div>
                        ) : ''}


                        {
                          event.isVirtual || event.isPrivate ? 
                          
                          <div className="w-100 d-inline-block"> 
                            { event.isVirtual ? <span className="tag hightlight square float-right">VIRTUAL</span> : "" } 

                            { event.isPrivate ? <span className="tag hightlight square float-right bg-danger mr-2">PRIVATE</span> : "" }
                          </div> : ""
                        }

                        {event.description ? (
                          <div dangerouslySetInnerHTML={{__html: event.description}}></div>
                        ) : ''}

                        <div className="w-100 mt-3 d-inline-block">
                          <div className="d-inline-flex">
                            <span className="description-title hightlight">When: &nbsp;&nbsp;</span> 
                            <small className="calendar-date ">
                              <i className="fa fa-calendar"></i>
                              <span>{convertUTCToLocal(event.startDate, 'MMM DD, YYYY')}</span>
                              {event.endDate ? (<span> - <time>{convertUTCToLocal(event.endDate, "MMM DD, YYYY")}</time></span>) : ''}
                            </small>
                            {" "}
                            <small className="ml-3">
                                <time>{convertUTCToLocal(event.startDate, "hh:mm A")}</time>
                                {event.endDate ? (<span> - <time>{convertUTCToLocal(event.endDate, "hh:mm A")}</time></span>) : ''}
                                </small>
                          </div>
                        </div>

                        <div className="w-100 mt-1 d-inline-block">
                          <div className="d-inline-flex">
                            <span className="description-title hightlight">Where: &nbsp;&nbsp;</span> 
                            { event.artEventDetails ?
                            
                              <small>{ event.artEventDetails.city },  {event.artEventDetails.stateOrProvince}, { event.artEventDetails.country }</small> : 
                              "" 
                            }
                          </div>
                        </div>

                        <div className="post-tags mt-2 d-inline-flex">
                          <span className="description-title hightlight">Causes: &nbsp;&nbsp;</span>
                          <div className="tags">
                            { event.artEventDetails && event.artEventDetails.tags &&
                              event.artEventDetails.tags.map((item, index) => (
                                <span className="tag" key={index}>
                                  {item.tagText}
                                </span>
                              ))}
                          </div>
                        </div>

                        { 
                          event.totalAttendees >  0 ? 
                          <div className="post-tags mt-2 d-inline-flex w-100 mb-2">
                            <span className="description-title hightlight">Attending: &nbsp;&nbsp;</span>
                            <div className="tags">
                              { event.artEventDetails && event.artEventDetails.attendees &&
                                event.artEventDetails.attendees.map((item, index) => (
                                    <div className="meta pl-2" key={index}>
                                      <div className="image avatar">
                                        {UserId ? (
                                          <Link to={`/p/${item.username}`}>
                                            <img
                                            src={
                                              item.image
                                              ? `${item.image}`
                                              : userDefaultImg
                                            }
                                            data-demo-src="assets/img/demo/video/home/collections/c20.jpg"
                                            alt=""
                                            />
                                          </Link>) : (
                                            <img
                                              src={
                                                item.image
                                                ? `${item.image}`
                                                : userDefaultImg
                                              }
                                              data-demo-src="assets/img/demo/video/home/collections/c20.jpg"
                                              alt=""
                                        />)}
                                      </div>
                                  </div>
                                ))}
                            </div>
                          </div>
                          : 
                          ""
                        }

                        <div className="mt-4 "> 
                            {isJoined ? (
                                <button 
                                    className="default-btn" 
                                    type="button" 
                                    onClick={handleLeaveButtonClick}
                                    >
                                    Leave Event
                                </button>) : (
                                <button 
                                    className="default-btn mr-3" 
                                    type="button" 
                                    onClick={handleJoinButtonClick}
                                    >
                                    Join Event  
                                </button>
                                )
                            }
                        </div>  
                        
                        {UserId && (<div className="story-post-wrapper w-100">
                          <div className="post-wrapper">
                            <div className="story-post w-100">
                              <div className="post-compose">
                                <div className="control">
                                  <textarea
                                    className="textarea"
                                    placeholder="Post a comment..."
                                    value={commentVal}
                                    onChange={(e) => setcommentVal(e.target.value)}
                                  ></textarea>
                                </div>
                                {commentVal && (
                                  <div className="compose-controls">
                                    {commentBtnLoad ? (
                                      <a className="button is-solid accent-button raised">
                                        <Loading />
                                      </a>
                                    ) : (
                                      <a
                                        className="button is-solid accent-button raised"
                                        onClick={() => addNewComment()}
                                      >
                                        Post Comment
                                      </a>
                                    )}
                                  </div>
                                )}
                              </div>
                              
                            {artWorkComment.length != 0 && (
                              <div className="comments-wrap">
                                <div className="comments-count">
                                  <h3>Comments ({artWorkComment.length})</h3>
                                </div>

                                {artWorkComment.map((item, index) => (
                                  <div className="media is-comment" key={index}>
                                    <div className="media-left">
                                      <Link to={'/p/' + item.username} className="avatar-wrap is-smaller">
                                        <img
                                          src={
                                            item.userAvatar
                                              ? `${item.userAvatar}`
                                              : userDefaultImg
                                          }
                                          data-user-popover="1"
                                          alt=""
                                        />
                                      </Link>
                                    </div>
                                    <div className="media-content">
                                      <div className="comment-meta">
                                        <h4>
                                          <Link to={'/p/' + item.username}>{item.username}</Link>{" "}
                                          <small>
                                            {" "}
                                            · {moment.utc(item.commentedDate).local().format("MMMM DD, YYYY hh:mm A")}
                                          </small>
                                        </h4>
                                        <p>{item.body}</p>
                                      </div>
                                      <div className="comment-stats-wrap">
                                        <div className="comment-stats"></div>
                                        <div className="comment-actions"></div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                            </div>
                          </div>
                        </div>)}

                        <div className="d-flex">
                        {event.createdByUserId == UserId && (
                          <div className="default-btn event-btn mr-3">
                            <Link  to={`/event/edit/${event.slug}`} >Edit event</Link>
                          </div>
                        )}
                        {event.createdByUserId == UserId && (
                            <button 
                              onClick={handleDeleteButtonClick} 
                              className="default-btn event-btn">
                                Delete event
                            </button>
                        )}
                        </div>
                      </div>

                      <div className="article-footer">
                        <div className="article-tags  text-lg-right">
                          <span>
                            <i className='fa fa-share-alt'></i>
                          </span>
                          <a>Share</a>
                        </div>

                        <div className="article-share">
                          <ul className="social">
                            <li>
                              <FacebookShareButton url="#">
                                <i className="flaticon-facebook"></i>
                              </FacebookShareButton>
                            </li>
                            <li>
                              <TwitterShareButton url="#">
                                <i className="flaticon-twitter"></i>
                              </TwitterShareButton>
                            </li>
                            <li>
                              <LinkedinShareButton url="#">
                                <i className="flaticon-linkedin"></i>
                              </LinkedinShareButton>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>                                          
                }
              </div>

              <div className="col-lg-4 col-md-12">
                <aside className="widget-area mb-5" id="secondary">
                  <section className="widget widget-peru-posts-thumb">
                    <h3 className="widget-title">Instagram</h3>
                    <div className="elfsight-app-11489573-eca2-4a72-a084-7f4aa1319016"></div>
                  </section>
                </aside>

                <aside className="widget-area mb-5" id="secondary">
                  <section className="widget widget-peru-posts-thumb">
                    <h3 className="widget-title">Twitter</h3>
                    <div className="tweeter-widget">
                      <a className="twitter-timeline"
                        href={TWITTER_URL}
                        data-tweet-limit="3">
                      </a>
                    </div>
                  </section>
                </aside>

                <aside className="widget-area" id="secondary">
                  <section className="widget widget-peru-posts-thumb">
                    <h3 className="widget-title">Facebook</h3>
                    <iframe
                      src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fcollectivebyartivist&tabs=timeline&width=340&height=500&small_header=true&adapt_container_width=true&hide_cover=true&show_facepile=true&appId"
                      width="340" height="500" scrolling="no" frameBorder="0"
                      allowFullScreen={true}
                      allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                  </section>
                </aside>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default EventDetailsPage;