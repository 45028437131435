import React, { useEffect, useState } from "react";
import "./style.scss";
import Api from "../../../../Helpers/Api";
import "react-toastify/dist/ReactToastify.css";
import { Link, useParams } from "react-router-dom";
import HeaderMenu from "../../../Common/HeaderMenu";
import script from "../../.../../../../Assets/img/icons/feather.svg";
import SideMenu from "../SideMenu";
import Loading from "../../../Common/Loading";
import Moment from "react-moment";
const FeedPage = (props) => {
  const [toggleMenu, setToggleMenu] = useState(false);

  const menuClick = () => {
    setToggleMenu(true);
  };

  const toggleChange = (data) => {
    setToggleMenu(data);
  };
  return (
    <div>
      <HeaderMenu />

      <div className="view-wrapper is-full">
        <div className="stories-wrapper is-home">
          {/*Side menu*/}
          <SideMenu
            toggle={toggleMenu}
            onchange={(e) => {
              toggleChange(e);
            }}
          />
          {/*Page content*/}
          <div className="inner-wrapper">
            <a
              className="mobile-sidebar-trigger is-home-v2"
              onClick={menuClick}
            >
              <svg className="feather feather-menu">
                <use href={script + "#feather-menu"}></use>
              </svg>
            </a>
            <Content {...props} />
          </div>
        </div>
      </div>
    </div>
  );
};
const Content = (props) => {
  let UserId = localStorage.getItem("userId"); //get userid
  const [feeds, setFeeds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);

  const getFeedPage = async () => {
    // method to get Feeds of the client
    setLoading(true);
    try {
      const result = await Api.getFeed(UserId);
      if (result) {
        setFeeds(result);
      }
    } catch (error) {
      console.error(error)
    }
    finally{
      setLoading(false);
    }
  };
  const toggleInspirations = async (item) => {
    var result;
    try {
      if (item.isInspired) {
        result = await Api.deleteInspiration(item.id, UserId);
      } else {
        result = await Api.addInspiration(item.id, UserId);
      }
      if (result.message === "Success") {
        const elementsIndex = feeds.findIndex(
          (element) => element.id == item.id
        );
        let newArray = [...feeds];
        newArray[elementsIndex] = {
          ...newArray[elementsIndex],
          isInspired: !item.isInspired,
        };
        setFeeds(newArray);
      }
    } catch (error) {
      console.error("toggleInspirations -> error", error);
    }
  };

  const Carousel = () => {
    if(feeds.length == 0)
      return <p className="p-5">You are not following any Artivists yet</p>
    else if (feeds.length > 0) {
      loadScript();
      return (
        <div className="feed-slider-wrapper">
          <div className="feed-slider-inner">           
            {(feeds || []).map((item, index) => {
              return (
                <div key={index} className="card feed-slider-item">
                  <div className="card-header">
                    <div className="media">
                      <div className="media-left">
                        <div className="post-avatar">
                          <Link to={"/p/" + item.username}>
                            <img
                              className="avatar"
                              src={`${item.userImage}`}
                              data-demo-src={`${item.userImage}`}
                              data-user-popover={index}
                              alt=""
                            />
                          </Link>
                        </div>
                      </div>
                      <div className="media-content">
                        <a href={"/p/" + item.username + "/" + item.slug} target="_blank">
                          <span>{item.title}</span>
                        </a>
                        <span>
                          <Moment fromNow>{item.completedDate}</Moment>
                        </span>
                      </div>
                      <div className="media-right">
                        <button
                          onClick={() => toggleInspirations(item)}
                          className="button is-liked"
                          tabIndex="0"
                        >
                          <i
                            className={
                              item.isInspired
                                ? "fa fa-star cstmStar"
                                : "fa fa-star-o cstmStar"
                            }
                          ></i>
                        </button>
                        <a
                          href={"/p/" + item.username + "/" + item.slug}
                          className="button"
                          tabIndex="0"
                          target="_blank"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-message-circle"
                          >
                            <path d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"></path>
                          </svg>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="card-image">
                    <figure className="image is-4by3">
                      <a
                        href={"/p/" + item.username + "/" + item.slug}                        
                        target="_blank"
                      >
                        <img
                          src={`${item.image}`}
                          data-demo-src={`${item.image}`}
                          alt=""
                        />
                      </a>
                    </figure>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
    }
  };

  const loadScript = () => {
    $(function () {
      if ($(".feed-slider-wrapper").length) {
        $(".feed-slider-inner")
          .not(".slick-initialized")
          .slick({
            centerMode: true,
            centerPadding: "10px",
            slidesToShow: 3,
            prevArrow:
              "<div class='slick-custom is-prev'><i class='mdi mdi-chevron-left'></i></div>",
            nextArrow:
              "<div class='slick-custom is-next'><i class='mdi mdi-chevron-right'></i></div>",
            responsive: [
              {
                breakpoint: 768,
                settings: {
                  arrows: false,
                  centerMode: true,
                  centerPadding: "40px",
                  slidesToShow: 3,
                },
              },
              {
                breakpoint: 480,
                settings: {
                  arrows: false,
                  centerMode: true,
                  centerPadding: "40px",
                  slidesToShow: 1,
                },
              },
            ],
          });
      }
    });
  };

  useEffect(() => {
    !isCancelled && getFeedPage();

    return () => {
        setIsCancelled(true);
    }
  }, []);

  return <div>{loading ? <Loading /> : Carousel()}</div>;
};

export default FeedPage;
