import React, { useState, useEffect, Fragment } from "react";
import Header from "../../Common/Header";
import Footer from "../../Common/Footer";
import Api from "./../../../Helpers/Api";
import {
  FACEBOOK_URL,
  INSTAGRAM_URL,
  TWITTER_URL,
  YOUTUBE_URL,
} from "../../../Configs/config";
const Contact = () => {
  return (
    <div>
      <Header />
      <Content />
      <Footer />
    </div>
  );
};
const Content = () => {
  const [contact, setContact] = useState({
    email: "",
    phone: "",
    location: "",
  });
  const [isCancelled, setIsCancelled] = useState(false);

  const getContact = async () => {
    try {
      const result = await Api.getContact();
      if (result.length > 0) setContact(result[0]);
    } catch (error) {
      console.error("getContact -> error", error);
    }
  };
  const getContactData = async () => {
    await Api.getAccessToken();
    Promise.all([getContact()]);
  };
  useEffect(() => {
    !isCancelled && getContactData();

    return () => {
        setIsCancelled(true);
    }
  }, []);
  const { location, phone, email } = contact;
  return (
    <>
      <section className="contact-area ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="contact-wrap">
                <div className="contact-form">
                  <div className="section-title">
                    <h2>Contact Us</h2>
                  </div>
                  <form
                    action="https://formspree.io/f/xgepdgqb"
                    method="POST"
                    id="contactForm"
                  >
                    <div className="row">
                      <div className="col-lg-6 col-sm-6">
                        <div className="form-group">
                          <input
                            type="text"
                            name="name"
                            id="name"
                            className="form-control"
                            required
                            data-error="Please enter your name"
                            placeholder="Your Name"
                          />
                          <div className="help-block with-errors"></div>
                        </div>
                      </div>

                      <div className="col-lg-6 col-sm-6">
                        <div className="form-group">
                          <input
                            type="email"
                            name="email"
                            id="email"
                            className="form-control"
                            required
                            data-error="Please enter your email"
                            placeholder="Your Email"
                          />
                          <div className="help-block with-errors"></div>
                        </div>
                      </div>

                      <div className="col-lg-6 col-sm-6">
                        <div className="form-group">
                          <input
                            type="text"
                            name="phone_number"
                            id="phone_number"
                            required
                            data-error="Please enter your number"
                            className="form-control"
                            placeholder="Your Phone"
                          />
                          <div className="help-block with-errors"></div>
                        </div>
                      </div>

                      <div className="col-lg-6 col-sm-6">
                        <div className="form-group">
                          <input
                            type="text"
                            name="msg_subject"
                            id="msg_subject"
                            className="form-control"
                            required
                            data-error="Please enter your subject"
                            placeholder="Your Subject"
                          />
                          <div className="help-block with-errors"></div>
                        </div>
                      </div>

                      <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                          <textarea
                            name="message"
                            className="form-control hight-unset"
                            id="message"
                            cols="30"
                            rows="5"
                            required
                            data-error="Write your message"
                            placeholder="Your Message"
                          ></textarea>
                          <div className="help-block with-errors"></div>
                        </div>
                      </div>

                      <div className="col-lg-12 col-md-12">
                        <button type="submit" className="default-btn page-btn">
                          Send Message
                        </button>
                        <div
                          id="msgSubmit"
                          className="h3 text-center hidden"
                        ></div>
                        <div className="clearfix"></div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="quick-contact">
                <h3>Quick Contact </h3>
                <ul className="contact-quick-links">
                  <li>
                    <i className="fa fa-envelope"></i>
                    Email Us:
                    <a href={`mailto:` + email}>{email}</a>
                  </li>
                  <li>
                    <i className="fa fa-mobile"></i>
                    Call Us:
                    <a href={`tel:` + phone}>{phone}</a>
                  </li>
                </ul>
                <div>
                  <ul className="contact-icons">
                    <li>
                      <a href={TWITTER_URL} target="_blank">
                        <i className="flaticon-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href={FACEBOOK_URL} target="_blank">
                        <i className="flaticon-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a href={YOUTUBE_URL} target="_blank">
                        <i className="fa fa-youtube-play"></i>
                      </a>
                    </li>
                    <li>
                      <a href={INSTAGRAM_URL} target="_blank">
                        <i className="flaticon-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <div className="single-contact-map pb-100">
        <div className="container">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d230234.44107282642!2d-74.13873179821576!3d40.69485647043758!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2sbd!4v1592319237992!5m2!1sen!2sbd"
            style={{ border: "0" }}
          ></iframe>
        </div>
      </div> */}
    </>
  );
};

export default Contact;
