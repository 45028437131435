import React, { useState, useEffect } from "react";
import SwiperCore, { Navigation, Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
SwiperCore.use([Navigation, Autoplay, Pagination]);
import Modal from "react-bootstrap/Modal";
import Api from "../../../Helpers/Api";
import Footer from "../../Common/Footer";
import Header from "../../Common/Header";
import "./style.css";
import imageNotAvailable from "../../../Assets/img/Image_Not_Available.png";
const About = () => {
  return (
    <div>
      <Header />
      <Content />
      <Footer />
    </div>
  );
};

const Content = () => {
  const [teams, setTeams] = useState([]);
  const [about, setAbout] = useState([]);
  const [show, setShow] = useState(null);
  const [isCancelled, setIsCancelled] = useState(false);
  
  const handleClose = () => {
    setShow(null);
  };
  const handleShow = (e, index) => {
    setShow(index);
  };

  //get Teams
  const getTeams = async () => {
    try {
      const result = await Api.getTeams();
      const imageIds = result.map((member) => member.image);
      const imagesData = await Api.getAllImageUrl({
        "filter[id][in]": imageIds.toString(),
      });

      result.map((data) => {
        let imageUrls = imagesData.filter(
          (details) => details.id === data.image
        );
        if (imageUrls.length > 0) data.imageUrl = imageUrls[0].data.full_url;
      });
      setTeams(result);
    } catch (err) {
      console.error("getTeams -> err", err);
    }
  };

  //get About
  const getAbout = async () => {
    try {
      const result = await Api.getAbout();
      //get vision image
      const vision_imageId = result.map((member) => member.vision_image);
      const vision_imageData = await Api.getAllImageUrl({
        "filter[id][in]": vision_imageId.toString(),
      });

      result.map((data) => {
        let imageUrls = vision_imageData.filter(
          (details) => details.id === data.vision_image
        );

        if (imageUrls.length > 0)
          data.vision_image = vision_imageData[0].data.full_url;
      });

      //Get Mission image data
      const mission_imageId = result.map((member) => member.mission_image);
      const mission_imageData = await Api.getAllImageUrl({
        "filter[id][in]": mission_imageId.toString(),
      });

      result.map((data) => {
        let imageUrls = mission_imageData.filter(
          (details) => details.id === data.mission_image
        );

        if (imageUrls.length > 0)
          data.mission_image = mission_imageData[0].data.full_url;
      });

      //get Who we Are image data
      const who_we_are_imageId = result.map(
        (member) => member.who_we_are_image
      );

      const who_we_are_ImageData = await Api.getAllImageUrl({
        "filter[id][in]": who_we_are_imageId.toString(),
      });

      result.map((data) => {
        let imageUrls = who_we_are_ImageData.filter(
          (details) => details.id === data.who_we_are_image
        );

        if (imageUrls.length > 0)
          data.who_we_are_image = who_we_are_ImageData[0].data.full_url;
      });

      //Get Origin image data
      const origin_imageId = result.map((member) => member.origin_image);
      const origin_imageData = await Api.getAllImageUrl({
        "filter[id][in]": origin_imageId.toString(),
      });

      result.map((data) => {
        let imageUrls = origin_imageData.filter(
          (details) => details.id === data.origin_image
        );

        if (imageUrls.length > 0)
          data.origin_image = origin_imageData[0].data.full_url;
      });

      //Get collective image data
      const collective_imageId = result.map(
        (member) => member.collective_image
      );
      const collective_imageData = await Api.getAllImageUrl({
        "filter[id][in]": collective_imageId.toString(),
      });

      result.map((data) => {
        let imageUrls = collective_imageData.filter(
          (details) => details.id === data.collective_image
        );

        if (imageUrls.length > 0)
          data.collective_image = collective_imageData[0].data.full_url;
      });

      setAbout(result[0]);
    } catch (err) {
      console.error("getAbout -> err", err);
    }
  };

  const getAboutData = async () => {
    await Api.getAccessToken();
    await getTeams()
    await getAbout()
  };

  useEffect(() => {
    !isCancelled && getAboutData();
    // counter();
    return () => {
      setIsCancelled(true)
    }
  }, []);

  const {
    who_we_are_text,
    who_we_are_title,
    who_we_are_image,
    mission_image,
    mission_title,
    mission_text,
    vision_image,
    vision_title,
    vision_text,
    origin_title,
    origin_text,
    origin_image,
    collective_image,
    collective_text,
    collective_title,
  } = about;

  return (
    <>
      <div className="page-title-area bg-15 about-banner-image">
      </div>

      <section id="about" className="about-section counter-section pt-80 pb-80">
        <div className="container">
          <div className="row flex-column-reverse flex-lg-row">
            <div className="col-lg-6 m-auto">
              <div className="about-area-content remove-before">
                <div className="counter-background">
                  <h3>{mission_title}</h3>
                </div>
                <p dangerouslySetInnerHTML={{ __html: mission_text }}></p>
              </div>
            </div>
            <div className="col-lg-6 m-auto">
              <div className="about-image mb-mt-20 mt-4">
                <img src={mission_image} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="video-section pt-80">
        <div className="container">
          <div className="video-image">
            <div className="video">
              <a
                target="_blank"
                href="https://www.youtube.com/watch?v=xTrQb2DDr20&ab_channel=THEARTIVISTFOUNDATION"
                className="video-btn popup-youtube"
              >
                <i className="flaticon-play"></i>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section id="about" className="about-section counter-section pt-80 pb-80">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 m-auto">
              <div className="about-image mb-mt-20 mt-4">
                <img src={who_we_are_image} alt="" />
              </div>
            </div>

            <div className="col-lg-6 m-auto">
              <div className="about-area-content remove-before">
                <div className="counter-background">
                  <h3>{who_we_are_title}</h3>
                </div>
                <p dangerouslySetInnerHTML={{ __html: who_we_are_text }}></p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="counter-section remove-custm-space pt-80 pb-80">
        <div className="container">
          <div className="row flex-column-reverse flex-lg-row">
            <div className="col-lg-6 m-auto">
              <div className="about-area-content">
                <div className="counter-content remove-before">
                  <h3>{vision_title}</h3>
                  <p dangerouslySetInnerHTML={{ __html: vision_text }}></p>
                </div>
              </div>
            </div>

            <div className="col-lg-6 m-auto">
              <div className="about-image mt-4 mb-mt-20">
                <img src={vision_image} />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Origin story section       */}
      <section
        id="origin"
        className="about-section counter-section pt-80 pb-80"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 m-auto">
              <div className="about-image mt-4 mb-mt-20">
                <img
                  src={origin_image != null ? origin_image : imageNotAvailable}
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-6 m-auto">
              <div className="about-area-content remove-before mt-4 mb-mt-20">
                <div className="counter-background">
                  <h3>{origin_title}</h3>
                </div>
                <p dangerouslySetInnerHTML={{ __html: origin_text }}></p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Collective Section */}
      <section
        id="collective"
        className="counter-section remove-custm-space pt-80 pb-80"
      >
        <div className="container">
          <div className="row flex-column-reverse flex-lg-row">
            <div className="col-lg-6 m-auto">
              <div className="about-area-content">
                <div className="counter-content remove-before">
                  <h3>{collective_title}</h3>
                  <p dangerouslySetInnerHTML={{ __html: collective_text }}></p>
                </div>
              </div>
            </div>

            <div className="col-lg-6 m-auto">
              <div className="about-image mt-4 mb-mt-20">
                <img
                  src={
                    collective_image != null
                      ? collective_image
                      : imageNotAvailable
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="team" className="team-section pt-80 pb-80">
        <div className="container">
          <div className="section-title">
            <h2>Our Team</h2>
            <p></p>
          </div>
          <Swiper
            className="team-slider owl-carousel owl-theme"
            spaceBetween={30}
            slidesPerView={3}
            breakpoints={{
              "0": {
                "slidesPerView": 1
              },
              "768": {
                "slidesPerView": 2
              },
              "1024": {
                "slidesPerView": 3
              }
            }}
            pagination={{ clickable: true }}
          >
            {teams.map((item, index) => (
              <SwiperSlide key={index}>
                <div className="team-item">
                  <div className="image">
                    <img
                      src={item.imageUrl}
                      alt=""
                      className="responsive"
                      onClick={(e) => handleShow(e, item.id)}
                      role="button"
                    />
                    <ul className="social">
                      {item.linkedin_url && (
                        <li>
                          <a
                            href={item.linkedin_url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="flaticon-linkedin"></i>
                          </a>
                        </li>
                      )}

                      {item.mailto_link && (
                        <li>
                          <a
                            href={`mailto:${item.mailto_link}`}
                            rel="noreferrer"
                          >
                            <i className="fa fa-envelope"></i>
                          </a>
                        </li>
                      )}
                    </ul>
                    <div className="content">
                      <h3>{item.name}</h3>
                      <span>{item.title}</span>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          {teams.map((item, index) => (
            <Modal
              className="team-modal"
              id={item.id}
              show={show === item.id}
              onHide={handleClose}
              size="lg"
              key={index}
            >
              <button type="button" className="close" onClick={handleClose}>
                <span aria-hidden="true">&times;</span>
              </button>
              <div className="bio-modal-content">
                <div className="bio-name-wrapper ">
                  <div className="default-img in-modal">
                    <img src={item.imageUrl} alt="img" className="img-fluid" />
                  </div>
                  <div className="section-title pos-rel ">
                    <div className="section-text pos-rel">
                      <h2>{item.name}</h2>
                      <h6 className="f-500  letter-spacing pink-color">
                        {item.title}
                      </h6>
                    </div>
                  </div>
                </div>
                <div className="bio-desc">
                  <p>{item.description}</p>
                </div>
                <div className="social">
                  {item.linkedin_url && (
                    <a href={item.linkedin_url} target="blank">
                      <i className="flaticon-linkedin"></i>
                    </a>
                  )}
                  {item.mailto_link && (
                    <a href={`mailto:${item.mailto_link}`}>
                      <i className="fa fa-envelope"></i>
                    </a>
                  )}
                </div>
              </div>
            </Modal>
          ))}
        </div>
      </section>
    </>
  );
};
export default About;
