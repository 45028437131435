import React, { useState, useEffect, useRef } from "react";
import "./style.scss";
import HeaderMenu from "../../../Common/HeaderMenu";
import SideMenu from "../SideMenu";
import script from "../../../../Assets/img/icons/feather.svg";
import { useParams, withRouter } from "react-router";
import Api from "../../../../Helpers/Api";
import jwtDecode from "jwt-decode";
import Moment from "react-moment";
import whiteIcon from "../../../../Assets/img/logo-main.png";
import thumbIcon from "../../../../Assets/img/logo.png";
import { connect } from "react-redux";
import { logout } from "../../../../Redux/actions/auth.action";
import Loading from "../../../Common/Loading";
import { Link, useHistory } from "react-router-dom";
import userDefaultImg from "../../../../Assets/img/user-defoult-img.jpg";
import defaultImg from "../../../../Assets/img/Image_Not_Available.png";
import Slider from "react-slick";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import moment from "moment";
import Dropdown from "../../../Common/HeaderDropdown";
import {URL_ADD_LIBRARY_PAGE} from "../../../../Helpers/urls";
import {toast, ToastContainer} from "react-toastify";
const toastMessage = (type, message) => {
  toast[type](message);
};

const EventDetail = () => {
  // var token = JwtDecode();
  // let UserId = token.sub; //get useri
  const sliderRef = useRef()
  let loggedUserId = localStorage.getItem("userId");
  let history = useHistory();
  let { slug, username } = useParams();
  const isLoggedUsername =
    username == localStorage.getItem("username") ? true : false;
  const [toggleMenu, setToggleMenu] = useState(false);
  const [artWorks, setArtWorks] = useState({});
  const [artWorkUserDetails, setArtWorkUserDetails] = useState([]);
  const [artWorkComment, setartWorkComment] = useState([]);
  const [latestArtWorks, setLatestArtWorks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [commentBtnLoad, setCommentBtnLoad] = useState(false);
  const [commentVal, setcommentVal] = useState("");
  const [zoomImg, setZoomImg] = useState("");
  const [showZoomImg, setShowZoomImg] = useState(false);
  const [report, setReport] = useState(false);
  let sliderS;

  const menuClick = () => {
    setToggleMenu(true);
  };

  const onchange = (data) => {
    setToggleMenu(data);
  };

  const getArtWorks = async () => {
    // method to get artworks of the client
    setLoading(true);
    try {
      const result = await Api.GetArtworkBySlug(username, slug);
      if (result) {
        const imageData = await Api.getArtworkImages({ ArtworkId: result.id });
        result.images = imageData;
        setLoading(false);
        setArtWorks(result);
        getArtWorkUserDetails(result.userId);
        getArtWorksComments(result.id);
      }
    } catch (error) {
      console.error(error)
      setLoading(false);
    }
  };

  const getArtWorksComments = async (_artID) => {
    // method to get artworks of the client
    try {
      const result = await Api.GetArtworkComments(_artID);
      if (result) {
        setartWorkComment(result.reverse());
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  const GetLatest = async () => {
    try {
      setLoad(true);
      const result = await Api.GetLatest();
      if (result) {
        setLatestArtWorks(result);
        setLoad(false);
      }
    } catch (error) {
      console.error("error", error);
      setLoad(false);
    }
  };

  const getArtWorkUserDetails = async (_id) => {
    try {
      const result = await Api.getProfile(_id);
      if (result) setArtWorkUserDetails(result);
    } catch (error) {
      console.error("error", error);
    }
  };

  const getArtWorkData = async () => {
    //await Api.getAccessToken();
    setcommentVal('');
    Promise.all([getArtWorks(), GetLatest()]);
  };

  const toggleInspirations = async () => {
    var result;
    try {
      if (artWorks.isInspired) {
        result = await Api.deleteInspiration(artWorks.id);
      } else {
        result = await Api.addInspiration(artWorks.id);
      }
      if (result.message == "Success") {
        setArtWorks({
          ...artWorks,
          isInspired: !artWorks.isInspired,
        });
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  const artWorkDeleteHandler = async (_id) => {
    try {
      const result = await Api.deleteArtwork(_id);
      history.push("/p/" + username);
    } catch (error) {
      console.error("error", error);
    }
  };

  const addNewComment = async () => {
    let commentData = {
      // "id": null,
      commenterId: loggedUserId,
      entityId: artWorks.id,
      discriminator: "ArtworkComment",
      commentedDate: new Date().toISOString(),
      body: commentVal,
    };
    setCommentBtnLoad(true);
    try {
      const result = await Api.addArtworkComment(commentData);
      if (result) {
        setCommentBtnLoad(false);
        setcommentVal("");
        getArtWorksComments(artWorks.id);
      } else {
        setCommentBtnLoad(false);
      }
    } catch (error) {
      console.error(error)
      setCommentBtnLoad(false);
    }
  };
  function validURL(str) {
    var a  = document.createElement('a');
    a.href = str;
    return (a.host && a.host !== window.location.host);
  }

  useEffect(() => {
    getArtWorkData();
  }, [location.pathname]);

  const settings = {
    dots: false,
    fade: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const zoomImage = (image) => {
    sliderS.slickGoTo(image);
    setTimeout(() => {
      setShowZoomImg(true);
    }, 100)
  };

  const reportArt = () => {
    Api.reportArtwork({url: window.location.href})
    .then(() => {
      setReport(true);
      toastMessage(
        "success",
      `Artwork successfully reported.`
      );
      setTimeout(() => {
        setReport(false);
      }, 2000)
    })
  };

  const handleEditArtwork = () => history.push("/artwork/edit/" + artWorks.id);

  return (
    <>
      <ToastContainer />
      {/*Header menu*/}
      <HeaderMenu />

      <div className="view-wrapper is-full artwork-details">
        <div className="stories-wrapper is-home">
          {/*Side menu*/}
          <SideMenu
            toggle={toggleMenu}
            onchange={(e) => {
              onchange(e);
            }}
          />
          {/*Page content*/}
          <div className="inner-wrapper">
            <a
              className="mobile-sidebar-trigger is-home-v2"
              onClick={menuClick}
            >
              <svg className="feather feather-menu">
                <use href={script + "#feather-menu"}></use>
              </svg>
            </a>
            <div className="story-post-wrapper">
              <div className="story-post">
                {loading ? (<Loading/>) :(
                  <>
                  <div className="post-title">
                    <h2 className="text-break">{artWorks.title}</h2>
                    {isLoggedUsername && (
                      <div className="dropdown artWorks-dropdown is-spaced is-right is-accent dropdown-trigger">
                        <Dropdown
                          viaButton="Actions ▾"
                          isAccountDropdown={false}
                          options={
                            <div className="inner">
                              <div className="media">
                                <div className="media-content"
                                  onClick={handleEditArtwork}
                                >
                                  <span>
                                    <svg className="feather feather-edit-3">
                                      <use href={script + "#feather-edit-3"}></use>
                                    </svg>
                                    <span>Edit</span>
                                  </span>
                                </div>
                                <div className="media-content"
                                 onClick={() => {
                                   if (
                                     window.confirm(
                                       "Are you sure to delete this art work?"
                                     )
                                   ) {
                                     artWorkDeleteHandler(artWorks.id);
                                   }
                                 }}
                                >
                                  <span>
                                    <svg className="feather feather-trash-2">
                                      <use href={script + "#feather-trash-2"}></use>
                                    </svg>
                                    <span>Delete</span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          }
                        />
                      </div>
                    )}
                  </div>
                  { (artWorks.images && artWorks.images.length) && (
                    <div className={
                        artWorks.images.length === 1
                          ? "post-image-wrap one-item"
                          : (artWorks.images.length === 2
                            ? "post-image-wrap two-items"
                            : "post-image-wrap many-items"
                          )
                      }>
                      {artWorks.images.map((art, index) => (
                        <div className="post-image-wrapper" key={index}>
                          <div className="post-image-item">
                            <div className="zoom-image" onClick={() => zoomImage(index)}>
                              <svg className="feather feather-img-zoom">
                                <use href={script + "#feather-img-zoom"}></use>
                              </svg>
                            </div>
                            <img
                                key={art.artworkId}
                                className="post-image"
                                src={`${art.image}`}
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = whiteIcon;
                                }}
                                alt=""
                            />
                          </div>
                        </div>
                      ))}

                      <div className={ showZoomImg ? 'zoom-image-modal open' : 'zoom-image-modal'}>
                        <div className="zoom-image">
                          <svg className="feather feather-close" onClick={() => {setShowZoomImg(false)}}>
                            <use href={script + "#feather-close"}></use>
                          </svg>
                          <Slider ref={c => (sliderS = c)} {...settings}>
                            {artWorks.images.map((art, index) => (
                              <img
                                key={index}
                                className={ showZoomImg ? 'open' : ''}
                                src={`${art.image}`} alt=""
                              />
                            ))}
                          </Slider>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="post-meta">
                    <div className="post-author">
                      <Link to={'/p/'+artWorks.username} className="story-avatar">
                        <img
                          className="avatar"
                          src={artWorks.userImage ? `${artWorks.userImage}` : thumbIcon}
                          data-demo-src={`${artWorks.userImage}`}
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = thumbIcon;
                          }}
                          data-user-popover="2"
                          alt=""
                        />
                      </Link>
                      <div className="meta">
                        <Link to={'/p/'+artWorks.username}>
                          <span>
                            {artWorkUserDetails.firstName}{" "}
                            {artWorkUserDetails.lastName}
                          </span>
                        </Link>
                        <span>
                          {moment.utc(artWorks.createdDate).local().format("MMMM DD, YYYY")}
                        </span>
                      </div>
                    </div>

                    <div className="post-stats">
                      <div className="stat-item">
                        <i
                          className={
                            artWorks.isInspired
                              ? "fa fa-star cstmStar"
                              : "fa fa-star-o cstmStar"
                          }
                          onClick={toggleInspirations}
                        ></i>
                      </div>
                      <div className="report-art-wrapper">
                        <button className={'report-artwork-btn' + (report ? ' hide' : '')} title="Report" onClick={reportArt}>
                          <i className="fa fa-flag"></i>
                        </button>
                        <div className={'report-done' + (report ? ' show' : '')}>
                          <i className="fa fa-check"></i>
                        </div>
                      </div>
                      {/*<button className="button facebookIcon" tabIndex="0">*/}
                      {/*  <i className="fa fa-facebook" aria-hidden="true"></i>*/}
                      {/*</button>*/}
                      {/*<button className="button twitterIcon" tabIndex="0">*/}
                      {/*  <i className="fa fa-twitter" aria-hidden="true"></i>*/}
                      {/*</button>*/}
                      {/*<button className="button instaIcon" tabIndex="0">*/}
                      {/*  <i className="fa fa-instagram" aria-hidden="true"></i>*/}
                      {/*</button>*/}
                    </div>
                  </div>
                  <div className="post-text content">
                    {artWorks.link && validURL(artWorks.link) && (
                      <div className="artWorks-link">
                        <a href={artWorks.link} target="_blank">
                          <i className="fa fa-link"></i>
                          {artWorks.link}
                        </a>
                      </div>
                    )}
                    <div
                      className="text-break"
                      dangerouslySetInnerHTML={{ __html: artWorks.description }}
                    ></div>
                  </div>
                  <div className="post-tags">
                    <div className="tags">
                      {artWorks.tags &&
                        artWorks.tags.map((item, index) => (
                          <span className="tag" key={index}>
                            {item.tagText}
                          </span>
                        ))}
                    </div>
                  </div>
                  <div className="post-compose">
                    <div className="control">
                      <textarea
                        className="textarea"
                        placeholder="Post a comment..."
                        value={commentVal}
                        onChange={(e) => setcommentVal(e.target.value)}
                      ></textarea>
                    </div>
                    {commentVal && (
                      <div className="compose-controls">
                        {commentBtnLoad ? (
                          <a className="button is-solid accent-button raised">
                            <Loading />
                          </a>
                        ) : (
                          <a
                            className="button is-solid accent-button raised"
                            onClick={() => addNewComment()}
                          >
                            Post Comment
                          </a>
                        )}
                      </div>
                    )}
                  </div>
                  {artWorkComment.length != 0 && (
                    <div className="comments-wrap">
                      <div className="comments-count">
                        <h3>Comments ({artWorkComment.length})</h3>
                      </div>

                      {artWorkComment.map((item, index) => (
                        <div className="media is-comment" key={index}>
                          <div className="media-left">
                            <Link to={'/p/' + item.username} className="avatar-wrap is-smaller">
                              <img
                                src={
                                  item.userAvatar
                                    ? `${item.userAvatar}`
                                    : userDefaultImg
                                }
                                data-user-popover="1"
                                alt=""
                              />
                            </Link>
                          </div>
                          <div className="media-content">
                            <div className="comment-meta">
                              <h4>
                                <Link to={'/p/' + item.username}>{item.username}</Link>{" "}
                                <small>
                                  {" "}
                                  · {moment.utc(item.commentedDate).local().format("MMMM DD, YYYY hh:mm A")}
                                </small>
                              </h4>
                              <p>{item.body}</p>
                            </div>
                            <div className="comment-stats-wrap">
                              <div className="comment-stats"></div>
                              <div className="comment-actions"></div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                  </>
                ) }
              </div>
            </div>
            <div className="story-post-sidebar">
              <div className="header">
                <h2>You might like</h2>
              </div>
              <div className="related-posts overflow-auto">
                {load ? (
                  <Loading />
                ) : (
                  latestArtWorks.map((item, index) => (
                    <Link
                      key={index}
                      className="related-post"
                      to={"/p/" + item.username + "/" + item.slug}
                    >
                      <img
                        src={
                          item.image
                            ? `${item.image}`
                            : defaultImg
                        }
                        data-demo-src="assets/img/demo/video/home/collections/c20.jpg"
                        alt=""
                      />
                      <div className="meta">
                        <h3>{item.title}</h3>
                        <div className="user-line">
                          <img
                            src={
                              item.userImage
                                ? `${item.userImage}`
                                : userDefaultImg
                            }
                            data-demo-src="assets/img/demo/video/home/collections/c20.jpg"
                            alt=""
                          />
                          <span>{item.username}</span>
                        </div>
                      </div>
                    </Link>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, {
  logout,
})(withRouter(EventDetail));
