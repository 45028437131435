import React, { useState, useEffect } from "react";
import "./style.scss";
import { errorMessage } from "../../../Helpers/utils";
import { Formik, Field, Form } from "formik";
import {
  initialValues,
  RegisterSchema,
} from "../../Pages/Registration/validation";
import Loading from "../../Common/Loading";
import { URL_LOGIN_PAGE, URL_USER_PROFILE } from "./../../../Helpers/urls";
import { Link, Redirect, withRouter } from "react-router-dom";
import Api from "../../../Helpers/Api";
import logo from '../../../Assets/img/logo-main.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import whiteIcon from '../../../Assets/img/white-logo.png'
import { connect } from "react-redux";
import {login} from "../../../Redux/actions/auth.action"
const Registration = (props) => {
  const [loading, setLoading] = useState(false);
  const toastMessage = (type, message) => {
    toast[type](message)
  };

  const handleSubmitClick = async(values, resetForm) => {
    values.emailConfirmationLink = window.location.origin + '/user-comfirm-email'
    setLoading(true);
    try { 
      const result = await Api.doUserRegister(values)
      setLoading(false);
      if(result.message == "Success") {
        resetForm()
        toastMessage('success', 'Thanks for you registration. We have sent an email with a confirmation link to your email address.')
      } else {
        toastMessage('error', result)
      }
    } catch (err) {
      setLoading(false);
      toastMessage('error', err)
    }
  };
  if (localStorage.getItem("accessToken") && props.username)
  return <Redirect to={`/p/${props.username}`} />;
  return (
    
    <div className="registeration-wrapper">
      
      <div className="registeration">
        <div className="registeration-left">
          <div className="registeration-left-content d-flex flex-column justify-content-between">
            <div className="registeration-content-heading pt-5">
              <div className="registeration-heading-content">
                <h2>Welcome to Collective!</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="registeration-right d-flex flex-column justify-content-center align-center registeration-form">
          <div className="form-inner">
            <div className="logo-wrap">
              <div className="wrap-inner">
                <img src={whiteIcon} alt="Logo" />
              </div>
            </div>
            <div className="registeration-right-content-heading form-act">
              <div className="form-wrapper">                
                <div className="team-item">
                  <h2 className="text-uppercase theme-color">Collective</h2>
                  <span className="theme-color">By the Artivist Foundation</span>
                </div>
                <Formik
                  initialValues={initialValues}
                  validationSchema={RegisterSchema}
                  onSubmit={(values, { resetForm }) => {
                    handleSubmitClick(values, resetForm);
                  }}
                >
                  {({ errors, touched, handleSubmit }) => (
                    <Form>
                      <div className="registeration-form">
                        <div className="field">
                          <div className="control">
                            <Field
                              type="text"
                              placeholder="First Name"
                              name="firstname"
                            ></Field>
                            {errors.firstname &&
                              touched.firstname &&
                              errorMessage(errors.firstname)}
                            <div className="input-icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                <circle cx="12" cy="7" r="4"></circle>
                              </svg>
                            </div>
                          </div>
                        </div>

                        <div className="field">
                          <div className="control">
                            <Field
                              type="text"
                              placeholder="Last Name"
                              name="lastname"
                            ></Field>
                            {errors.lastname &&
                              touched.lastname &&
                              errorMessage(errors.lastname)}
                            <div className="input-icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                <circle cx="12" cy="7" r="4"></circle>
                              </svg>
                            </div>
                          </div>
                        </div>
                        
                        <div className="field">
                          <div className="control">
                            <Field
                              type="Email"
                              placeholder="Email"
                              name="email"
                            ></Field>
                            {errors.email &&
                              touched.email &&
                              errorMessage(errors.email)}
                            <div className="input-icon">
                              <i
                                className="fa fa-envelope-o"
                                aria-hidden="true"
                              ></i>
                            </div>
                          </div>
                        </div>
                        
                        <div className="field">
                          <div className="control">
                            <Field
                              type="text"
                              placeholder="User Name"
                              name="username"
                            ></Field>
                            {errors.username &&
                              touched.username &&
                              errorMessage(errors.username)}
                            <div className="input-icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                <circle cx="12" cy="7" r="4"></circle>
                              </svg>
                            </div>
                          </div>
                        </div>

                        <div className="field">
                          <div className="control">
                            <Field
                              type="password"
                              placeholder="Password"
                              name="password"
                            ></Field>
                            {errors.password &&
                              touched.password &&
                              errorMessage(errors.password)}
                            <div className="input-icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <rect
                                  x="3"
                                  y="11"
                                  width="18"
                                  height="11"
                                  rx="2"
                                  ry="2"
                                ></rect>
                                <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                              </svg>
                            </div>
                          </div>
                        </div>

                        <div className="field">
                          <div className="control">
                            <Field
                              type="password"
                              placeholder="Confirm Password"
                              name="passwordConfirmation"
                            ></Field>
                            {errors.passwordConfirmation &&
                              touched.passwordConfirmation &&
                              errorMessage(errors.passwordConfirmation)}
                            <div className="input-icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <rect
                                  x="3"
                                  y="11"
                                  width="18"
                                  height="11"
                                  rx="2"
                                  ry="2"
                                ></rect>
                                <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                              </svg>
                            </div>
                          </div>
                        </div>

                        <div className="field">
                          <div className="control">
                          { loading ? <Loading /> :<button
                              onClick={handleSubmit}
                              className="button is-solid primary-button raised is-rounded is-fullwidth default-btn">
                              Registration
                          </button> }
                          </div>
                          <div className="control">
                            <Link
                              className="d-flex justify-content-center register-link"
                              to={URL_LOGIN_PAGE} >
                              If you already have an account, log in
                            </Link>
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
 
  );
};

const mapReduxStateToProps = (state) => ({
  username: state.auth.username,
});

export default connect(mapReduxStateToProps, {
  login,
})(withRouter(Registration));
