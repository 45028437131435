import React, { useState } from "react";
import HeaderMenu from "../../Common/HeaderMenu";
import SideMenu from "../UserProfile/SideMenu";
import script from "../../../Assets/img/icons/feather.svg";
import EditPageContent from "./EditPageContent";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {logout} from "./../../../Redux/actions/auth.action"

const UserProfileEdit = () => {

  const [toggleMenu, setToggleMenu] = useState(false);

  const menuClick = () => {
    setToggleMenu(true)
  };

  const toggleChange = (data) => {
    setToggleMenu(data)
  };

  return (
      <div>
        {/*Header menu*/}
        <HeaderMenu />

        <div className="view-wrapper is-full">
          <div className="stories-wrapper is-home">

            {/*Side menu*/}
            <SideMenu toggle={toggleMenu} onchange={(e) => { toggleChange(e) }} />

            {/*Page content*/}
            <div className="inner-wrapper">
              <a className="mobile-sidebar-trigger is-home-v2" onClick={menuClick}>
                <svg className="feather feather-menu">
                  <use href={script + '#feather-menu'}></use>
                </svg>
              </a>

              <EditPageContent />

            </div>

          </div>
        </div>

      </div>
  )
};

export default connect(null, {
  logout,
})(withRouter(UserProfileEdit));
