import { ACTION_TYPES } from "./../actions/auth.action";

const initialState = {
  isLoggedIn: localStorage.getItem("isLoggedIn") === "true" ? true : false,
  accessToken: localStorage.getItem("accessToken")
    ? localStorage.getItem("accessToken")
    : "",
  roles: localStorage.getItem("roles") ? localStorage.getItem("roles") : "",
  username: localStorage.getItem("username") ? localStorage.getItem("username") : "",
};


export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.LOGIN:
      return {
        isLoggedIn: true,
        accessToken: action.accessToken,
        roles: action.roles,
        username: action.username,
      };
    case ACTION_TYPES.LOGOUT:
      return {
        isLoggedIn: false,
        accessToken: "",
        roles: ""
      };
    default:
      return state;
  }
};
