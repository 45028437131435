// Sandbox
export const BASE_URL = "https://artivist-sandbox.azurewebsites.net/api/";

// Prod
//export const BASE_URL = "https://artivist-prod.azurewebsites.net/api/";

export const PAGES_BASE_URL = "https://oceanring.net/artivistcollective/";
export const AUTH_BASE_URL = BASE_URL;
export const CREDENTIALS = {
  EMAIL: "artivistapi@oceanrintech.com",
  PASSWORD: "arTiV1st%",
};
export const ORIGIN_URL=window.location.origin
export const FACEBOOK_URL = "https://www.facebook.com/collectivebyartivist";
export const INSTAGRAM_URL = "https://www.instagram.com/collectivebyartivist/";
export const YOUTUBE_URL = "https://www.youtube.com/channel/UCYqC-3GGLzMPhJKdqftxjYw/featured";
export const TWITTER_URL = "https://twitter.com/ArtivistTeam";

export const ADMIN_URL = "https://oceanring.net/admin/#/login?project=artivistcollective";