import React, { useState } from "react";
import HeaderMenu from "../../../Common/HeaderMenu";
import SideMenu from "../SideMenu";
import featherIcon from "../../.../../../../Assets/img/icons/feather.svg";
import "./style.scss";
import { Link, withRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { logout } from "../../../../Redux/actions/auth.action";
import { connect } from "react-redux";
import {
  searchChangeValue,
  handleSearch,
} from "../../../../Redux/actions/search.action";
import { Formik, Field, Form } from "formik";
import { useEffect } from "react";
import Api from "../../../../Helpers/Api";
import Loading from "../../../Common/Loading";
import moment from 'moment';
import fallbackImage from '../../../../Assets/img/events/image1.jpg';
import Select from 'react-select';
import * as $ from 'jquery';

const MainEvents = (props) => {
  const [toggleMenu, setToggleMenu] = useState(false);

  const menuClick = () => {
    setToggleMenu(true);
  };

  const toggleChange = (data) => {
    setToggleMenu(data);
  };
    
  return (
    <div>
      <HeaderMenu />

      <div className="view-wrapper is-full">
        <div className="stories-wrapper is-home">
          {/*Side menu*/}
          <SideMenu
            toggle={toggleMenu}
            onchange={(e) => {
              toggleChange(e);
            }}
          />
          {/*Page content*/}
          <div className="inner-wrapper">
            <a
              className="mobile-sidebar-trigger is-home-v2"
              onClick={menuClick}
            >
              <svg className="feather feather-menu">
                <use href={featherIcon + "#feather-menu"}></use>
              </svg>
            </a>

            <Content Select={Select} {...props} />
          </div>
        </div>
      </div>
    </div>
  );
};

const Search = ({ handleSubmit, tagSelect, tags }) => {
  return (
    <Formik
      initialValues={{ search: "" }}
      onSubmit={(values) => {
        handleSubmit(values);
      }}>
      <Form>
        <div className="field-group">
          <div className="search-icon">
            <i className="fa fa-search"></i>
          </div>
          <Field
            name="tags"
            className="input is-fade"
            options={tags}
            component={tagSelect}
          />
        </div>
        <button type="submit" className="button is-solid accent-button form-button">Search</button>
      </Form>
    </Formik>
  )
}

function trimMyString(string, maxLength, start = 0) {
  if (string.length > maxLength) {
    let trimmedString = string.substr(start, maxLength);
    return (
      trimmedString.substr(
        start,
        Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))
      ) + " ..."
    );
  }
  return string;
}


const Content = ({ Select }) => {
  const [loading, setLoading] = useState(false);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [filteredPastEvents, setFilteredPastEvents] = useState([]);
  const [isCancelled, setIsCancelled] = useState(false);
  const [tags, setTags] = useState([]);
  const [fieldValue, setFieldValue] = useState("");
  const [isFilter, setIsFilter] = useState(false);

  const filterLatestEvents = (data) => {
    const now = new Date()

    const latestEvents = data.filter(event => {
      if (!moment(convertUTCToLocal(event.startDate)).isBefore(now) ||
          !moment(convertUTCToLocal(event.endDate)).isBefore(now)) {
          return event
      } 
    })
    
    return latestEvents;
  } 

  const getEvents = async () => {
    setLoading(true);

    try {
      const result = await Api.getEvents();

      if (result.length > 0) {
        setFilteredEvents(filterLatestEvents(result));
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error("getEvents Error: ", error);
      setLoading(false);
    }
  }

  const getPastEvents = async () => {
    setLoading(true);

    try {
      const result = await Api.getPastEvents();

      if (result.length > 0) {
        setFilteredPastEvents(result);
        setLoading(false);
      }
    } catch (error) {
      console.error("getPastEvents Error: ", error);
      setLoading(false);
    }
  }

  const getAllTags = async () => {
    try {
      const result = await Api.getAllTags();
      if (result) {
        setTags(result);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async () => {
    if (fieldValue.length > 0) {
      setFilteredEvents([]);
      setFilteredPastEvents([])
      setIsFilter(true);

      let ids = fieldValue.map(item => {
        return item.id
      })

      const object = {
        tagIds: ids,
        skip: 0,
        take: 12,
      };

      setLoading(true);
      await Api.getByTags(object).then((response) => {
        setFilteredEvents(response);
        setLoading(false);
      });
      setLoading(false);
    } else {
      setIsFilter(false);
      getEvents();
      getPastEvents();
    }
  }

  const convertUTCToLocal = (date, format) => {
    return moment.utc(date).local().format(format);
  }

  const getAllEvents = async () => {
    await Api.getAccessToken(true);

    Promise.all([
      getEvents(),
      getPastEvents(),
      getAllTags()
    ])
  }

  useEffect(() => {
    !isCancelled && getAllEvents();

    return () => {
      setIsCancelled(true);
    }
  }, []);

  const resetFilter = () => {
    setFilteredEvents([]);
    setFilteredPastEvents([])
    setIsFilter(false);
    setFieldValue([]);
    getEvents();
    getPastEvents();
  };

  const TagSelect = ({ options }) => {
    $(".css-g1d714-ValueContainer").animate(
      { scrollTop: $(document).height() },
      "slow"
    );

    const style = {
      control: (base, state) => ({
        ...base,
        border: state.isFocused ? 0 : 0,
        boxShadow: state.isFocused ? 0 : 0,
        "&:hover": {
          border: state.isFocused ? 0 : 0,
        },
      }),
      valueContainer: (base) => ({
        ...base,
        paddingLeft: 36,
        minWidth: 220,
        maxWidth: 350
      }),
    };

    return (
      <Select
        getOptionLabel={(option) => option.tagText + "(" + option.tagType + ")"}
        getOptionValue={(option) => option.id}
        options={options}
        styles={style}
        className="event-select"
        onChange={(val) => {
          setFieldValue(val);
        }}
        value={fieldValue}
        isMulti
      />
    );
  };

  return (
  <>
    <div className="events-page">
      <div className="events-search py-4">
        {isFilter && (
          <button
            className="button dark-grey-button reset-button"
            onClick={resetFilter}
          >
            Reset Filter
          </button>
        )}
        <Search handleSubmit={handleSubmit} tagSelect={TagSelect} tags={tags} /> 
      </div>

      {filteredEvents.length > 0 || filteredPastEvents.length > 0 ? (
      <section id="eventsSection">
            {filteredEvents.length > 0 && (
              <div className="container">
                <div className="mb-4">
                  <h2>{isFilter ? "Search Results" : "Latest Events"}</h2>
                </div>

                {loading ? <Loading /> : (
                  <div>
                    <div className="row">
                      {filteredEvents.map((item) => (
                        <div className="col-lg-4 col-md-6" key={item.id} >
                          <div className="blog-item">
                            <div className="image">
                              <Link to={`/events/${item.slug}`}>
                                <img src={item.coverImageUrl ? item.coverImageUrl : fallbackImage} alt="" />
                              </Link>
                            </div>
                            <ul className="post-meta">
                              <li className="w-100 d-flex justify-content-between ">
                                <div className="d-flex">
                                  <i className="fa fa-calendar"></i>
                                  <span>
                                    <span>{convertUTCToLocal(item.startDate, 'MMM DD, YYYY')}</span>
                                    {item.endDate ? (<span> - {convertUTCToLocal(item.endDate, "MMM DD, YYYY")}</span>) : ''}
                                  </span>

                                </div>
                                <div>
                                  {item.isVirtual ? <span className="tag hightlight square float-right">VIRTUAL</span> : ""}
                                </div>
                              </li>
                            </ul>
                            <div className="content">
                              <h3>
                                <Link to={`/events/${item.slug}`}>{item.name}</Link>
                              </h3>

                              {item.description ? (
                                <div>{trimMyString(item.description, 100)}</div>
                              ) : (
                                ""
                              )}

                              <div className="d-block">
                                <Link to={`/events/${item.slug}`} className="read-more">
                                  See event details
                                  <i className="flaticon-curve-arrow-1"></i>
                                </Link>

                                {
                                  item.totalAttendees > 0 ?
                                    <span className="float-right attendees mt-2">
                                      <i className="fa fa-users icon-hightlight"></i> {item.totalAttendees}
                                    </span>
                                    :
                                    ""
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}

              </div>
            )}

        {filteredPastEvents.length > 0 && (<div className="container">
          <div className="mb-4">
            <h2>Past Events</h2>
          </div>

          {loading ? (
            <Loading />
          ) : (
            <div>
              <div className="row">
                {filteredPastEvents.map((item) => (
                  <div className="col-lg-4 col-md-6" key={item.id} >
                    <div className="blog-item">
                      <div className="image">
                        <Link to={"/events/"}>
                          <img src={item.coverImageUrl ? item.coverImageUrl : fallbackImage} alt="" />
                        </Link>
                      </div>
                      <ul className="post-meta">
                        <li className="w-100 d-flex justify-content-between">
                          <div className="d-flex">
                            <i className="fa fa-calendar"></i>
                            <span>
                              <span>{convertUTCToLocal(item.startDate, 'MMM DD, YYYY')}</span>
                              {item.endDate ? (<span> - {convertUTCToLocal(item.endDate, "MMM DD, YYYY")}</span>) : ''}
                            </span>
                            
                          </div>
                          <div>
                            {item.isVirtual ? <span className="tag hightlight square float-right">VIRTUAL</span> : ""}
                          </div>
                        </li>
                      </ul>
                      <div className="content">
                        <h3>
                          <Link to={`/events/${item.slug}`}>{item.name}</Link>
                        </h3>

                        {item.description ? (
                          <div>{trimMyString(item.description, 100)}</div>
                        ) : (
                          ""
                        )}

                        <div className="d-block">
                          <Link to={`/events/${item.slug}`} className="read-more">
                            See event details
                            <i className="flaticon-curve-arrow-1"></i>
                          </Link>

                          <span className="float-right attendees mt-2">
                            <i className="fa fa-users icon-hightlight"></i> {item.totalAttendees}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
      </div>)}
      </section>) : (
        <section>
          <div className="container">
            <div className="row">
              <div className="col-sm-12">No Events found</div>
            </div>
          </div>
        </section>
      )}
    </div>
  </>)
}

const mapReduxStateToProps = (state) => ({
  searchValue: state.search.searchValue,
  shouldSearch: state.search.shouldSearch,
});

export default connect(mapReduxStateToProps, {
  logout,
  searchChangeValue,
  handleSearch,
})(withRouter(MainEvents));
