import React, { useEffect, useState } from "react";
import "../style.scss";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import script from "../../../../Assets/img/icons/feather.svg";
import userDefaultImg from "../../../../Assets/img/user-defoult-img.jpg";
import Api from "../../../../Helpers/Api";
import { Link, useHistory, withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "../../../Common/Loading";
import "./style.scss";
import HeaderMenu from "../../../Common/HeaderMenu";
import SideMenu from "../SideMenu";
import { connect } from "react-redux";
import { logout } from "../../../../Redux/actions/auth.action";
import Select from "react-select";
import { useParams } from "react-router-dom";
import ImageRequirements from "../../../Common/ImageRequirements";
import csc from "country-state-city";
import moment from "moment";

const EditEvent = (props) => {
  const [toggleMenu, setToggleMenu] = useState(false);

  const menuClick = () => {
    setToggleMenu(true);
  };

  const toggleChange = (data) => {
    setToggleMenu(data);
  };
  return (
    <div>
      <HeaderMenu />
      <div className="view-wrapper is-full">
        <div className="stories-wrapper is-home">
          {/*Side menu*/}
          <SideMenu
            toggle={toggleMenu}
            onchange={(e) => {
              toggleChange(e);
            }}
          />

          {/*Page content*/}
          <div className="inner-wrapper">
            <a
              className="mobile-sidebar-trigger is-home-v2"
              onClick={menuClick}
            >
              <svg className="feather feather-menu">
                <use href={script + "#feather-menu"}></use>
              </svg>
            </a>

            <Content {...props} />
          </div>
        </div>
      </div>
    </div>
  );
};

const TagSelect = ({ values, options, field, setFieldValue, ...props }) => {
  const style = {
    control: (base, state) => ({
      ...base,
      border: state.isFocused ? 0 : 0,
      boxShadow: state.isFocused ? 0 : 0,
      "&:hover": {
        border: state.isFocused ? 0 : 0,
      },
    }),
  };

  return (
    <Select
      getOptionLabel={(option) => option.tagText}
      getOptionValue={(option) => option.id}
      options={options}
      styles={style}
      className="ml-5"
      onChange={(val) => {
        setFieldValue("tags", val);
      }}
      value={values.tags}
      isOptionDisabled={(option) =>
        values.tags ? values.tags.length >= 7 : false
      }
      isMulti
    />
  );
};

const Content = (props) => {
  let UserId = localStorage.getItem("userId");
  let username = localStorage.getItem("username");
  let history = useHistory();
  const { slug } = useParams();
  const [mainFileStatus, setMainFileStatus] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [file2, setFile2] = useState([]);
  const [mainFile, setMainFile] = useState(null);
  const [tags, setTags] = useState([]);
  const [event, setEvent] = useState({});
  const [formUpdated, setFormUpdated] = useState(false);
  const [country, setCountry] = useState([]);
  const [region, setRegion] = useState([]);
  const [coverImage, setCoverImage] = useState([]);
  const [newStartDate, setNewStartDate] = useState(null);
  const [newEndDate, setNewEndDate] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [commentVal, setcommentVal] = useState('');


  const [initialValues, setInitialValues] = useState({
    name: "",
    description:  "",
    startDate: new Date(),
    endDate: new Date(),
    tags: [],
    country: '',
    stateOrProvince: '',
    city: '',
    isPrivate: true,
    isVirtual: false
  })    

  const toastMessage = (type, message) => {
    toast[type](message);
  };

  const handleSubmitClick = async (formValue) => {

    if (mainFile === null) {
        setMainFileStatus(false);
    } else {
        try {
        setLoading(true);

        let result;
        let otherImages = [];
        
        formValue.createdByUserId = UserId;

        if (mainFile)
        formValue.images = [
            {
            image: mainFile.substring(mainFile.indexOf(",") + 1),
            isMain: true,
            },
            ...otherImages,
        ];

        formValue.tags = formValue.tags
          ? formValue.tags.map((tag) => tag)
          : [];

        const payload = {
            id: event.id,
            name: formValue.name,
            description: formValue.description,
            createdByUserId: UserId,
            startDate: new Date(newStartDate).toISOString(),
            endDate: new Date(newEndDate).toISOString() ,
            artEventDetails: {
                id: event.id,
                city: formValue.city,
                stateOrProvince: selectedRegion,
                country: formValue.country,
                tags: formValue.tags,
                latitude: 0,
                longitude: 0,
                coverImageUrl: formValue.images[0].image,
            },
            coverImageUrl:  formValue.images[0].image,
            isPrivate: formValue.isPrivate,
            isVirtual: formValue.isVirtual,
            slug: slug
        }

        result = await Api.updateEvent(payload);

        if (result?.message === "Success" && result.statusCode === 200) {
          toastMessage(
            "success",
            `Event Updated Successfully.`
          );
          setLoading(false);
          setTimeout(() => {
            history.push(`/events`);
          }, 1500);
        } else {
          toastMessage(
            "Error",
            result.error
          );
          setLoading(false);
          console.error(result.error);
        }
      } catch (ex) {
        setLoading(false);
        console.error(ex);
      }
    }
  };

  function openImgUpload(name) {
    document.getElementById(name).click();
  }

  // Handles file upload event and updates state
  function handleMainChange(event) {
    if (event.target.files[0] != null) {
      getBase64(event.target.files[0], (result) => {
        setMainFile(result);
      });
    } else return;
  }

  const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const getAllTags = async () => {
    try {
      const result = await Api.getAllTags();
      // Sort tags alphabetically
      result.sort((a, b) => {
        a = a.tagText.replace("#", "");
        b = b.tagText.replace("#", "");
        if (a < b) {
          return -1;
        }
        if (a > b) {
          return 1;
        }
        return 0;
      });
      setTags(result);
    } catch (error) {
      console.error(error);
    }
  };

  function selectCountry() {
    const result = csc.getAllCountries();
    setCountry(result);
  }

  function selectRegion(e) {
    setRegion([]);

    setSelectedCountry(e.target.value)
    
    const result = csc.getStatesOfCountry(e.target.value);
    setRegion(result);
  }

  function selectCity(e) {
    setSelectedRegion(e.target.value)
  }

  function checkChange() {
    setFormUpdated(true);
  }

 const getEventDetails = async (slug) => {

    setcommentVal('');
    setLoading(true);

    try {
      const result = await Api.getEventDetails(slug);

      if(result) {
        setInitialValues({
            name: result.name ? result.name : "",
            description: result.description ? result.description :  "",
            startDate: result.startDate ? moment.utc(result.startDate).local().format("YYYY-MM-DDThh:mm") : moment().format("YYYY-MM-DDThh:mm"),
            endDate: result.endDate ? moment.utc(result.endDate).local().format("YYYY-MM-DDThh:mm") : moment().format("YYYY-MM-DDThh:mm"),
            tags: result.artEventDetails.tags ? result.artEventDetails.tags : [],
            country:  result?.artEventDetails?.country ? result.artEventDetails.country :   'US',
            stateOrProvince: result?.artEventDetails?.stateOrProvince ? result.artEventDetails.stateOrProvince :   '',
            city:  result?.artEventDetails?.city ? result.artEventDetails.city :   '',
            isPrivate:result.isPrivate ? result.isPrivate : true,
            isVirtual:  result.isVirtual ? result.isVirtual :  false
        })

        setCoverImage(result.coverImageUrl)
        setMainFile(result.coverImageUrl)

        setFormUpdated(false);
        const Statesresult = csc.getStatesOfCountry(
          result.artEventDetails.country !== "" ? result.artEventDetails.country : "US"
        );
        setRegion(Statesresult);

        setNewStartDate(moment.utc(result.startDate).local().format("YYYY-MM-DDThh:mm"));
        setNewEndDate(moment.utc(result.endDate).local().format("YYYY-MM-DDThh:mm"))
        setSelectedRegion(result?.artEventDetails?.stateOrProvince)

        setEvent(result);
        getArtWorksComments(result.id);
        setLoading(false);
      }

    } catch (error) {
      console.error("getEventDetails Error:", error);
      setLoading(false);
    }
  }

  const handleStartDateChange = (event) => {
    setNewStartDate(event.target.value)
  }

  const handleEndDateChange = (event) => {
    setNewEndDate(event.target.value)
  }

  useEffect(async () => {
    await Promise.all([
        getEventDetails(slug),
        getAllTags(),
        selectCountry()
    ]);
  }, []);

  const EventUploadSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "Min characters is 5")
      .max(100, "Max characters is 200")
      .required("Required"),
    description: Yup.string().max(1000, "Max characters is 1000").required(),
    startDate: Yup.date().required("Required"),
    endDate: Yup.date().required("Required"),
    tags: Yup.array().required()
    .min(1, "Min 1 cause is required")
    .of(
      Yup.object().shape({
        id: Yup.string().required(),
        tagText: Yup.string().required(),
      })
    ),
  });


  return (
    <>
      <ToastContainer />
      <div className="settings-wrapper">
        <div id="general-settings" className="settings-section is-active">
          <div className="settings-panel">
            <div className="row settings-form-wrapper Custmsettings-form-wrappers">
              <div className="settings-form col-md-7">
                {isLoading ? (
                  <Loading />
                ) : (
                  <>
                    <div className="title-wrap">
                      <a className="mobile-sidebar-trigger">
                        <i data-feather="menu"></i>
                      </a>
                      <h2>Edit Event</h2>
                    </div>
                    <div className="">
                      <Formik
                        enableReinitialize={true}
                        initialValues={initialValues}
                        validationSchema={EventUploadSchema}
                        onSubmit={(values, { resetForm }) => {
                          handleSubmitClick(values);
                        }}
                      >
                        {({
                          errors,
                          touched,
                          handleSubmit,
                          setFieldValue,
                          values,
                        }) =>  (
                          <Form className="w-100">
                            <div className="columns is-multiline">
                              <div className="column is-6 pb-0">
                                <div
                                  className={
                                    "field field-group" +
                                    (errors.name && touched.name
                                      ? " is-invalid"
                                      : "")
                                  }
                                >
                                  <label>Name</label>
                                  <div className="control has-icon">
                                    <Field
                                      type="Text"
                                      name="name"
                                      className="input is-fade"
                                    ></Field>
                                    <div className="form-icon">
                                      <svg className="feather feather-user">
                                        <use
                                          href={script + "#feather-user"}
                                        ></use>
                                      </svg>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      "errors-msg" +
                                      (errors.name && touched.name
                                        ? " show-error"
                                        : "")
                                    }
                                  >
                                    {errors.name}
                                  </div>
                                </div>
                              </div>
                              
                              <div className="column is-6 pb-0 markAsComplete">
                                <div
                                  className={
                                    "field field-group" +
                                    (errors.isVirtual && touched.isVirtual
                                      ? " is-invalid"
                                      : "")
                                  }
                                >
                                  <label>Mark as Virtual</label>
                                  <div className="control has-icon">
                                    <Field
                                      type="checkbox"
                                      name="isVirtual"
                                    />
                                  </div>
                                  <div
                                    className={
                                      "errors-msg" +
                                      (errors.isVirtual &&
                                      touched.isVirtual
                                        ? " show-error"
                                        : "")
                                    }
                                  >
                                    {errors.isVirtual}
                                  </div>
                                </div>
                              </div>

                              <div className="column is-12 pb-0">
                                <div
                                  className={
                                    "field field-group" +
                                    (errors.description && touched.description
                                      ? " is-invalid"
                                      : "")
                                  }
                                >
                                  <label>Description</label>
                                  <div className="control has-icon">
                                    <Field
                                      component="textarea"
                                      name="description"
                                      className="input is-fade"
                                      rows={3}
                                    ></Field>
                                    <div className="form-icon">
                                      <svg className="feather feather-message">
                                        <use
                                          href={script + "#feather-message"}
                                        ></use>
                                      </svg>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      "errors-msg" +
                                      (errors.description && touched.description
                                        ? " show-error"
                                        : "")
                                    }
                                  >
                                    {errors.description}
                                  </div>
                                </div>
                              </div>

                              <div className="column is-6 pb-0">
                                <div
                                  className={
                                    "field field-group" +
                                    (errors.startDate && touched.startDate
                                      ? " is-invalid"
                                      : "")
                                  }
                                >
                                  <label>Start Date</label>
                                  <div className="control has-icon">
                                    <Field
                                      type="datetime-local"
                                      name="startDate"
                                      className="input is-fade"
                                      value={newStartDate ? newStartDate : initialValues.startDate}
                                      onChange={handleStartDateChange}
                                    ></Field>
                                    <div className="form-icon">
                                      <svg className="feather feather-user">
                                        <use
                                          href={script + "#feather-user"}
                                        ></use>
                                      </svg>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      "errors-msg" +
                                      (errors.startDate && touched.startDate
                                        ? " show-error"
                                        : "")
                                    }
                                  >
                                    {errors.startDate}
                                  </div>
                                </div>
                              </div>

                              <div className="column is-6 pb-0">
                                <div
                                  className={
                                    "field field-group" +
                                    (errors.endDate && touched.endDate
                                      ? " is-invalid"
                                      : "")
                                  }
                                >
                                  <label>End date</label>
                                  <div className="control has-icon">
                                    <Field
                                      type="datetime-local"
                                      name="endDate"
                                      className="input is-fade"
                                      value={newEndDate ? newEndDate : initialValues.endDate}
                                      onChange={handleEndDateChange}
                                    ></Field>
                                    <div className="form-icon">
                                      <svg className="feather feather-user">
                                        <use
                                          href={script + "#feather-user"}
                                        ></use>
                                      </svg>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      "errors-msg" +
                                      (errors.endDate && touched.endDate
                                        ? " show-error"
                                        : "")
                                    }
                                  >
                                    {errors.endDate}
                                  </div>
                                </div>
                              </div>

                               <div className="column is-6 pb-0">
                                <div className="field field-group">
                                <label>Country</label>
                                <div className="control has-icon">
                                    <select
                                    id="country"
                                    value={selectedCountry ? selectedCountry
                                     : initialValues.country}
                                    disabled={!country.length}
                                    name="country"
                                    onChange={selectRegion}
                                    className="input is-fade"
                                    onBlur={checkChange}
                                    >
                                    {country.length > 1  ? (
                                        <SelectOption
                                        object={country}
                                        selectedValue={initialValues.country}
                                        />
                                    ) : (
                                        <option>...</option>
                                    )} 
                                    </select>
                                    <div className="form-icon">
                                    <svg className="feather feather-globe">
                                        <use href={script + "#feather-globe"}></use>
                                    </svg>
                                    </div>
                                </div>
                                </div>
                            </div>

                            <div className="column is-6 pb-0">
                                <div
                                className={
                                    "field field-group" +
                                    (errors.stateOrProvince && touched.stateOrProvince
                                    ? " is-invalid"
                                    : "")
                                }
                                >
                                <label>
                                    {initialValues.country  === "US"
                                    ? "State"
                                    : "Region"}
                                </label>
                                <div className="control has-icon">
                                    <select
                                    value={selectedRegion ? selectedRegion : initialValues.stateOrProvince}
                                    disabled={!region.length}
                                    name="stateOrProvince"
                                    onChange={selectCity}
                                    className="input is-fade"
                                    onBlur={checkChange}
                                    >
                                    <option hidden>
                                        Please Select State Region
                                    </option>
                                    {region.length ? (
                                        <SelectOption object={region} selectedValue={initialValues.stateOrProvince}  />
                                    ) : (
                                        <option>...</option>
                                    )}
                                    </select>
                                    <div className="form-icon">
                                    <svg className="feather feather-globe">
                                        <use href={script + "#feather-globe"}></use>
                                    </svg>
                                    </div>
                                </div>
                                <div
                                    className={
                                    "errors-msg" +
                                    (errors.stateOrProvince && touched.stateOrProvince
                                        ? " show-error"
                                        : "")
                                    }
                                >
                                    {errors.stateOrProvince}
                                </div>
                                </div>
                            </div>

                             <div className="column is-6 pb-0">
                                <div
                                className={
                                    "field field-group" +
                                    (errors.city && touched.city
                                    ? " is-invalid"
                                    : "")
                                }
                                >
                                <label>City</label>
                                <div className="control has-icon">
                                    <Field
                                        type="text"
                                        name="city"
                                        className="input is-fade"
                                        onBlur={checkChange}
                                        value={values.city}
                                    ></Field>
                                    <div className="form-icon">
                                    <svg className="feather feather-globe">
                                        <use href={script + "#feather-globe"}></use>
                                    </svg>
                                    </div>
                                </div>
                                <div
                                    className={
                                    "errors-msg" +
                                    (errors.city && touched.city
                                        ? " show-error"
                                        : "")
                                    }
                                >
                                    {errors.city}
                                </div>
                                </div>
                            </div>

                              <div className="column is-6 pb-0">
                                <div
                                  className={
                                    "field field-group" +
                                    (errors.tags && touched.tags
                                      ? " is-invalid"
                                      : "")
                                  }
                                >
                                  <label>causes</label>
                                  <div className="control has-icon">
                                    <Field
                                      name="tags"
                                      // className="input is-fade"
                                      options={tags}
                                      setFieldValue={setFieldValue}
                                      values={values}
                                      component={TagSelect}
                                    />
                                    <div className="form-icon mt-1">
                                      <svg className="feather feather-user">
                                        <use
                                          href={script + "#feather-user"}
                                        ></use>
                                      </svg>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      "errors-msg" +
                                      (errors.tags && touched.tags
                                        ? " show-error"
                                        : "")
                                    }
                                  >
                                    {errors.tags}
                                  </div>
                                </div>
                              </div>

                              <div className="column is-6 pb-0 markAsComplete">
                                <div
                                  className={
                                    "field field-group" +
                                    (errors.isPrivate && touched.isPrivate
                                      ? " is-invalid"
                                      : "")
                                  }
                                >
                                  <label>Mark as Private</label>
                                  <div className="control has-icon">
                                    <Field
                                      type="checkbox"
                                      name="isPrivate"
                                    />
                                  </div>
                                  <div
                                    className={
                                      "errors-msg" +
                                      (errors.isPrivate &&
                                      touched.isPrivate
                                        ? " show-error"
                                        : "")
                                    }
                                  >
                                    {errors.isPrivate}
                                  </div>
                                </div>
                              </div>

                              <div className="column is-12">
                                <div className="buttons">
                                  {loading ? (
                                    <Loading />
                                  ) : (
                                    <div>
                                      <button
                                        disabled={ !mainFile}
                                        className="button is-solid accent-button form-button"
                                        onClick={handleSubmit}
                                      >
                                        Save
                                      </button>
                                      <Link
                                        className="button is-solid form-button dark-grey-button"
                                        to={
                                          event && event.slug ? `/events/${slug}` : `/events`
                                        }
                                      >
                                        Cancel
                                      </Link>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </>
                )}
              </div>
              {!isLoading && (
                <div className="col-md-5">
                  <div className="row">
                    <div className="col-12">
                      <div className="illustration">
                        <h2 className="text-center mb-5">
                          Upload event cover image
                        </h2>
                        <div className="user-img-wrapper user--main-img-wrapper custmuser-img-wrapper">
                          {mainFile ? (
                            <ImageThumb image={mainFile} />
                          ) : (
                            <img
                              src={coverImage ? coverImage : userDefaultImg}
                              // className="user-img"
                              alt="user-img"
                            />
                          )}
                          <div
                            className="upload-img-icon"
                            title="Change Profile Image"
                            onClick={() =>
                              openImgUpload("hidden-main-img-input")
                            }
                          >
                            <svg className="feather feather-camera">
                              <use href={script + "#feather-camera"}></use>
                            </svg>
                          </div>
                        </div>

                        <ImageRequirements width="330" length="240"/>

                        <div className="imageCustmValidation">
                          <div
                            className={
                              "field field-group" +
                              (!mainFile ? " is-invalid" : "")
                            }
                          >
                            {!mainFileStatus && (
                              <div
                                className={
                                  "errors-msg" +
                                  (!mainFile ? " show-error" : "")
                                }
                              >
                                Cover image is Required
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      
                    </div>
                    <div
                      id="upload-box"
                      className="mt-5 d-flex justify-content-center"
                    >
                      <input
                        type="file"
                        onChange={handleMainChange}
                        id="hidden-main-img-input"
                        accept="image/png, image/jpeg"
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const ImageThumb = ({ image }) => {
  return (
    <div className="col-12">
      <img src={image} alt={image} className="mb-2" />
    </div>
  );
};

const SelectOption = ({ object, selectedValue = "US" }) => {
  if (object && object.length > 0) {
    return object.map((item, index) => (
      <option value={item.isoCode} key={index}>
        {item.name}
      </option>
    ));
  }
};

const mapReduxStateToProps = (state) => ({
  username: state.auth.username,
});

export default connect(mapReduxStateToProps, {
  logout,
})(withRouter(EditEvent));

