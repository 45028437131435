import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { URL_LOGIN_PAGE } from "../Helpers/urls";

const ProtectedRoute = (props) => {
  const { isLoggedIn, children, username, accessToken } = props;

  return (
    <div>{!isLoggedIn || !username || !accessToken ? <Redirect to={{ pathname: URL_LOGIN_PAGE, state: props.location.pathname}} /> : <>{children}</>}</div>
  );
};

const mapReduxStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
  username: state.auth.username,
  accessToken: state.auth.accessToken,
});

export default connect(mapReduxStateToProps, null)(ProtectedRoute);
