import React, {useEffect, useState} from 'react'
import Api from '../../Helpers/Api';
import { getImageData, modifyData } from '.';
import VisibilitySensor from 'react-visibility-sensor'

const About = () => {
    const [about, setAbout] = useState([]);
    const [visibility, setVisibility] = useState(false)
    const [show, setShow] = useState(false)

    const getAboutData = async () => {
        const about = await Api.getAbout();
        const visionImageIds = about.map((data) => data.vision_image);
        const visionImageData = await getImageData(visionImageIds)
        const visionData = modifyData(about, visionImageData, 'vision_image', 'vision_image')

        const missionImageIds = about.map((data) => data.mission_image);
        const missionImageData = await getImageData(missionImageIds)
        const missionData = modifyData(about, missionImageData, 'mission_image', 'mission_image')

        const who_we_are_imageImageIds = about.map((data) => data.who_we_are_image_image).filter(i => i);
        const who_we_are_imageImageData = await getImageData(who_we_are_imageImageIds)
        const who_we_are_imageData = modifyData(about, who_we_are_imageImageData, 'who_we_are_image_image', 'who_we_are_image_image')

        setAbout([...visionData, ...missionData, ...who_we_are_imageData][0])
    }

    useEffect(() => {
        if (!show && visibility) {
            setShow(true)
        }
    }, [visibility])

    useEffect(() => {
        if (show) {
            getAboutData()
        }
    }, [show])

    return (
      <VisibilitySensor
        partialVisibility
        onChange={(isVisible) => {
            setVisibility(isVisible)
        }}
      >
          <section id="about" className="about-section counter-section pt-80 pb-80">
              <div className="container">
                  <div className="row">
                      <div className="col-lg-12">
                          <div className="about-area-content remove-before">
                              <div className="counter-background text-center">
                                  <h3>{about.mission_title}</h3>
                              </div>
                              <p
                                className="text-center ourMissionText"
                                dangerouslySetInnerHTML={{ __html: about.mission_text }}
                              ></p>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
      </VisibilitySensor>
    )
}

export default About
