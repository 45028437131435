import React, { useState, useEffect } from "react";
import "./style.scss";
import { errorMessage } from "../../../Helpers/utils";
import Loading from "../../Common/Loading";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { URL_LOGIN_PAGE } from "./../../../Helpers/urls";
import { Link, useHistory } from "react-router-dom";
import Api from "../../../Helpers/Api";
import whiteIcon from '../../../Assets/img/white-logo.png'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ORIGIN_URL } from "../../../Configs/config";

const ForgotPassword = (props) => {

    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [resetData, setResetData] = useState(null);
    const PasswordForgotRequestSchema = Yup.object().shape({
        email: Yup.string().email("Invalid email").required("Required"),
    });

    const PasswordResetRequestSchema = Yup.object().shape({
        email: Yup.string().email("Invalid email").required("Required"),
        password: Yup.string().required("Required").min(6, 'Password is too short - should be 6 chars minimum.'),
        passwordConfirmation: Yup.string().required("Required").oneOf([Yup.ref("password"), null], "Password must match")
    });

    useEffect(() => {
        if (history.location.pathname == "/reset-password") {
            if (location.search) {
                const params = new URLSearchParams(window.location.search)
                setResetData({
                    token: params.get('token'),
                    email: params.get('email'),
                    password: "",
                    passwordConfirmation: "",
                });

            }
        }
    }, []);


    const toastMessage = (type, message) => {
        toast[type](message)
    };

    const handleResetPasswordClick = async (values, resetForm) => {
        setLoading(true);
        let string = "?Token=" + values.token + "&Email=" + values.email + "&Password=" + values.password + "&ConfirmPassword=" + values.passwordConfirmation;
        try {
            const result = await Api.doRestPassword(string)
            setLoading(false);
            if (result.message == "Success") {
                history.push('/login')
                setTimeout(() => { toastMessage('success', "Your password has been reset. You can now use the new password to login to your account") }, 100)
            } else {
                toastMessage('error', result)
            }
        } catch (err) {
            setLoading(false);
            toastMessage('error', err)
        }
    };

    const handleResetLinkClick = async (values, resetForm) => {
        values.resetPasswordLink =ORIGIN_URL + '/reset-password'
        setLoading(true);
        try {
            const result = await Api.doForgotPassword(values)
            setLoading(false);
            if (result.message == "Success") {
                resetForm()
                toastMessage('success', "We have sent a password reset link to your email address.")
            } else {
                toastMessage('error', result)
            }
        } catch (err) {
            setLoading(false);
            toastMessage('error', err)
        }
    };


    return (
        <div className="login-wrapper">
          <div className="login">
            <div className="login-left">
              <div className="login-left-content d-flex flex-column justify-content-between">
                <div className="login-content-heading pt-5">
                  <div className="login-heading-content">
                    <h2>Welcome to Collective!</h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="login-right d-flex flex-column justify-content-center align-center login-form">
              <div className="form-inner">
                <div className="logo-wrap">
                  <div className="wrap-inner">
                    <img src={whiteIcon} alt="Logo" />
                  </div>
                </div>
                <div className="login-right-content-heading form-act">
                  <div className="form-wrapper">
                    <div className="team-item">
                      <h2 className="text-uppercase theme-color">Collective</h2>
                      <span className="theme-color">By the Artivist Foundation</span>
                    </div>
                    { resetData ?  
                    <Formik
                      key="reset-form"
                      initialValues={{ email: resetData.email, password: "", passwordConfirmation: "", token: resetData.token }}
                      validationSchema={PasswordResetRequestSchema}
                      onSubmit={(values, { resetForm }) => {
                        handleResetPasswordClick(values, resetForm);
                      }}
                    >
                      {({ errors, touched, handleSubmit }) => (
                        <Form>
                          <div className="login-form">
                            <div className="field">   
                                <div className="control">
                                    <Field
                                    type="Email"
                                    placeholder="Email"
                                    name="email"
                                    disabled
                                    ></Field>
                                    {errors.email &&
                                    touched.email &&
                                    errorMessage(errors.email)}
                                    <div className="input-icon">
                                    <i
                                        className="fa fa-envelope-o"
                                        aria-hidden="true"
                                    ></i>
                                    </div>
                                </div>
                            </div>

                            <div className="field">
                                <div className="control">
                                    <Field
                                    type="password"
                                    placeholder="Password"
                                    name="password"
                                    ></Field>
                                    {errors.password &&
                                    touched.password &&
                                    errorMessage(errors.password)}
                                    <div className="input-icon">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <rect
                                        x="3"
                                        y="11"
                                        width="18"
                                        height="11"
                                        rx="2"
                                        ry="2"
                                        ></rect>
                                        <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                                    </svg>
                                    </div>
                                </div>
                            </div>

                            <div className="field">
                                <div className="control">
                                <Field
                                type="password"
                                placeholder="Confirm Password"
                                name="passwordConfirmation"
                                ></Field>
                                {errors.passwordConfirmation &&
                                touched.passwordConfirmation &&
                                errorMessage(errors.passwordConfirmation)}
                                <div className="input-icon">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    <rect
                                    x="3"
                                    y="11"
                                    width="18"
                                    height="11"
                                    rx="2"
                                    ry="2"
                                    ></rect>
                                    <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                                </svg>
                                </div>
                            </div>
                            </div>

                            <div className="field">
                                <div className="control">
                                    { loading ? <Loading /> : <button
                                    onClick={handleSubmit}
                                    className="button primary-button default-btn"
                                    >
                                    Reset Password
                                    </button>}
                                </div>
                                <div className="control">
                                    <Link
                                    className="d-flex justify-content-center register-link"
                                    to={URL_LOGIN_PAGE} >
                                    If you already have an account, log in
                                    </Link>
                                </div>
                            </div>

                          </div>
                        </Form>
                      )}
                    </Formik> :
                    <Formik
                        key="password-form"
                        initialValues={{ email: "" }}
                        validationSchema={PasswordForgotRequestSchema}
                        onSubmit={(values, { resetForm }) => {
                        handleResetLinkClick(values, resetForm);
                        }}
                    >
                        {({ errors, touched, handleSubmit }) => (
                        <Form>
                            <div className="login-form">
                            <div className="field">
                                <div className="field">
                                    <div className="control">
                                        <Field
                                        type="Email"
                                        placeholder="Email"
                                        name="email"
                                        ></Field>
                                        {errors.email &&
                                        touched.email &&
                                        errorMessage(errors.email)}
                                        <div className="input-icon">
                                        <i
                                            className="fa fa-envelope-o"
                                            aria-hidden="true"
                                        ></i>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="field">
                                <div className="control">
                                    { loading ? <Loading /> : <button
                                    onClick={handleSubmit}
                                    className="button primary-button default-btn"
                                    >
                                    Send Password Reset Link
                                    </button>}
                                </div>
                                <div className="control">
                                    <Link
                                    className="d-flex justify-content-center register-link"
                                    to={URL_LOGIN_PAGE} >
                                    If you already have an account, log in
                                    </Link>
                                </div>
                            </div>

                            </div>
                        </Form>
                        )}
                    </Formik>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      );
};

export default ForgotPassword;