import Header from "../../Common/Header";
import Footer from "../../Common/Footer";
import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import { URL_LANDING_PAGE } from "../../../Helpers/urls";
import "./style.scss";
import { useParams } from "react-router";
import Api from "../../../Helpers/Api";
import Loading from "../../Common/Loading";
import Moment from 'react-moment';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import { TWITTER_URL } from "../../../Configs/config";

const NewsDetails = () => {
  return (
    <div>
      <Header/>
      <NewsContent/>
      <Footer/>
    </div>
  );
};

function componentDidMount () {
  const script = document.createElement("script");
  script.src = "https://platform.twitter.com/widgets.js";
  script.async = true;
  document.body.appendChild(script);
}

const NewsContent = () => {
  let { slug } = useParams();
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);
  const pageUrl = window.location.href;

  const getNews = async (slug) => {
    setLoading(true);
    try {
      const result = await Api.getNewsByUrl(slug);
      const imageIds = result.map((news) => news.image);
      const imagesData = await Api.getAllImageUrl({
        "filter[id][in]": imageIds.toString(),
      });

      result.map((data) => {
        let imageUrls = imagesData.filter(
            (details) => details.id === data.image
        );
        if (imageUrls.length > 0) data.imageUrl = imageUrls[0].data.full_url;
      });
      setNews(result[0]);
      setLoading(false);
    } catch (err) {
      console.error("getTeams -> err", err);
      setLoading(false);
    }
  };

  const getIGPhotos = async () => {
    let images = await Api.instagramPhotos();
  };

  const getNewsData = async (slug) => {
    await Api.getAccessToken();
    Promise.all([getNews(slug), componentDidMount(), getIGPhotos()]);
  };


  useEffect(() => {
    !isCancelled && getNewsData(slug);

    return () => {
      setIsCancelled(true);
    }
  }, []);

  return (
    <>
      <div>

        <div className="page-title-area bg-15">
          <div className="container">
            <div className="page-title-content">
              <h2>{news.name}</h2>
            </div>
          </div>
        </div>

        <section className="blog-details-area ptb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-12">
                {
                  loading
                    ? <Loading/>
                    : <div className="blog-details-desc">
                      <div className="article-content">
                        <div className="entry-meta mb-2">
                          <ul>
                            <li><span>Posted On:</span> <a href="#">
                              <Moment date={news.date} format="MMM DD, YYYY" />
                            </a></li>
                          </ul>
                        </div>

                        <div className="article-image">
                          <img src={news.imageUrl} alt="image" width="100%" />
                        </div>

                        {
                          news.text
                          ? <div dangerouslySetInnerHTML={{__html: news.text}}></div>
                          : ''
                        }

                      </div>

                      <div className="article-footer">
                        <div className="article-tags  text-lg-right">
                          <span><i className='fa fa-share-alt'></i></span>

                          <a>Share</a>
                        </div>

                        <div className="article-share">
                          <ul className="social">

                            <li>
                              <FacebookShareButton url={pageUrl}>
                                <i className="flaticon-facebook"></i>
                              </FacebookShareButton>
                            </li>
                            <li>
                              <TwitterShareButton url={pageUrl}>
                                <i className="flaticon-twitter"></i>
                              </TwitterShareButton>
                            </li>
                            <li>
                              <LinkedinShareButton url={pageUrl}>
                                <i className="flaticon-linkedin"></i>
                              </LinkedinShareButton>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                }
              </div>

              <div className="col-lg-4 col-md-12">
                <aside className="widget-area mb-5" id="secondary">
                  <section className="widget widget-peru-posts-thumb">
                    <h3 className="widget-title">Instagram</h3>
                    <div className="elfsight-app-11489573-eca2-4a72-a084-7f4aa1319016"></div>
                  </section>
                </aside>
                <aside className="widget-area mb-5" id="secondary">
                  <section className="widget widget-peru-posts-thumb">
                    <h3 className="widget-title">Twitter</h3>
                    <div className="tweeter-widget">
                      <a className="twitter-timeline"
                         href={TWITTER_URL}
                         data-tweet-limit="3">
                      </a>
                    </div>
                  </section>
                </aside>

                <aside className="widget-area" id="secondary">
                  <section className="widget widget-peru-posts-thumb">
                    <h3 className="widget-title">Facebook</h3>
                    <iframe
                        src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fcollectivebyartivist&tabs=timeline&width=340&height=500&small_header=true&adapt_container_width=true&hide_cover=true&show_facepile=true&appId"
                        width="340" height="500" scrolling="no" frameBorder="0"
                        allowFullScreen={true}
                        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                  </section>
                </aside>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
};

export default NewsDetails;
