import React, { useEffect, useState } from "react";
import "../style.scss";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import script from "../../../../Assets/img/icons/feather.svg";
import userDefaultImg from "../../../../Assets/img/user-defoult-img.jpg";
import Api from "../../../../Helpers/Api";
import { Link, useHistory, withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { URL_USER_PROFILE } from "../../../../Helpers/urls";
import JwtDecode from "../../../Common/JwtDecode";
import Loading from "../../../Common/Loading";
import "./style.scss";
import HeaderMenu from "../../../Common/HeaderMenu";
import SideMenu from "../SideMenu";
import { connect } from "react-redux";
import { logout } from "../../../../Redux/actions/auth.action";
import Select from "react-select";
import { useParams } from "react-router-dom";
import ImageRequirements from "../../../Common/ImageRequirements";

const AddLibrary = (props) => {
  const [toggleMenu, setToggleMenu] = useState(false);

  const menuClick = () => {
    setToggleMenu(true);
  };

  const toggleChange = (data) => {
    setToggleMenu(data);
  };
  return (
    <div>
      <HeaderMenu />
      <div className="view-wrapper is-full">
        <div className="stories-wrapper is-home">
          {/*Side menu*/}
          <SideMenu
            toggle={toggleMenu}
            onchange={(e) => {
              toggleChange(e);
            }}
          />

          {/*Page content*/}
          <div className="inner-wrapper">
            <a
              className="mobile-sidebar-trigger is-home-v2"
              onClick={menuClick}
            >
              <svg className="feather feather-menu">
                <use href={script + "#feather-menu"}></use>
              </svg>
            </a>

            <Content {...props} />
          </div>
        </div>
      </div>
    </div>
  );
};

const TagSelect = ({ values, options, field, setFieldValue, ...props }) => {
  const style = {
    control: (base, state) => ({
      ...base,
      border: state.isFocused ? 0 : 0,
      boxShadow: state.isFocused ? 0 : 0,
      "&:hover": {
        border: state.isFocused ? 0 : 0,
      },
    }),
  };

  return (
    <Select
      getOptionLabel={(option) => option.tagText}
      getOptionValue={(option) => option.id}
      options={options}
      styles={style}
      className="ml-5"
      onChange={(val) => {
        setFieldValue("tags", val);
      }}
      value={values.tags}
      isOptionDisabled={(option) =>
        values.tags ? values.tags.length >= 7 : false
      }
      isMulti
    />
  );
};

const Content = (props) => {
  let UserId = localStorage.getItem("userId"); //get userid
  let username = localStorage.getItem("username"); //get userid
  let history = useHistory();
  const { artworkId } = useParams();
  const [fileStatus, setfileStatus] = useState(true);
  const [mainFileStatus, setMainFileStatus] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState([]);
  const [file2, setFile2] = useState([]);
  const [mainFile, setMainFile] = useState(null);
  const [tags, setTags] = useState([]);
  const [artwork, setArtwork] = useState({});

  const toastMessage = (type, message) => {
    toast[type](message);
  };

  const handleSubmitClick = async (formValue) => {
    // if (!artworkId && file.length === null) {
    //   setfileStatus(false);
    // }
    if (!artworkId && mainFile === null) {
      setMainFileStatus(false);
    } else {
      try {
        setLoading(true);
        formValue.userId = UserId;
        let otherImages = [];
        if (file.length)
          file.map((image) => {
            otherImages.push({
              image: image.substring(image.indexOf(",") + 1),
              isMain: false,
            });
          });
        if (mainFile)
          formValue.images = [
            {
              image: mainFile.substring(mainFile.indexOf(",") + 1),
              isMain: true,
            },
            ...otherImages,
          ];
        formValue.completedDate = !formValue.completeDate
          ? new Date().toISOString()
          : null;
        formValue.tags = formValue.tags
          ? formValue.tags.map((tag) => tag.id)
          : [];

        // Call endpoint
        let result;
        if (artworkId) {
          formValue.id = artworkId;
          result = await Api.UpdateArtwork(formValue);
        } else result = await Api.CreateArtwork(formValue);

        if (result.message === "Success" && result.statusCode === 200) {
          toastMessage(
            "success",
            `Artwork ${artworkId ? "Edited" : "Created"} Successfully.`
          );
          setLoading(false);
          setTimeout(() => {
            if (artworkId)
              history.push(
                `/p/${props.username || username}/${result.model.slug}`
              );
            else history.push(`/p/${props.username || username}`);
          }, 1500);
        } else {
          setLoading(false);
          console.error(result.error);
        }
      } catch (ex) {
        setLoading(false);
        console.error(ex);
      }
    }
  };

  function openImgUpload(name) {
    document.getElementById(name).click();
  }

  // Handles file upload event and updates state
  function handleMainChange(event) {
    if (event.target.files[0] != null) {
      getBase64(event.target.files[0], (result) => {
        setMainFile(result);
      });
    } else return;
  }

  // Handles file upload event and updates state
  async function handleChange(event) {
    let fileBase64 = [];
    let arr = [];
    for (const [key, fileData] of Object.entries(event.target.files)) {
      arr.push(fileData.name);
    }
    setFile2(arr);
    for (const [key, fileData] of Object.entries(event.target.files)) {
      getBase64(fileData, (result) => {
        fileBase64.push(result);
        setFile(fileBase64);
      });
    }
  }

  const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const getAllTags = async () => {
    try {
      const result = await Api.getAllTags();
      // Sort tags alphabetically
      result.sort((a, b) => {
        a = a.tagText.replace("#", "");
        b = b.tagText.replace("#", "");
        if (a < b) {
          return -1;
        }
        if (a > b) {
          return 1;
        }
        return 0;
      });
      setTags(result);
    } catch (error) {
      console.error(error);
    }
  };

  const getArtworkDetails = async () => {
    if (artworkId) {
      setIsLoading(true);
      try {
        const result = await Api.GetArtwork(artworkId);
        if (result) {
          const imageData = await Api.getArtworkImages({
            ArtworkId: result.id,
          });
          result.images = imageData;
          setArtwork(result);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(async () => {
    await Promise.all([getArtworkDetails(), getAllTags()]);
  }, []);

  const initialValues = {
    title: artwork.title || "",
    description: artwork.description || "",
    link: artwork.link || "",
    completeDate: !artwork.completedDate ? false : (!(artwork.completedDate && artwork.completedDate !== "0001-01-01T00:00:00")),
    tags: artwork.tags !== null ? artwork.tags : [],
  };

  const ArtworkUploadSchema = Yup.object().shape({
    title: Yup.string()
      .min(2, "Min characters is 2")
      .max(100, "Max characters is 100")
      .required("Required"),
    description: Yup.string().max(1000, "Max characters is 1000"),

    link: Yup.string().max(600, "Max characters is 600"),
    completeDate: Yup.boolean().required("Required"),
    tags: Yup.array(),
    topics: Yup.array()
      .max(7, "Only 7 tags are allowed")
      .of(
        Yup.object().shape({
          id: Yup.string().required(),
          tagText: Yup.string().required(),
        })
      ),
  });

  return (
    <>
      <ToastContainer />
      <div className="settings-wrapper">
        <div id="general-settings" className="settings-section is-active">
          <div className="settings-panel">
            <div className="row settings-form-wrapper Custmsettings-form-wrappers">
              <div className="settings-form col-md-7">
                {isLoading ? (
                  <Loading />
                ) : (
                  <>
                    <div className="title-wrap">
                      <a className="mobile-sidebar-trigger">
                        <i data-feather="menu"></i>
                      </a>
                      <h2>{artworkId ? "Edit Artwork" : "Upload Artwork"}</h2>
                    </div>
                    <div className="">
                      <Formik
                        enableReinitialize={true}
                        initialValues={initialValues}
                        validationSchema={ArtworkUploadSchema}
                        onSubmit={(values, { resetForm }) => {
                          handleSubmitClick(values, resetForm);
                        }}
                      >
                        {({
                          errors,
                          touched,
                          handleSubmit,
                          setFieldValue,
                          values,
                        }) => (
                          <Form className="w-100">
                            <div className="columns is-multiline">
                              <div className="column is-6 pb-0">
                                <div
                                  className={
                                    "field field-group" +
                                    (errors.title && touched.title
                                      ? " is-invalid"
                                      : "")
                                  }
                                >
                                  <label>Title</label>
                                  <div className="control has-icon">
                                    <Field
                                      type="Text"
                                      name="title"
                                      className="input is-fade"
                                    ></Field>
                                    <div className="form-icon">
                                      <svg className="feather feather-user">
                                        <use
                                          href={script + "#feather-user"}
                                        ></use>
                                      </svg>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      "errors-msg" +
                                      (errors.title && touched.title
                                        ? " show-error"
                                        : "")
                                    }
                                  >
                                    {errors.title}
                                  </div>
                                </div>
                              </div>
                              <div className="column is-6 pb-0">
                                <div
                                  className={
                                    "field field-group" +
                                    (errors.link && touched.link
                                      ? " is-invalid"
                                      : "")
                                  }
                                >
                                  <label>Link</label>
                                  <div className="control has-icon">
                                    <Field
                                      type="Text"
                                      name="link"
                                      className="input is-fade"
                                    ></Field>
                                    <div className="form-icon">
                                      <svg className="feather feather-user">
                                        <use
                                          href={script + "#feather-user"}
                                        ></use>
                                      </svg>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      "errors-msg" +
                                      (errors.link && touched.link
                                        ? " show-error"
                                        : "")
                                    }
                                  >
                                    {errors.link}
                                  </div>
                                </div>
                              </div>

                              <div className="column is-12 pb-0">
                                <div
                                  className={
                                    "field field-group" +
                                    (errors.description && touched.description
                                      ? " is-invalid"
                                      : "")
                                  }
                                >
                                  <label>Description</label>
                                  <div className="control has-icon">
                                    <Field
                                      component="textarea"
                                      name="description"
                                      className="input is-fade"
                                      rows={3}
                                    ></Field>
                                    <div className="form-icon">
                                      <svg className="feather feather-message">
                                        <use
                                          href={script + "#feather-message"}
                                        ></use>
                                      </svg>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      "errors-msg" +
                                      (errors.description && touched.description
                                        ? " show-error"
                                        : "")
                                    }
                                  >
                                    {errors.description}
                                  </div>
                                </div>
                              </div>
                              <div className="column is-6 pb-0">
                                <div
                                  className={
                                    "field field-group" +
                                    (errors.tags && touched.tags
                                      ? " is-invalid"
                                      : "")
                                  }
                                >
                                  <label>Tags</label>
                                  <div className="control has-icon">
                                    <Field
                                      name="tags"
                                      // className="input is-fade"
                                      options={tags}
                                      setFieldValue={setFieldValue}
                                      values={values}
                                      component={TagSelect}
                                    />
                                    <div className="form-icon mt-1">
                                      <svg className="feather feather-user">
                                        <use
                                          href={script + "#feather-user"}
                                        ></use>
                                      </svg>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      "errors-msg" +
                                      (errors.tags && touched.tags
                                        ? " show-error"
                                        : "")
                                    }
                                  >
                                    {errors.tags}
                                  </div>
                                </div>
                              </div>
                              <div className="column is-6 pb-0 markAsComplete">
                                <div
                                  className={
                                    "field field-group" +
                                    (errors.completeDate && touched.completeDate
                                      ? " is-invalid"
                                      : "")
                                  }
                                >
                                  <label>Mark as Work In Progress</label>
                                  <div className="control has-icon">
                                    <Field
                                      type="checkbox"
                                      name="completeDate"
                                    />
                                  </div>
                                  <div
                                    className={
                                      "errors-msg" +
                                      (errors.completeDate &&
                                      touched.completeDate
                                        ? " show-error"
                                        : "")
                                    }
                                  >
                                    {errors.completeDate}
                                  </div>
                                </div>
                              </div>

                              <div className="column is-12">
                                <div className="buttons">
                                  {loading ? (
                                    <Loading />
                                  ) : (
                                    <div>
                                      <button
                                        disabled={!artworkId && !mainFile}
                                        className="button is-solid accent-button form-button"
                                        onClick={handleSubmit}
                                      >
                                        Save
                                      </button>
                                      <Link
                                        className="button is-solid form-button dark-grey-button"
                                        to={
                                          artwork && artwork.slug ? `/p/${username}/${artwork.slug}` : `/p/${username}`
                                        }
                                      >
                                        Cancel
                                      </Link>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </>
                )}
              </div>
              {!isLoading && !artworkId && (
                <div className="col-md-5">
                  <div className="row">
                    <div className="col-12">
                      <div className="illustration">
                        <h2 className="text-center mb-5">
                          Upload artwork main image
                        </h2>
                        <div className="user-img-wrapper user--main-img-wrapper custmuser-img-wrapper">
                          {mainFile ? (
                            <ImageThumb image={mainFile} />
                          ) : (
                            <img
                              src={userDefaultImg}
                              // className="user-img"
                              alt="user-img"
                            />
                          )}
                          <div
                            className="upload-img-icon"
                            title="Change Profile Image"
                            onClick={() =>
                              openImgUpload("hidden-main-img-input")
                            }
                          >
                            <svg className="feather feather-camera">
                              <use href={script + "#feather-camera"}></use>
                            </svg>
                          </div>
                        </div>

                        <ImageRequirements width="330" length="240"/>

                        <div className="imageCustmValidation">
                          <div
                            className={
                              "field field-group" +
                              (!mainFile ? " is-invalid" : "")
                            }
                          >
                            {!mainFileStatus && (
                              <div
                                className={
                                  "errors-msg" +
                                  (!mainFile ? " show-error" : "")
                                }
                              >
                                Main image is Required
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-sm">
                        {" "}
                        <div className="illustration">
                          <h2 className="text-center mb-5">
                            Upload artwork other images
                          </h2>
                          <div className="row">
                            <div className="user-img-wrapper other-img-wrapper custmuser-img-wrapper for-multiple-images">
                              {file.length ? (
                                <div className="file-list">
                                  {file2.map((image, index) => (
                                    <div key={index} className="file-list-item">
                                      <span>{image}</span>
                                    </div>
                                  ))}
                                </div>
                              ) : (
                                <div className="not-select-image d-flex flex-column">
                                  <span>
                                    Select images
                                  </span>
                                  <ImageRequirements width="330" length="240"/>
                                </div>
                              )}
                              <div
                                className="upload-img-icon"
                                title="Change Profile Image"
                                onClick={() =>
                                  openImgUpload("hidden-img-input")
                                }
                              >
                                <svg className="feather feather-camera">
                                  <use href={script + "#feather-camera"}></use>
                                </svg>
                              </div>
                            </div>
                          </div>
                          <div className="imageCustmValidation">
                            <div
                              className={
                                "field field-group" +
                                (!file ? " is-invalid" : "")
                              }
                            >
                              {!fileStatus && (
                                <div
                                  className={
                                    "errors-msg" + (!file ? " show-error" : "")
                                  }
                                >
                                  Image is Required
                                </div>
                              )}
                            </div>
                          </div>

                          <div
                            id="upload-box"
                            className="mt-5 d-flex justify-content-center"
                          >
                            <input
                              type="file"
                              onChange={handleChange}
                              id="hidden-img-input"
                              accept="image/png, image/jpeg"
                              multiple
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      id="upload-box"
                      className="mt-5 d-flex justify-content-center"
                    >
                      <input
                        type="file"
                        onChange={handleMainChange}
                        id="hidden-main-img-input"
                        accept="image/png, image/jpeg"
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const ImageThumb = ({ image }) => {
  return (
    <div className="col-12">
      <img src={image} alt={image} className="mb-2" />
    </div>
  );
};

const mapReduxStateToProps = (state) => ({
  username: state.auth.username,
});

export default connect(mapReduxStateToProps, {
  logout,
})(withRouter(AddLibrary));
