import React from "react";
import Footer from "../../Common/Footer";
import Header from "../../Common/Header";
import "./style.css";

const Privacy = () => {
  return (
    <div>
      <Header />
      <Content />
      <Footer />
    </div>
  );
};

const Content = () => {
  return (
    <>
      <section
        id="about"
        className="about-section counter-section pt-80 pb-80 mt-5"
      >
        <div className="container">
          <div className="row flex-column-reverse flex-lg-row">
            <div className="col-lg-12 m-auto">
              <div className="about-area-content remove-before">
                <div className="counter-background">
                  <h3>Privacy Policy</h3>
                </div>
                <p className="mt-4">
                  The Artivist Foundation takes the private nature of your
                  information very seriously. This policy regarding our privacy
                  practices (the <b> "Privacy Policy"</b>) describes how we
                  treat the information we collect when you visit and use
                  Collective (the <b> "Site"</b>) and/or The Artivist
                  Foundation’s other domains, products, advertising products,
                  services, and/or content, including iOS and Android mobile
                  applications or progressive web apps (collectively with the
                  Site, the
                  <b> "Services"</b>). When you use the Services, you also
                  consent to the collection, transfer, manipulation, storage,
                  disclosure and other uses of your information as described in
                  this Privacy Policy; please read it carefully.
                </p>
              </div>
            </div>
            <div className="col-lg-12 m-auto">
              <div className="privacy-area-content remove-before my-5">
                <div className="counter-background">
                  <h4>What This Privacy Policy Covers</h4>
                </div>
                <p className="mt-3">
                  This Privacy Policy covers our treatment of information
                  gathered when you are using or accessing the Services.
                </p>
                <p>
                  This Privacy Policy does not apply to the practices of third
                  parties that we do not own, control, or manage, including but
                  not limited to any third party websites, services,
                  applications, or businesses (<b>“Third Party Services”</b>).
                  While we try to work only with those Third Party Services that
                  share our respect for your privacy, we don't take
                  responsibility for the content or privacy policies of those
                  Third Party Services. We encourage you to carefully review the
                  privacy policies of all Third Party Services you access.
                </p>
                <p>
                  Also: This Privacy Policy doesn’t govern what our users do on
                  their Collective accounts and we aren't responsible for the
                  information collection and use practices of our individual
                  users. One of the great features of Collective’s products is
                  customizability, and users have a lot of flexibility in how
                  their profiles behave. When you visit a profile in our
                  network, that profile may collect more information than we do,
                  and may provide information to third parties that we have no
                  relationship with, including to advertisers.
                </p>
              </div>
            </div>
            <div className="col-lg-12 m-auto">
              <div className="privacy-area-content remove-before mb-5">
                <div className="counter-background">
                  <h4>What We Collect and How We Use It</h4>
                </div>
                <p className="mt-3">
                  <b> Account Information</b>: When you create an account on the
                  Services (an <b> "Account"</b>), we’ll ask you for information
                  such as your username, password, location, and email address (
                  <b>“Account Information”</b>). We may use Account Information,
                  alone or together with other information, to enhance and
                  improve the Services, such as by personalization. We use your
                  email address to verify your Account and to communicate with
                  you, as described in more detail below.
                </p>
                <p>
                  You can keep yourself fairly anonymous on Collective, but
                  remember that your posts, comments, portfolio, and username
                  are all visible to all users by default.
                </p>
                <p>
                  <b>Email Communications with Us</b>: As part of the Services,
                  you may occasionally receive email and other communications
                  from us. Administrative communications relating to your
                  Account (e.g., for purposes of Account recovery or password
                  reset) are considered part of the Services and your Account,
                  which you may not be able to opt-out from receiving. We also
                  may send you other kinds of emails, which you can opt-out of
                  either from your Account Settings page or by using the
                  “Opt-Out” link in the emails themselves.{" "}
                  <b>
                    Note that we will never email you to ask for your password
                    or other Account Information; if you receive such an email,
                    please forward it to us.
                  </b>
                </p>
                <p>
                  <b>Information about Your Accounts on Third Party Services</b>
                  : You may link your Account to certain Third Party Services.
                  In order to do so, you may provide us with your username or
                  other user ID for a Third Party Service, and you may then be
                  required to log into that Service. After you complete this
                  login process, we will receive a token that allows us to
                  access your account on that service so that we can, for
                  example, post your content to that service when you ask us to.
                  We do not receive or store your passwords for your Third Party
                  Service accounts.
                </p>
                <p>
                  <b>User Content</b>: By default, all sharing through the
                  Services is public, and when you provide us with content it is
                  published so that anyone can view it. Content published and
                  shared publicly is accessible to everyone, including search
                  engines, and you may lose any privacy rights you might have
                  regarding that content. In addition, information shared
                  publicly may be copied and shared throughout the Internet,
                  including through actions or features native to the Services,
                  such as other users saving your work to their Inspirations.
                </p>
                <p>
                  <b>Native Actions</b>: The Services allow you to perform
                  native actions that are integral to our products, such as
                  saving a post, commenting on a post, and following a user.
                  Saving, commenting, and following are public actions. We use
                  information about native actions to improve the Services,
                  develop new Services, and, particularly, to personalize your
                  Collective experience. Personalization using this information
                  may include presenting you with new posts relevant to what
                  you’ve saved and providing you with better search results.
                </p>
                <p>
                  <b>Financial Information</b>: We will sometimes collect
                  financial information, such as information related to your
                  payment method (valid credit card number, type, expiration
                  date or other financial information). This happens when you
                  decide to purchase our Service. We do not, however, store that
                  financial information; such information is stored by our
                  payment processor (the <b> "Payment Processor"</b>), and use
                  and storage of that information is governed by the Payment
                  Processor’s applicable terms of service and privacy policy
                  (the Payment Processor may also have other terms, and you are
                  responsible for locating and familiarizing yourself with those
                  terms, as applicable). We do receive certain information from
                  our Payment Processor: (1) a unique token that we connect with
                  your Account to enable you to make further purchases using the
                  information stored by our Payment Processor and (2) in certain
                  cases, the last four digits of the credit card number
                  associated with that token, so that we can prevent fraudulent
                  transactions and identity theft.
                </p>
                <p>
                  <b>Information Related to Use of the Services</b>: We collect
                  information about how people use the Services, including those
                  with an Account. This type of information may be collected in
                  our log files each time you interact with (i.e., make a
                  request to) the Services. We may use internal tools and third
                  party applications and services to collect and analyze this
                  information. Some of this information may also be associated
                  with the Internet Protocol Address (<b>“IP Address”</b>) used
                  to access the Services; some may be connected with your
                  Account; and some may only be collected and used in aggregate
                  form (as a statistical measure that wouldn’t identify you or
                  your Account). We also collect your IP Address when you make a
                  post. We may use this information about how you and others
                  interact with the Services for a number of things generally
                  related to enhancing, improving, protecting, and developing
                  new Services, including but not limited to: providing users
                  with personalized content; improving our search results;
                  identifying trending or popular content; fighting spam,
                  malware, identity theft and generally keeping our users and
                  community safe; and for legal and safety reasons as set forth
                  in “Information Disclosed for Our Protection and the
                  Protection of Others.”
                </p>
                <p>
                  <b>Location Information</b>: In some cases we collect and
                  store information about where you are located. We may ask you
                  to provide information about your location. We may use
                  location information to improve and personalize the Services
                  for you, for example by showing you relevant local content.
                </p>
                <p>
                  <b>Information Related to Your Mobile Device</b>: We may
                  collect and store information related to your mobile device.
                  In some cases, we may receive, generate, or assign your mobile
                  device a unique identifier for the purposes described above in
                  “Information Related to Use of the Services.” We may also ask
                  you to provide your phone number to enable new products and
                  improve the Services, such as by enabling multi-factor
                  authentication for Account login. We will always ask you
                  whether it’s OK for us to collect and store your phone number.
                </p>
                <p>
                  <strong>
                    Information Collected Using Cookies and Web Tags:
                  </strong>
                  <span>
                    {" "}
                    Cookies are text files that may be sent to and saved by your
                    web browser when you access a website; your web browser
                    stores these cookies in a way associated with each website
                    you visit, and you can generally see your cookies through
                    your browser settings, depending on what browser
                    you&rsquo;re using. A web tag is code or a pixel embedded in
                    a web page, or email, that allows Tumblr or a third party to
                    see that you have looked at that page. We use cookies and
                    web tags to enable our servers to recognize your web browser
                    and tell us how and when you use the Services, as described
                    above in &ldquo;Information Related to Use of the
                    Services.&rdquo; Our cookies do not, by themselves, contain
                    information that personally identifies you, and we don't
                    combine the general information collected through cookies
                    with other such information to tell us who you are. However,
                    we do use cookies to identify that you have logged in, and
                    that your web browser has accessed the Services, and we may
                    associate that information with your Account if you have
                    one. We may also store unique or near-unique identifiers
                    that we associate with your Account in our cookies. This
                    information, in turn, is sometimes used as described above
                    in &ldquo;Information Related to Use of the Services.&rdquo;
                    Most web browsers have an option for turning off the cookie
                    feature, which will prevent your browser from accepting new
                    cookies, as well as (depending on the sophistication of your
                    web browser) allowing you to decide on acceptance of each
                    new cookie in a variety of ways. If you disable cookies, you
                    won't be able to log into your Account, and so won&rsquo;t
                    be able to use the vast majority of our Services; as such,
                    we don't recommend disabling your cookies when using the
                    Services. Some services that we use (including Third Party
                    Services), such as{" "}
                  </span>
                  <a
                    href="http://www.google.com/policies/privacy/"
                    target="_blank"
                    rel="noopener"
                  >
                    Google Analytics
                  </a>
                  <span>
                    {" "}
                    and comScore, use web tags and may also place their own
                    cookies on your browser, and individual blogs on our network
                    may contain code that places their own cookies. For more
                    information about Google Analytics, click{" "}
                  </span>
                  <a
                    href="http://www.google.com/policies/privacy/partners"
                    target="_blank"
                    rel="noopener"
                  >
                    here
                  </a>
                  <span>, and for comScore, click </span>
                  <a
                    href="https://www.scorecardresearch.com/privacy.aspx?newlanguage=1"
                    target="_blank"
                    rel="noopener"
                  >
                    here
                  </a>
                  <span>
                    . We also run limited-time studies using web tags, sometimes
                    with third parties, to, for example, measure the
                    effectiveness of our advertising or email. Note that, unless
                    otherwise disclosed, this Privacy Policy covers our use of
                    cookies only and does not cover the use of cookies by third
                    parties.
                  </span>
                </p>
                <p>
                  <strong>Derived Information: </strong>
                  <span>
                    As described above in &ldquo;Native Actions&rdquo; and
                    &ldquo;Information Related to Use of the Services,&rdquo; we
                    analyze your actions on the Services in order to derive or
                    infer characteristics that may be descriptive of your
                    Account (for example, what kinds of artists you follow or
                    what kinds of posts you view, save, or comment on). We use
                    this information for all of the purposes set forth in
                    &ldquo;Information Related to Use of the Services,&rdquo;
                    above.
                  </span>
                </p>
              </div>
            </div>
            <div className="col-lg-12 m-auto">
              <div className="privacy-area-content remove-before mb-5">
                <div className="counter-background">
                  <h4>With Whom Your Information Is Shared</h4>
                </div>
                <p className="mt-3">
                  We never share information we receive from you unless: (a) we
                  have your permission to share that information; (b) we have
                  given you prior notice that the information will be shared,
                  and with whom (such as in this Privacy Policy); or (c) that
                  information is aggregate information or other information that
                  does not identify you.
                </p>
                <p>
                  <strong>
                    Information Shared with the Public Through the Services:
                  </strong>
                  <span>
                    {" "}
                    As noted in &ldquo;User Content&rdquo; and &ldquo;Native
                    Actions,&rdquo; above, by default, content published through
                    the Services and many actions you take on the Services are
                    shared with the public. Because this kind of information can
                    be seen by anyone and may be indexed by search engines, you
                    should be careful about what you choose to disclose publicly
                    and make sure it&rsquo;s information you want to share with
                    everyone.
                  </span>
                </p>
                <p>
                  <strong>Information Shared Between the Services:</strong>
                  <span>
                    {" "}
                    We may, if possible, aggregate information about your use of
                    multiple Services and use that consolidated information to
                    enhance and improve the Services, and to develop new
                    Services.
                  </span>
                </p>
                <p>
                  <strong>
                    Information You Share with Third Party Services:
                  </strong>
                  <span>
                    {" "}
                    You may access Third Party Services through the Services,
                    for example by clicking on externally-pointing links. You
                    may also choose to share information that you provide to us,
                    like posts, with those Third Party Services (for example, by
                    sharing posts to Twitter or Facebook). This Privacy Policy
                    only governs information we collect and you are responsible
                    for reading and understanding those Third Party Services'
                    privacy policies.
                  </span>
                </p>
                <p>
                  <strong>
                    Information Shared with Our Agents in Order to Operate and
                    Improve the Services:
                  </strong>
                  <span>
                    {" "}
                    In some cases, we share information we store (such as IP
                    addresses) with third parties, such as service providers,
                    consultants, and other agents ("
                  </span>
                  <strong>Agents</strong>
                  <span>
                    "), for the purposes of operating, enhancing, and improving
                    the Services, and developing new products and services. For
                    example, we may share information with service providers
                    that help us fight spam, payment providers that process your
                    credit and debit card information, customer chat and email
                    support services that help us communicate with you,
                    analytics providers that help us understand and improve our
                    services, and third party partners that pitch in on our
                    marketing efforts (like identifying a specific marketing
                    target group or improving our marketing campaigns, and by
                    placing ads to market our services), along with third-party
                    consultants who may have access to information in the
                    process of improving our Services and technology. Agents
                    with whom we share such information for these reasons are
                    generally bound by confidentiality obligations and, unless
                    we tell you differently, our agents do not have any right to
                    use information we share with them beyond the scope and
                    duration of what is necessary to assist us.
                  </span>
                </p>
                <p>
                  <strong>
                    Information Disclosed for Our Protection and the Protection
                    of Others:
                  </strong>
                  <span>
                    {" "}
                    We believe in freedom of expression, and, to the extent
                    reasonable, we try to protect our community from baseless
                    legal demands. That said, we also reserve the right to
                    access, preserve, and disclose any information as we
                    reasonably believe is necessary, in our sole discretion, to
                    (i) satisfy any law, regulation, legal process, governmental
                    request, or governmental order, (ii) enforce this Privacy
                    Policy and our{" "}
                  </span>
                  <a
                    href="https://www.tumblr.com/policy/terms-of-service/automattic"
                    target="_blank"
                    rel="noopener"
                  >
                    Terms of Service
                  </a>
                  <span>
                    , including investigation of potential violations hereof,
                    (iii) detect, prevent, or otherwise address fraud, security,
                    trust and safety, or technical issues (including exchanging
                    information with other companies and organizations for the
                    purposes of improving security and preventing fraud, spam,
                    and malware), (iv) respond to user support requests, or (v)
                    protect the rights, property, health or safety of us, our
                    users, any third parties or the public in general, including
                    but not limited to situations involving possible violence,
                    suicide, or self-harm.
                  </span>
                </p>
                <p>
                  <strong>
                    Information We Share with Your Consent or at Your Request:
                  </strong>
                  <span>
                    {" "}
                    If you ask us to release information that we have about your
                    Account, we will do so if reasonable and not unduly
                    burdensome.
                  </span>
                </p>
                <p>
                  <strong>Information Shared with Other Third Parties:</strong>
                  <span>
                    {" "}
                    We may share or disclose non-private information, Aggregate
                    Information, or other non-personally identifying information
                    with people and entities that we do business with.
                  </span>
                </p>
              </div>
            </div>
            <div className="col-lg-12 m-auto">
              <div className="privacy-area-content remove-before mb-5">
                <div className="counter-background">
                  <h4>Security and Retention of Your Information</h4>
                </div>
                <p>
                  <span>
                    Your Account Information is protected by a password for your
                    privacy and security. We may enable additional security
                    features in the future, like multi-factor authentication.
                    You need to prevent unauthorized access to your Account and
                    information by creating a unique, secure, and protected
                    password and limiting access to your computer and browser by
                    signing off after you have finished accessing your Account
                    on the Services.
                  </span>
                </p>
                <p>
                  <span>
                    We seek to protect your information (including your Account
                    Information) to ensure that it is kept private; however, we
                    can't guarantee the security of any information.
                    Unauthorized entry or use, hardware or software failure, and
                    other factors may compromise the security of user
                    information at any time.
                  </span>
                </p>
                <p>
                  <em>Collective</em>
                  <span>
                    {" "}
                    will retain your information only for as long as is
                    necessary for the purposes set out in this Privacy Policy,
                    for as long as your{" "}
                  </span>
                  <em>Collective</em>
                  <span>
                    {" "}
                    account is active or as needed to provide you with the
                    Services. Please note that this applies to information in{" "}
                  </span>
                  <em>Collective</em>
                  <span> group chats as well. If you no longer want </span>
                  <em>Collective</em>
                  <span>
                    {" "}
                    to use your information to provide you with the Services,
                    you may close your account and{" "}
                  </span>
                  <em>Collective</em>
                  <span>
                    {" "}
                    will delete the information it holds about you unless we
                    need to retain and use your information to comply with our
                    legal obligations, to resolve disputes, to enforce our
                    agreements or as otherwise permitted by law.
                  </span>
                </p>
              </div>
            </div>
            <div className="col-lg-12 m-auto">
              <div className="privacy-area-content remove-before mb-5">
                <div className="counter-background">
                  <h4>What Information You Can Access</h4>
                </div>
                <p>
                  <span>
                    If you are a registered user, you can access most
                    information associated with your Account by logging into the
                    Services and checking your Account Settings page. Registered
                    and unregistered users can access and delete cookies through
                    their web browser settings.
                  </span>
                </p>
              </div>
            </div>
            <div className="col-lg-12 m-auto">
              <div className="privacy-area-content remove-before mb-5">
                <div className="counter-background">
                  <h4>
                    How to Delete Your Account and What Happens When You Delete
                    Your Account
                  </h4>
                </div>
                <p>
                  <span>
                    If you want to delete your Account, you can do so from your
                    Account Settings. Deleting your Account may not fully remove
                    the content you have published from our systems, as caching
                    of, backups of, copies of, or references to your Account
                    Information may not be immediately removed. In addition,
                    given the nature of sharing on the Services, some of the
                    public activity on your Account prior to deletion may remain
                    stored on our servers and accessible to the public.
                  </span>
                </p>
              </div>
            </div>
            <div className="col-lg-12 m-auto">
              <div className="privacy-area-content remove-before mb-5">
                <div className="counter-background">
                  <h4>Changes to This Privacy Policy</h4>
                </div>
                <p>
                  <span>
                    We may amend this Privacy Policy from time to time, using
                    the process for modifications set forth in our Terms of
                    Service. Use of information we collect is subject to the
                    Privacy Policy in effect at the time such information is
                    collected.
                  </span>
                </p>
                <p>
                  <span>
                    If you have any questions or concerns regarding privacy
                    using the Services, send us a detailed message at
                    info@artivistfoundation.org.
                  </span>
                </p>
              </div>
            </div>
            <div className="col-lg-12 m-auto">
              <div className="privacy-area-content remove-before mb-5">
                <div className="counter-background">
                  <h4>Supplemental Privacy Notice for California Residents</h4>
                </div>
                <p>
                  <span>
                    The Notice supplements the information in our privacy policy
                    above for California residents.
                  </span>
                </p>
              </div>
            </div>
            <div className="col-lg-12 m-auto">
              <div className="privacy-area-content remove-before mb-5">
                <div className="counter-background">
                  <h4>Summary of Information We Collect</h4>
                </div>
                <p>
                  <span>
                    If you’re a California resident, California law requires us
                    to provide you with some additional information about how we
                    collect, use, and disclose your “personal information,” as
                    defined in the California Consumer Privacy Act ("CCPA").
                  </span>
                </p>
                <p>
                  <span>
                    Throughout our privacy policy, we describe the specific
                    pieces of personal information we collect, the sources of
                    that information, and how we share it. Under the CCPA, we
                    also have to provide you with the "categories" of personal
                    information we collect and disclose for “business purposes”
                    (as those terms are defined by applicable law). Those
                    categories are identifiers (such as username and email
                    address); commercial information (such as transaction data);
                    financial data (such as credit card and other financial
                    account information); internet or other network or device
                    activity (such as unique identifiers, browsing history or
                    other usage data); geolocation information (general
                    location); inference data about you; legally protected
                    classifications (such as gender); physical characteristics
                    or description (such as when you voluntarily submit a
                    photo); or other information that identifies or can be
                    reasonably associated with you.
                  </span>
                </p>
                <p>
                  <span>
                    We use these categories of personal information consistent
                    with the various business and commercial purposes we
                    describe in this privacy policy. Check out “What We Collect
                    and How We Use It” to learn more. We share this information
                    with the categories of third parties described above in
                    “With Whom Your Information Is Shared."
                  </span>
                </p>
                <p>
                  <span>
                    We may also use the categories of personal information for
                    compliance with applicable laws and regulations. We may
                    aggregate the information we collect or de-identify it in
                    order to limit or prevent the identification of any
                    particular user or device.
                  </span>
                </p>
              </div>
            </div>
            <div className="col-lg-12 m-auto">
              <div className="privacy-area-content remove-before mb-5">
                <div className="counter-background">
                  <h4>California Privacy Rights</h4>
                </div>
                <p>
                  <span>
                    If you’re a California resident, the CCPA provides you with
                    rights regarding your personal information.
                  </span>
                </p>
                <p>
                  <strong>Access:</strong>
                  <span>
                    {" "}
                    The CCPA gives you the right to request a copy of certain
                    information we hold about you, as well as a summary of: the
                    categories of personal information we have collected or
                    disclosed about you in the last twelve months, the
                    categories of sources of such information, the business or
                    commercial purpose for collecting or sharing that
                    information, and the categories of third parties with whom
                    we shared that information. As described in the section
                    &lsquo;What Information You Can Access,&rsquo; you can
                    access much of your information by logging in to your{" "}
                  </span>
                  <em>Collective</em>
                  <span> account.&nbsp;</span>
                </p>
                <p>
                  <strong>Delete:</strong>
                  <span>
                    {" "}
                    The CCPA also gives you the right to delete certain
                    information we have about you. You can delete much of your
                    information by logging in to your account. If you cannot do
                    this, or you do not have a{" "}
                  </span>
                  <em>Collective</em>
                  <span> account, please submit a support ticket </span>
                  <a
                    href="https://www.tumblr.com/support"
                    target="_blank"
                    rel="noopener"
                  >
                    here
                  </a>
                  <span>
                    , or email us at info@artivistfoundation.org. For more
                    information on deleting your account or leaving{" "}
                  </span>
                  <em>Collective</em>
                  <span>
                    , please refer to the section &lsquo;How to Delete Your
                    Account.&rsquo;
                  </span>
                </p>
                <p>
                  <strong>Financial Incentives:</strong>
                  <span>
                    {" "}
                    You may have the right to receive information about the
                    financial incentives that we offer to you (if any).
                  </span>
                </p>
                <p>
                  <strong>Consequences of Exercising Rights:</strong>
                  <span>
                    {" "}
                    You also have the right not to be discriminated against (as
                    provided for in applicable law) for exercising your rights.
                  </span>
                </p>
                <p>
                  <span>
                    Certain information may be exempt from these rights under
                    applicable law. For example, we need certain types of
                    information so that we can provide you with the Services. If
                    you ask us to delete them, you may no longer be able to
                    access or use the Services.
                  </span>
                </p>
                <p>
                  <span>
                    Before we can satisfy your request, you&rsquo;ll need to
                    give us enough information so that we can be certain of your
                    identity. To do that, you&rsquo;ll need to log in to your
                    account or give us certain information about you and/or your
                    use of the Services. You can also designate an authorized
                    agent to make a request on your behalf. To do so, you have
                    to give us written authorization for the agent to act on
                    your behalf. You&rsquo;ll still need to verify your identity
                    directly with us.
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Privacy;
