import { CREDENTIALS } from "../../Configs/config";
import * as handler from "./handler";
import { PATH_ACCESS_TOKEN, PATH_REGISTER, PATH_LOGIN, PATH_USER_EMAIL_CONFIRMATION, PATH_FORGOT_PASSWORD, PATH_RESET_PASSWORD } from "./path";



const getAccessToken = (shouldRefresh) => {
  if (!shouldRefresh && localStorage.getItem("dataAccessToken")) return;
  return handler
    .post(
      PATH_ACCESS_TOKEN,
      {
        email: CREDENTIALS.EMAIL,
        password: CREDENTIALS.PASSWORD,
      },
      true
    )
    .then((res) => {
      localStorage.setItem("dataAccessToken", res.data.token);
      return res.data;
    });
};

const doUserEmailConfirm = (queryString) => {
  return handler
    .getAuth(
      PATH_USER_EMAIL_CONFIRMATION + queryString,
      {},
      true
    )
    .then((res) => {
      return res;
    });
};

const doUserRegister = (formData) => {
  return handler
    .authPost(
      PATH_REGISTER,
      {
        userName: formData.username,
        FirstName: formData.firstname,
        LastName: formData.lastname,
        emailConfirmationLink: formData.emailConfirmationLink,
        email: formData.email,
        password: formData.password,
        confirmPassword: formData.passwordConfirmation,
        country: formData.country,
      },
      true
    )
    .then((res) => {
      return res;
    });
};

const doUserLogin = (formData) => {
  return handler
    .authPost(
      PATH_LOGIN,
      {
        userName: formData.username,
        password: formData.password,
      },
      true
    )
    .then((res) => {
      return res;
    });
};

const doForgotPassword = (formData) => {
  return handler
  .authPost(
    PATH_FORGOT_PASSWORD,
    {
      email: formData.email,
      resetPasswordLink: formData.resetPasswordLink,
    },
    true
  )
  .then((res) => {
    return res;
  });
}

const doRestPassword = (queryString) => {
  return handler
    .getAuth(
      PATH_RESET_PASSWORD + queryString,
      {},
      true
    )
    .then((res) => {
      return res;
    });
};

export default {
  getAccessToken, doUserRegister, doUserLogin, doUserEmailConfirm, doForgotPassword, doRestPassword
};
