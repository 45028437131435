import React, {useEffect, useState} from 'react'
import Api from '../../Helpers/Api';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Modal} from 'react-bootstrap';
import {getImageData, modifyData} from '.';
import VisibilitySensor from "react-visibility-sensor";
import Loading from "../Common/Loading";

const Teams = () => {
  const [teams, setTeams] = useState([]);
  const [show, setShow] = useState(null);
  const [visibility, setVisibility] = useState(false)
  const [showVisibility, setShowVisibility] = useState(false)

  const handleClose = () => {
    setShow(null);
  };

  const handleShow = (e, index) => {
    setShow(index);
  };

  const getTeamsData = async () => {
    const teams = await Api.getTeams();
    const teamsImageIds = teams.map((data) => data.image);
    const teamsImageData = await getImageData(teamsImageIds)
    const teamsData = modifyData(teams, teamsImageData, 'imageUrl', 'image')
      setTeams(teamsData);
  }

  useEffect(() => {
    if (!showVisibility && visibility) {
      setShowVisibility(true)
    }
  }, [visibility])

  useEffect(() => {
    if (showVisibility) {
      getTeamsData()
    }
  }, [showVisibility])

  return (
    <>
      <VisibilitySensor
        partialVisibility
        onChange={(isVisible) => {
          setVisibility(isVisible)
        }}
      >
      {teams?.length <= 0
        ? <Loading />
        :
          <section id="team" className="team-section pt-80 pb-80">
            <div className="container">
              <div className="section-title">
                <h2>Our Team</h2>
                <p></p>
              </div>
              {teams?.length > 0 && (
                <Swiper
                  className="team-slider owl-carousel owl-theme"
                  spaceBetween={30}
                  slidesPerView={3}
                  breakpoints={{
                    "0": {
                      "slidesPerView": 1
                    },
                    "768": {
                      "slidesPerView": 2
                    },
                    "1024": {
                      "slidesPerView": 3
                    }
                  }}
                  pagination={{clickable: true}}
                >
                  {teams?.map((item, index) => (
                    <SwiperSlide key={index}>
                      <div className="team-item">
                        <div className="image">
                          <img
                            src={item.imageUrl}
                            alt=""
                            className="responsive"
                            onClick={(e) => handleShow(e, item.id)}
                            role="button"
                          />
                          <ul className="social">
                            {item.linkedin_url && (
                              <li>
                                <a
                                  href={item.linkedin_url}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <i className="flaticon-linkedin"></i>
                                </a>
                              </li>
                            )}

                            {item.mailto_link && (
                              <li>
                                <a
                                  href={`mailto:${item.mailto_link}`}
                                  rel="noreferrer"
                                >
                                  <i className="fa fa-envelope"></i>
                                </a>
                              </li>
                            )}
                          </ul>
                          <div className="content">
                            <h3>{item.name}</h3>
                            <span>{item.title}</span>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              )}
              {teams?.map((item, index) => (
                <Modal
                  className="team-modal"
                  id={item.id}
                  show={show === item.id}
                  onHide={handleClose}
                  size="lg"
                  key={index}
                >
                  <button type="button" className="close" onClick={handleClose}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <div className="bio-modal-content">
                    <div className="bio-name-wrapper ">
                      <div className="default-img in-modal">
                        <img
                          src={item.imageUrl}
                          alt="img"
                          className="img-fluid"
                        />
                      </div>
                      <div className="section-title pos-rel ">
                        <div className="section-text pos-rel">
                          <h2>{item.name}</h2>
                          <h6 className="f-500  letter-spacing pink-color">
                            {item.title}
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="bio-desc">
                      <p>{item.description}</p>
                    </div>
                    <div className="social">
                      {item.linkedin_url && (
                        <a href={item.linkedin_url} target="blank">
                          <i className="flaticon-linkedin"></i>
                        </a>
                      )}
                      {item.mailto_link && (
                        <a href={`mailto:${item.mailto_link}`}>
                          <i className="fa fa-envelope"></i>
                        </a>
                      )}
                    </div>
                  </div>
                </Modal>
              ))}
            </div>
          </section>
      }
      </VisibilitySensor>
    </>

  )
}

export default Teams
