import React, { useEffect, useState } from "react";
import script from "../../../../Assets/img/icons/feather.svg";
import userDefaultImg from "../../../../Assets/img/user-defoult-img.jpg";
import Api from "../../../../Helpers/Api";
import JwtDecode from "../../../Common/JwtDecode";
import { Link, useParams, withRouter, useHistory } from "react-router-dom";
import Moment from "react-moment";
import { connect } from "react-redux";
import "./style.scss";
import {
  CONTACT_ART_FOUNDATION_URL,
  URL_EXPLORE_PAGE,
  URL_Feed_PAGE,
  URL_PRIVACY_PAGE,
  ART_FOUNDATION_URL,
  URL_RESOURCES_PAGE,
  URL_EVENTS_PAGE,
} from "../../../../Helpers/urls";
import Modal from "react-bootstrap/Modal";
import Loading from "../../../Common/Loading";

const SideMenu = (props) => {
  const loggedUsername = localStorage.getItem("username") || props.username;

  const UserSideMenu = [
    {
      activeRoute: `/p/${loggedUsername}`,
      to: `/p/${loggedUsername}`,
      name: 'Portfolio',
      icon:"feather feather-portfolio",
      href:'#feather-portfolio'
    },
    {
      activeRoute: URL_Feed_PAGE,
      to: URL_Feed_PAGE,
      name: 'Feed',
      icon:'feather feather-grid',
      href:'#feather-grid'
    }, {
      activeRoute: URL_EXPLORE_PAGE,
      to: URL_EXPLORE_PAGE,
      name: 'Explore',
      icon:'feather feather-explore',
      href:'#feather-explore'
    }, {
      activeRoute: URL_EVENTS_PAGE,
      to: URL_EVENTS_PAGE,
      name: 'Events',
      icon:'feather feather-calendar',
      href:'#feather-calendar'
    }, {
      activeRoute: URL_RESOURCES_PAGE,
      to: URL_RESOURCES_PAGE,
      name: 'Resources',
      icon:'feather feather-book-open',
      href:'#feather-book-open'
    }
  ]

  var token = JwtDecode();
  const { username } = useParams();
  const [file, setFile] = useState("");
  const [loading, setLoading] = useState(false);
  const [isFollow, setIsFollow] = useState(false);
  const [followLoading, setFollowLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [UserData, setSideMenu] = useState({
    firstName: "",
    lastName: "",
    city: "",
    stateRegion: "",
    country: "",
    isFollow: true,
  });
  const [isCancelled, setIsCancelled] = useState(false);

  const [showModalFollow, setShowModalFollow] = useState(false);
  const [activeTab, setActiveTab] = useState('followers');

  const [loadingFollowers, setLoadingFollowers] = useState(false);
  const [followers, setFollowers] = useState([]);

  const [loadingFollowings, setLoadingFollowings] = useState(false);
  const [followings, setFollowings] = useState([]);

  const history = useHistory();

  const getSideMenu = async (props) => {
    setLoading(true);
    try {
      const result = await Api.getProfileByName(username);
      if (result != "false") {
        result.isFollow = false;
        if (props.userID) props.userID(result.profile.userId);
        getAvatarThumb(result.profile.userId);
        setSideMenu(result.profile);
        setIsFollow(result.isFollowing);
        setLoading(false);
      } else {
        props.userID(0);
      }
    } catch (error) {
      console.error("errorsss", error);
    }
  };

  const getAvatarThumb = async (_id) => {
    const result = await Api.getAvatarThumb(_id);
    setFile(result ? result : userDefaultImg);
  };

  const getSideMenuData = async (props) => {
    await Api.getAccessToken();
    Promise.all([username && getSideMenu(props)]);
  };

  useEffect(() => {
    getSideMenuData(props);
  }, [username]);

  const {
    firstName,
    lastName,
    city,
    stateRegion,
    country,
    mission,
    registeredDate,
  } = UserData;
  const [windowSize, setWindowSize] = useState(true);

  useEffect(() => {
    function handleResize() {
      setWindowSize(window.innerWidth < 768);
    }
    !isCancelled && handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      setIsCancelled(true);
    }
  },[]);

  const clickMenu = () => {
    props.onchange(false);
  };

  const toggleFollow = async () => {
    setFollowLoading(true);
    try {
      let values = {
        followerId: token.sub,
        mainUserId: UserData.userId,
      };
      var result;
      if (!isFollow) {
        result = await Api.userFollow(values);
      } else {
        result = await Api.userUnFollow(values);
      }

      setFollowLoading(false);

      if (result.message === "Success") {
        setIsFollow(!isFollow);
      }
    } catch (error) {
      setFollowLoading(false);
      console.error("error", error);
    }
  };

  const activeRoute = (routeName) => {
    return props.location.pathname.indexOf(routeName) > -1 ? "is-active" : "";
  };

  const getFollowers = async (id) => {
    if (!followers.length) {
      setLoadingFollowers(true);
      try {
        const result = await Api.getFollowers(id);
        if (result && result.length) {
          setFollowers(result)
        }
        setLoadingFollowers(false);
      } catch {
        setLoadingFollowers(false);
      }
    }
  };

  const getFollowings = async (id) => {
    if (!followings.length) {
      setLoadingFollowings(true);
      try {
        const result = await Api.getFollowings(id);
        if (result && result.length) {
          setFollowings(result)
        }
        setLoadingFollowings(false);
      } catch {
        setLoadingFollowings(false);
      }
    }
  };

  const openFollowModal = (tab) => {
    if (!UserData) {
      return
    }

    setShowModalFollow(true);
    changeTab(tab)
  };

  const changeTab = async (tab) => {
    if (tab === 'followings') {
      setActiveTab('followings');
      await getFollowings(UserData.userId);
    }

    if (tab === 'followers') {
      setActiveTab('followers');
      await getFollowers(UserData.userId);
    }
  };

  const closeModal = () => {
    setShowModalFollow(false);
  };

  useEffect(() => {
    return history.listen(() => {
      setFollowers([]);
      setFollowings([]);
    })
  }, [history]);

  return (
    <div
      className={
        (!props.toggle && windowSize ? "" : "is-active") + " stories-sidebar"
      }
    >
      <Modal size="md" show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <h4 className="user-name">{firstName + " " + lastName}</h4>
        </Modal.Header>
        <div className="modal-user-avatar avatar-wrap">
          <img src={file || userDefaultImg} alt="" />
        </div>
        <Modal.Body>
          <p className="state-city-information">
            {city && <span>{city}</span>}
            {stateRegion && <span>{stateRegion}</span>}
            {country && <span>{country}</span>}
          </p>
          <p>
            Member since <Moment date={registeredDate} format="YYYY" />
          </p>
          <p>{mission}</p>
        </Modal.Body>
      </Modal>
      <div
        className={
          !loading && username
            ? "stories-sidebar-inner"
            : "stories-sidebar-inner hide-top-section"
        }
      >
        {!loading && username && (
          <div className="user-block">
            <a className="close-stories-sidebar is-hidden" onClick={clickMenu}>
              <svg className="feather feather-x">
                <use href={script + "#feather-x"}></use>
              </svg>
            </a>
            <div className="avatar-wrap">
              {followLoading && <div className="disabled-follow-btn"></div>}
              <img
                src={file || userDefaultImg}
                alt=""
                onClick={() => setShowModal(true)}
              />
              {loggedUsername != username ? (
                !isFollow ? (
                  <div className="badge notFollowing" onClick={toggleFollow}>
                    {followLoading && (
                      <div className="loading-follow-btn"></div>
                    )}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-plus"
                    >
                      <line x1="12" y1="5" x2="12" y2="19"></line>
                      <line x1="5" y1="12" x2="19" y2="12"></line>
                    </svg>
                  </div>
                ) : (
                  <div className="badge" onClick={toggleFollow}>
                    {followLoading && (
                      <div className="loading-follow-btn"></div>
                    )}
                    <svg className="feather feather-check-2">
                      <use href={script + "#feather-check-2"}></use>
                    </svg>
                  </div>
                )
              ) : (
                ""
              )}
            </div>
            <div>
              <h4 onClick={() => setShowModal(true)} className="user-name">
                {firstName + " " + lastName}
              </h4>
              <p className="state-city-information">
                {city && <span>{city}</span>}
                {stateRegion && <span>{stateRegion}</span>}
                {country && <span>{country}</span>}
              </p>
              <p>
                Member since <Moment date={registeredDate} format="YYYY" />
              </p>
              <p className="line-clamp">{mission}</p>
              <div className="stats">
                <span onClick={() => openFollowModal('followers')}>Followers</span>
                <span onClick={() => openFollowModal('followings')}>Following</span>
              </div>
            </div>
          </div>
        )}

        <div
          className={([props.userid ? "isActive" : ""], "user-menu")}
          style={{ top: username ? "200px" : "0" }}
        >
          <div className="user-menu-inner has-slimscroll">
            <div className="menu-block">
              <ul>
                {
                  UserSideMenu.map(menu => (
                    <>
                      <li className={activeRoute(menu.activeRoute)}>
                        <Link to={menu.to}>
                          <i data-feather="grid"></i>
                          <svg className={menu.icon}>
                            <use href={`${script}${menu.href}`}></use>
                          </svg>
                          <span>{menu.name}</span>
                        </Link>
                      </li>
                    </>
                  ))
                }
              </ul>
              <div className="separator"></div>
              <div className="menu-block">
                <ul>
                  <li className={activeRoute(ART_FOUNDATION_URL)}>
                    <a href={ART_FOUNDATION_URL} target="_blank">
                      <svg className="feather feather-AF">
                        <use href={script + "#feather-AF"}></use>
                      </svg>
                      <span>Artivist Foundation</span>
                    </a>
                  </li>
                  <li className={activeRoute(URL_PRIVACY_PAGE)}>
                    <Link to={URL_PRIVACY_PAGE}>
                      <svg className="feather feather-lock">
                        <use href={script + "#feather-lock"}></use>
                      </svg>
                      <span>Privacy</span>
                    </Link>
                  </li>
                  <li className={activeRoute(CONTACT_ART_FOUNDATION_URL)}>
                    <a href={CONTACT_ART_FOUNDATION_URL}>
                      <svg className="feather feather-life-buoy">
                        <use href={script + "#feather-life-buoy"}></use>
                      </svg>
                      <span>Help</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="side-menu-footer">
          <p>
            © Copyright {new Date().getFullYear()} Artivist Foundation, Inc. EIN
            #85-2123373, All Rights Reserved. <br />
            Powered by{" "}
            <a href="https://www.flexiblesites.com/" target="_blank">
              FlexibleSites
            </a>
          </p>
        </div>
      </div>

      <Modal className="follow-modal" size="md" show={showModalFollow} onHide={() => setShowModalFollow(false)}>
        <Modal.Header closeButton>
          <h4
            className={'tabs-name' + (activeTab === 'followers' ? ' active' : '')}
            onClick={() => changeTab('followers')}
          >Followers</h4>
          <h4
            className={'tabs-name' + (activeTab === 'followings' ? ' active' : '')}
            onClick={() => changeTab('followings')}
          >Following</h4>
        </Modal.Header>
        <Modal.Body>
          {
            activeTab === 'followers' && (
              <div>
                {
                  loadingFollowers ? <Loading /> :
                    <FollowList tab='Followers' data={followers} closeModal={closeModal} />
                }
              </div>
            )
          }
          {
            activeTab === 'followings' && (
              <div>
                {
                  loadingFollowings ? <Loading /> :
                    <FollowList tab="Followings" data={followings} closeModal={closeModal} />
                }
              </div>
            )
          }
        </Modal.Body>
      </Modal>
    </div>
  );
};

const FollowList = (props) => {

  return (
    <div className="follow-lists">
      {
        props.data.length === 0
          ? <div className="not-found-div">None found</div>
          : <div>
            {
              props.data.map((item, index) => (
                <div className="follow-list-item" key={index}>
                  <Link to={'/p/' + item.username} className='story-avatar' onClick={props.closeModal}>
                    {
                      item.image
                        ? <img className="avatar" src={"data:image/jpeg;base64," + item.image} alt="" />
                        : <img className="avatar" src={userDefaultImg} alt="" />
                    }
                  </Link>
                  <div className="meta">
                    <Link to={'/p/' + item.username} onClick={props.closeModal}>
                      <span>{item.username}</span>
                    </Link>
                  </div>
                </div>
              ))
            }
          </div>
      }
    </div>
  )
};

const mapReduxStateToProps = (state) => ({
  username: state.auth.username,
});

export default connect(mapReduxStateToProps, null)(withRouter(SideMenu));
