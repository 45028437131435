import React, { useEffect, useState } from "react";
import Logo from "../../../Assets/img/logo-main.png";
import script from "../../../Assets/img/icons/feather.svg";
import Dropdown from "../../Common/HeaderDropdown";
import { Link } from "react-router-dom";
import userDefaultImg from "../../../Assets/img/user-defoult-img.jpg";
import { useDispatch, useSelector } from "react-redux";
import Api from "../../../Helpers/Api";
import Moment from "react-moment"
import { URL_ADD_LIBRARY_PAGE } from "../../../Helpers/urls";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "./../../../Redux/actions/auth.action";
import { searchChangeValue, handleSearch } from "./../../../Redux/actions/search.action";
import {
  URL_EXPLORE_PAGE
} from "../../../Helpers/urls";
import Chat from "../Chat";
import "../../Common/Chat/style.scss";
import thirdParty from "../../../Assets/img/third-party-cookies.png";

const HeaderMenu = (props) => {
  let UserId = localStorage.getItem("userId"); //get userid
  let username = localStorage.getItem("username"); //get userid
  const [file, setFile] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [indicator, setIndicator] = useState(false);
  const [chat, setChat] = useState(false);
  const [notificationsError, setNotificationsError] = useState(undefined);
  const [accountDropdown, setAccountDropdown] = useState(undefined);
  const [showModal, setShowModal] = useState(false)
  const [isCancelled, setIsCancelled] = useState(false);
  const dispatch = useDispatch();

  const getAvatarThumb = async () => {
    const result = await Api.getAvatarThumb(UserId);
      setFile(result ? result : null);
  };

  const getUserNotifications = async () => {
    try {
      let notification_parms = {
        "userId": UserId,
        "startAt": 0,
        "limit": 10
      };
      const result = await Api.getUserNotification(notification_parms);
      if (result.message === "Success" && result.statusCode === 200) {
        if(result.model) {
          result.model.forEach((val) => {
            if (!val.isRead) {
              setIndicator(true);
            }
          });
          setNotifications(result.model)
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getMenuData = async () => {
    await Api.getAccessToken();
    Promise.all([getAvatarThumb(), getUserNotifications()]);
  };
  useEffect(() => {
    !isCancelled && getMenuData();

    return () => {
      setIsCancelled(true);
    }
  }, []);

  const handleSearchChange = (e) => {
    const {value} = e.target;
    props.searchChangeValue(value)
    localStorage.setItem("searchValue", value)
  }

  const handleSearchKeyPress = (ev) => {
    if (ev.key === 'Enter') {
      if(props.searchValue.length > 2 && props.location.pathname !== "explore")
        props.history.push("/explore")
      props.handleSearch(true)
    }
    else
      props.handleSearch(false)
  };

  const checkOpen = () => {
    notifications.forEach((val) => {
      if (!val.isRead) {
        Api.readUserNotification(val.id).then(() => {
          setIndicator(false);
        });

        setTimeout(() => {
          getUserNotifications();
        }, 3000)
      }
    });
  };

  const openChat = (e) => {
    e.preventDefault();
    setChat(true)

    if (!localStorage.getItem('56867chat_state')) {
      setShowModal(true)
    } else {
      setShowModal(false)
    }
  }

  return (
    <div>
      <div
        id="main-navbar"
        className="navbar-main is-inline-flex is-transparent no-shadow is-hidden-mobile"
      >
        <div className="container is-fluid">
          <div className="navbar-brand">
            <Link to={URL_EXPLORE_PAGE} className="navbar-item">
              <img className="logo" src={Logo} width="112" height="28" alt="" />
            </Link>
          </div>
          <div className="navbar-menu">
            <div className="navbar-start">
              {/*Custom DropDown component*/}
              <Dropdown
                img={file}
                icon={"#feather-bell"}
                isAccountDropdown={false}
                isNotification={true}
                indicator={indicator}
                checkOpen={checkOpen}
                options={
                  <div>
                    <div className="nav-drop-header">
                      <span>Notifications</span>
                      <a href="#">
                        <i data-feather="bell"></i>
                      </a>
                    </div>
                    <div className="nav-drop-body is-notifications">
                      {
                        notifications.map((item, index) => (
                          <div className={'media' + (!item.isRead ? ' not-read' : '')} key={index}>

                            <div className="media-content">
                              <span>
                                {item.text}.
                              </span>
                              <span className="time"><Moment fromNow>{item.generatedDate}</Moment></span>
                            </div>
                            <div className="media-right">
                              <div className="added-icon">
                                <i data-feather="message-square"></i>
                              </div>
                            </div>
                          </div>
                        ))
                      }
                      {notifications.length == 0 &&
                        <div className="media">
                          <div className="media-content">
                            <span>
                              <a href="#">No data found</a>
                            </span>
                          </div>
                        </div>
                      }
                     </div>
                  </div>
                }
              />
              <div className="navbar-item is-icon open-chat">
                <Link to={`/p/${props.username || username}?tab=inspirations`} className="icon-link">
                  <i className="fa fa-star-o"></i>
                  <span className="indicator"></span>
                </Link>
              </div>
              <div className="navbar-item is-icon open-chat mobile-search">
                <a className="icon-link">
                  <svg className="feather feather-explore">
                    <use href={script + "#feather-explore"}></use>
                  </svg>
                  <span className="indicator"></span>
                </a>
              </div>
              <div className="navbar-item is-icon open-chat">
                <a href="#" onClick={openChat} className="icon-link">
                  <svg className="feather feather-chat">
                    <use href={script + "#feather-chat"}></use>
                  </svg>
                  <span className="indicator"></span>
                </a>
              </div>
            </div>

            <div className="navbar-end">
              <div className="navbar-item desktop-search">
                <div id="global-search" className="control">
                  <input
                    id="tipue_drop_input"
                    className="input is-rounded"
                    type="text"
                    placeholder="Search"
                    onChange={handleSearchChange}
                    value={props.searchValue}
                    onKeyPress={handleSearchKeyPress}
                    required
                  />
                  <span id="clear-search" className="reset-search">
                    <i data-feather="x"></i>
                  </span>
                  <span className="search-icon">
                    <i data-feather="search"></i>
                  </span>
                  <span className="drop-icon">
                    <i data-feather="more-vertical"></i>
                  </span>
                </div>
              </div>

              {/*Custom DropDown component*/}
              <Dropdown
                img={file || userDefaultImg}
                isAccountDropdown={true}
                onChange={(v) => setAccountDropdown(v)}
                options={
                  <div className="inner">
                    <div className="nav-drop-body account-items">
                      <Link to={URL_ADD_LIBRARY_PAGE} className="account-item">
                        <div className="media">
                          <div className="icon-wrap">
                            <svg className="feather feather-portfolio">
                              <use href={script + "#feather-portfolio"}></use>
                            </svg>
                          </div>
                          <div className="media-content">
                            <h3>Add to My Portfolio</h3>
                            <small>Add to My Portfolio</small>
                          </div>
                        </div>
                      </Link>
                      <Link to="/event/add" className="account-item">
                        <div className="media">
                          <div className="icon-wrap">
                            <svg className="feather feather-plus-circle">
                              <use href={script + "#feather-plus-circle"}></use>
                            </svg>
                          </div>
                          <div className="media-content">
                            <h3>Add new event</h3>
                            <small>Add new event</small>
                          </div>
                        </div>
                      </Link>

                      <Link to="/user-profile" className="account-item">
                        <div className="media">
                          <div className="icon-wrap">
                            <svg className="feather feather-settings">
                              <use href={script + "#feather-settings"}></use>
                            </svg>
                          </div>
                          <div className="media-content">
                            <h3>Settings</h3>
                            <small>Update Account</small>
                          </div>
                        </div>
                      </Link>

                      <Link to={"/login"} className="account-item">
                        <div className="media">
                          <div className="icon-wrap">
                            <svg className="feather feather-power">
                              <use href={script + "#feather-power"}></use>
                            </svg>
                          </div>
                          <div
                            className="media-content"
                            onClick={() => dispatch(logout())}
                          >
                            <h3>Log out</h3>
                            <small>Log out from your account.</small>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                }
              />

              <div className="navbar-item is-plus-menu is-hidden">
                <a
                  id="plus-menu"
                  className="button action-button is-solid primary-button raised"
                >
                  <i data-feather="plus"></i>
                </a>
                <div className="plus-drop">
                  <div className="drop-content">
                    <a>
                      <i data-feather="book"></i>
                      <div className="meta">
                        <span>Guides</span>
                        <span>Learn everything fast</span>
                      </div>
                    </a>
                    <a>
                      <i data-feather="help-circle"></i>
                      <div className="meta">
                        <span>FAQ</span>
                        <span>Most asked questions</span>
                      </div>
                    </a>
                    <a>
                      <i data-feather="life-buoy"></i>
                      <div className="meta">
                        <span>Assistance</span>
                        <span>Get in touch with support</span>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={"chat-wrapper" + (chat ? ' is-active': '')}>
        <div className="chat-header">
          <div className="chat-close" onClick={() => {setChat(false)}}>
            Close chat
            <svg className="feather feather-close">
              <use href={script + "#feather-close"}></use>
            </svg>
          </div>
        </div>
        <Chat />
      </div>

      <div className={'message-modal' + (showModal ? ' show-modal': '')}>
        <div className="message-modal-wrapper">
          <div className="message-modal-header">
            <img src={thirdParty} width="200" alt=""/>
            <h2>Third-party cookies are blocked</h2>
            <p>When using a browser that does not allow cookies, chat on this site may break.</p>
          </div>

          <div className="message-modal-footer mt-4 d-flex justify-content-center">
            <button onClick={() => {setShowModal(false)}} className="button is-solid accent-button form-button">
              Got it
            </button>
            <a href="https://knowledge.kaltura.com/help/enabling-3rd-party-cookies-for-chrome-incognito-mode" target="_blank" className="button is-solid ml-3 form-button">
              How to enable it
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapReduxStateToProps = (state) => ({
  username: state.auth.username,
  searchValue: state.search.searchValue,
});

export default connect(mapReduxStateToProps, {
  logout,
  searchChangeValue,
  handleSearch
})(withRouter(HeaderMenu));
