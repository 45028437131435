import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import 'bootstrap/dist/css/bootstrap.min.css';
import './Assets/css/bootstrap.min.css'
import './Assets/css/animate.min.css'
import './Assets/css/meanmenu.css'
import './Assets/css/flaticon.css'
import './Assets/css/flaticon2.css'
import './Assets/css/font-awesome.min.css'
import './Assets/css/odometer.css'
import './Assets/css/style.css'
import './Assets/css/responsive.css'
import './Assets/css/responsive.scss'
ReactDOM.render(<App />, document.getElementById("root"));
